import React from "react";

function Fomi(_) {
  return (
    <div>
      {/* Never Changes */}
      <div>
        <a
          href="https://yomi.roguestudios.io/"
          target="_blank"
          rel="noreferrer"
        >
          <p className="uppercase font-medium hover:text-heading text-xs duration-500">
            YOMI
          </p>
        </a>
      </div>
      <a href="https://yomi.roguestudios.io/" target="_blank" rel="noreferrer">
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Website
        </p>
      </a>
      <a
        href="https://docs.roguestudios.io/yomi-nfts/yomi-project-information"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Whitepaper
        </p>
      </a>
      <a
        href="https://twitter.com/YomiNFT/status/1554580013068025857"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Trailer
        </p>
      </a>
    </div>
  );
}

export default Fomi;
