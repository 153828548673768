import React, { PureComponent } from "react";
import { ThemeContext } from "../ThemeContext";

import NewsletterInput from "./NewsletterInput";

const Newsletter = () => {
  const { theme } = React.useContext(ThemeContext);
  return (
    <div className="overflow-hidden w-[100%] h-[90.5%] m-0 p-0 absolute">
      <div classname="w-full h-full sm:-translate-x-96 md:-translate-x-96 lg:-translate-x-96 xl:-translate-x-96 2xl:-translate-x-20">
        <h1 className="text-body text-6xl sm:text-6xl md:text-7xl lg:text-8xl absolute sm:whitespace-nowrap xs:translate-y-[100px] translate-y-[100px] mr-32 sm:mr-0 xs:mr-20 translate-x-[50px] sm:rotate-[14.5deg] z-30 xs:translate-x-[30px] xs:text-5xl sm:translate-x-[120px] sm:translate-y-[320px] md:translate-x-[150px] md:translate-y-[300px] lg:translate-x-[100px] lg:translate-y-[240px] xl:translate-x-[400px] xl:translate-y-[210px]">
          The Rogue Newsletter
        </h1>
        <div className="absolute z-30 scale-[0.8] sm:scale-105 translate-y-[400px] xs:-translate-x-[100px] -translate-x-[50px] sm:translate-y-[430px] sm:translate-x-[50px] md:translate-y-[400px] md:translate-x-[200px] lg:translate-y-[330px] lg:translate-x-[100px] xl:translate-y-[280px] xl:translate-x-[380px]">
          <NewsletterInput />
        </div>

        <div className="h-screen w-[1000px] absolute lg:z-20 scale-125 -translate-y-[180px] overflow-hidden xs:-translate-x-[300px] -translate-x-[200px] sm:-translate-x-[100px] md:translate-x-[0px] lg:translate-x-[400px] xl:translate-x-[700px] 2xl:translate-x-[900px]">
          <img
            alt=""
            className="overflow-hidden"
            src={
              theme === "dark"
                ? "https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/LightPose1.png"
                : "https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/pose1.png"
            }
          ></img>
        </div>

        <div className="h-screen w-[1000px] absolute z-20 scale-125 -rotate-[30deg] opacity-10 overflow-hidden hidden xl:block translate-y-[100px] translate-x-[150px]">
          <img
            alt=""
            src="https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/black-icon.png"
            className="overflow-hidden"
          ></img>
        </div>

        <div className="h-[2000px] w-[3000px] bg-heading rotate-[14deg] -translate-x-[500px] translate-y-[600px] sm:translate-y-[580px] md:translate-y-[550px] lg:translate-y-[500px] xl:translate-y-[400px] 2xl:translate-y-[400px]"></div>
      </div>
    </div>
  );
};

export default Newsletter;
