import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export default function FloatingCase(props) {
  const group = useRef();

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = -Math.PI / 1.75 + Math.cos(t / 4) / 2;
    group.current.rotation.y = Math.sin(t / 4) / 2;
    group.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20;
    group.current.position.y = (1 + Math.sin(t / 1.5)) / 30;
  });
  const { nodes, materials } = useGLTF("/glassCase.glb");
  return (
    <group
      position={[-5, 0, 0]}
      scale={1}
      ref={group}
      {...props}
      dispose={null}
    >
      <mesh
        geometry={nodes.cloth.geometry}
        material={materials.AAAAAcloth}
        position={[-0.01, 0.09, 0.31]}
        rotation={[1.55, 0, 0.01]}
        scale={0.34}
      />
      <mesh
        geometry={nodes.primary.geometry}
        material={materials.AAAPRIMARY}
        position={[-0.01, 0.09, 0.31]}
        rotation={[1.55, 0, 0.01]}
        scale={0.34}
      >
        <meshStandardMaterial roughness={0} color={"#F9797A"} />
      </mesh>
      <mesh
        geometry={nodes.blade.geometry}
        material={materials.AAAAAAWHITE}
        position={[-0.01, 0.09, 0.31]}
        rotation={[1.55, 0, 0.01]}
        scale={0.34}
      >
        <meshStandardMaterial roughness={0} color={"#white"} />
      </mesh>
      <mesh
        geometry={nodes.secondary.geometry}
        material={materials.AAASECONDARY}
        position={[-0.01, 0.09, 0.31]}
        rotation={[1.55, 0, 0.01]}
        scale={0.34}
      >
        <meshStandardMaterial roughness={0} color={"#000000"} />
      </mesh>
      <mesh
        geometry={nodes.Glass_box.geometry}
        material={materials["Condensed Glass"]}
        position={[-0.01, 0.07, -0.03]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.23, 0.45, 0.13]}
      >
        <meshPhysicalMaterial
          transmission={0.8}
          thickness={1.5}
          roughness={0.01}
          clearcoat={1}
          color={"#6e6e6e"}
          clearcoatRoughness={0.3}
        />
      </mesh>
      <group position={[-0.01, 0.2, -0.14]} scale={[-25.33, -3.41, -25.42]}>
        <mesh
          geometry={nodes.Curve004.geometry}
          material={materials["SVGMat.154"]}
        >
          <meshStandardMaterial roughness={0} color={"#000000"} />
        </mesh>
        {/* <mesh
          geometry={nodes.Curve004_1.geometry}
          material={materials["SVGMat.151"]}
        >
          <meshStandardMaterial roughness={0} color={"#000000"} />
        </mesh>
        <mesh
          geometry={nodes.Curve004_2.geometry}
          material={materials["SVGMat.153"]}
        >
          <meshStandardMaterial roughness={0} color={"#000000"} />
        </mesh> */}
      </group>
    </group>
  );
}

useGLTF.preload("/glassCase.glb");
