import React from "react";
import { Link } from "react-router-dom";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Fome(_) {
  return (
    <div>
      {/* 
        Active Domain:
        <Link to="/" onClick={topScroll}>

        Non Domain Href Links:
        <a
            href="https://xxxx.roguestudios.io/"     
            target="_blank"
            rel="noreferrer"
        > 
    */}
      <div>
        <Link to="/" onClick={topScroll}>
          <p className="uppercase font-medium hover:text-heading text-xs duration-500">
            Home
          </p>
        </Link>
      </div>
      <a href="https://radar.roguestudios.io/" target="_blank" rel="noreferrer">
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Rogue Radar
        </p>
      </a>
      <a href="https://yomi.roguestudios.io/" target="_blank" rel="noreferrer">
        <p className="my-3 block hover:text-heading text-sm duration-500">
          YOMI
        </p>
      </a>
      <a
        href="https://banners.roguestudios.io/"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Rogue Banners
        </p>
      </a>

      <a
        href="https://rarity.roguestudios.io/"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Rogue Rarities
        </p>
      </a>

      <p className="my-3 block text-bodySecondary text-sm duration-500 cursor-not-allowed">
        News
        <span className="text-heading text-xs p-1">coming soon</span>
      </p>
    </div>
  );
}

export default Fome;
