import React from "react";
import { Link } from "react-router-dom";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Farities(_) {
  return (
    <div>
      <div>
        <a
          href="https://rarity.roguestudios.io/"
          target="_blank"
          rel="noreferrer"
        >
          <p className="uppercase font-medium hover:text-heading text-xs duration-500">
            Rogue Rarities
          </p>
        </a>
      </div>
      <a
        href="https://rarity.roguestudios.io/collections"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Collections
        </p>
      </a>
      <a
        href="https://rarity.roguestudios.io/rarity-form"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Submit a Collection
        </p>
      </a>

      {/*
        <div>
          <Link to="/" onClick={topScroll}>
            <p className="uppercase font-medium hover:text-heading text-xs duration-500">
              Rogue Rarities
            </p>
          </Link>
        </div>
        <Link to="/collections" onClick={topScroll}>
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Collections
          </p>
        </Link>
        <Link to="/rarity-form" onClick={topScroll}>
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Submit a Collection
          </p>
        </Link>
       */}
    </div>
  );
}

export default Farities;
