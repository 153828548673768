import React, { PureComponent } from "react";
import {
  TwitterIcon,
  DiscordIcon,
  WebsiteIcon,
  InstagramIcon,
} from "../../icons/SocialIcons";

import { WhitepaperIcon } from "../../icons/DisplayIcons";

export default class YomiLinks extends PureComponent {
  render() {
    return (
      <div>
        <a
          href='https://mark-eting.gitbook.io/roguestudios/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none  bg-darkCB text-darkBody  border-2 border-darkBorders hover:bg-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative xs:pl-10'>
              <WhitepaperIcon iconClass='absolute w-9 h-auto left-4 fill-background' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate text-background '>
                YOMI Whitepaper
              </p>
            </div>
          </div>
        </a>

        <a
          href='https://yomi.roguestudios.io/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none  bg-darkCB text-darkBody  border-2 border-darkBorders hover:bg-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative xs:pl-10'>
              <WebsiteIcon iconClass='absolute w-9 h-auto left-4 fill-background' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate text-background '>
                YOMI Tools
              </p>
            </div>
          </div>
        </a>

        <a
          href='https://enter.roguestudios.io/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none  bg-darkCB text-darkBody  border-2 border-darkBorders hover:bg-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative xs:pl-10'>
              <WebsiteIcon iconClass='absolute w-9 h-auto left-4 fill-background' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate text-background '>
                Enter YOMI
              </p>
            </div>
          </div>
        </a>

        <a href='https://twitter.com/YomiNFT' target='_blank' rel='noreferrer'>
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none  bg-darkCB text-darkBody  border-2 border-darkBorders hover:bg-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative xs:pl-10'>
              <TwitterIcon iconClass='absolute w-9 h-auto left-4 fill-background' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate text-background '>
                YOMI Twitter
              </p>
            </div>
          </div>
        </a>

        <a
          href='https://discord.gg/roguestudios'
          target='_blank'
          rel='noreferrer'
        >
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none  bg-darkCB text-darkBody  border-2 border-darkBorders hover:bg-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative xs:pl-10'>
              <DiscordIcon iconClass='absolute w-9 h-auto left-4 fill-background' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate text-background '>
                YOMI Discord
              </p>
            </div>
          </div>
        </a>

        <a
          href='https://www.instagram.com/yominft/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none  bg-darkCB text-darkBody  border-2 border-darkBorders hover:bg-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative xs:pl-10'>
              <InstagramIcon iconClass='absolute w-9 h-auto left-4 fill-background' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate text-background '>
                YOMI Instagram
              </p>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
