import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "../src/reportWebVitals";
import { ThemeProvider } from "./ThemeContext";
import Background from "./Background";
import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Background>
      <App className='bg-background dark:bg-body w-full overflow-hidden container' />
    </Background>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
