import React from "react";

function Why() {
  return (
    <div className='w-11/12 mx-auto'>
      <div className='w-full max-w-6xl mx-auto'>
        <h2 className='mb-4 text-4xl lg:text-5xl text-center capitalize w-full text-darkBody'>
          The Rogue Mission
        </h2>
        <p className='text-center py-2  text-base text-cardBackground'>
          At our core, we're a software company. YOMI is the perfect
          representation of what we are all about. We are 100% a utility project
          but we're still releasing some of the best 3D generative art NFTs
          ever. Unique, made in-house, and something that's never been done
          before.
        </p>
        <p className='text-center py-2 text-base text-cardBackground'>
          We can't just ignore the elephant in the room, this space has some
          problems. This is the Rogue Mission; actively work to develop new
          solutions to intrinsic problems within this ecosystem and innovate as
          much as humanly possible along the way.{" "}
        </p>
        <div className='w-full px-8 flex flex-wrap gap-4 justify-center '>
          <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md text-darkCB bg-cardBackground border-borders border-2 hover:bg-borders'>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://docs.roguestudios.io/yomi-nfts/yomi-project-information'
            >
              Read the Whitepaper
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Why;
