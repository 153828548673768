import React, { PureComponent } from "react";

import Footer from "../../navigation/Footer";
import Back from "./Back";
import YomiTop from "./YomiTop";

import { TwitterIcon, DiscordIcon, WebsiteIcon } from "../../icons/SocialIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class ToolsWayfinder extends PureComponent {
  render() {
    return (
      <div>
        <div className='bg-gradient-to-t from-cardBackground via-background to-background dark:from-darkCB dark:via-darkShadows dark:to-darkCB w-full py-20 mt-10'>
          <div className='w-full h-full'>
            <div className='w-11/12 mx-auto max-w-6xl'>
              <h2 className='uppercase tracking-wider font-bold lg:text-8xl md:text-7xl text-6xl font-anton pb-4 text-center my-auto overflow-hidden'>
                Rogue Tools
              </h2>

              <YomiTop />

              <a
                href='https://banners.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <WebsiteIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      banners Website
                    </p>
                  </div>
                </div>
              </a>

              <a
                href='https://radar.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <WebsiteIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      radar Website
                    </p>
                  </div>
                </div>
              </a>

              <a
                href='https://rarity.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <WebsiteIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      rarity Website
                    </p>
                  </div>
                </div>
              </a>

              <a
                href='https://mobile.twitter.com/Rogue_Tools'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <TwitterIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      twitter
                    </p>
                  </div>
                </div>
              </a>

              <a
                href='https://discord.gg/roguestudios'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <DiscordIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      discord
                    </p>
                  </div>
                </div>
              </a>

              <Back />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
