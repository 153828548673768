import React from "react";
import BrokenLinkWrapper from "./BrokenLinkWrapper";
import Radar from "./components/homepage/Radar";
import Footer from "./navigation/Footer";

function RadarLanding() {
  return (
    <>
      <BrokenLinkWrapper>
        <Radar />
      </BrokenLinkWrapper>
      <Footer />
    </>
  );
}

export default RadarLanding;
