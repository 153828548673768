import React from "react";
import { Link } from "react-router-dom";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Fadar(_) {
  return (
    <div>
      <div>
        <a
          href="https://radar.roguestudios.io/"
          target="_blank"
          rel="noreferrer"
        >
          <p className="uppercase font-medium hover:text-heading text-xs duration-500">
            Rogue Radar
          </p>
        </a>
      </div>
      <a
        href="https://radar.roguestudios.io/upcoming-drops"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Upcoming Drops
        </p>
      </a>
      <a
        href="https://radar.roguestudios.io/giveaways"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          NFT Giveaways
        </p>
      </a>
      <a
        href="https://radar.roguestudios.io/upcoming-form"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Submit Your Drop
        </p>
      </a>
      <a
        href="https://radar.roguestudios.io/giveaway-form"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Submit a Giveaway
        </p>
      </a>
      <a
        href="https://radar.roguestudios.io/get-drop-featured"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Get Featured
        </p>
      </a>

      {/*
        <div>
          <Link onClick={topScroll} to="/">
            <p className="uppercase font-medium hover:text-heading text-xs duration-500">
              Rogue Radar
            </p>
          </Link>
        </div>
        <Link onClick={topScroll} to="/upcoming">
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Upcoming
          </p>
        </Link>
        <Link onClick={topScroll} to="/giveaways">
          <p className="my-3 block hover:text-heading text-sm duration-500">
            NFT Giveaways
          </p>
        </Link>
        <Link onClick={topScroll} to="/upcoming-form">
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Submit Your Drop
          </p>
        </Link>
        <Link onClick={topScroll} to="/giveaway-form">
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Submit a Giveaway
          </p>
        </Link>
        <Link onClick={topScroll} to="/get-drop-featured">
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Get Featured
          </p>
        </Link>
      */}
    </div>
  );
}

export default Fadar;
