import React from "react";
import Footer from "../../navigation/Footer";

import SocialCards from "./SocialMarquee/SocialCards";
import { LandingIO, OurVision, CalendarIO, HelpIO, GuideCards } from "./index";

import { TreeLine } from "../../icons/SVGs";
import Rarity from "./Rarity";

import YomiSection from "./yomi/YomiSection";
import Banners from "./Banners";
import Radar from "./Radar";
import { SliceLeft, SliceRight } from "../../icons/FunctionalIcons";
import LinkTree from "./LinkTree";
import Why from "./Why";

export default function Homepage() {
  return (
    <div className='bg-cardBackground heropattern-topography-borders '>
      <div className='pt-10'>
        <LandingIO />
      </div>

      <div className='relative z-10 sm:block hidden'>
        <TreeLine iconClass='w-full mt-auto mb-0 h-fit fill-darkBorders' />
      </div>

      <div className='scroll-mt-20 2xl:py-20 py-10 bg-darkBorders' id='Vision'>
        <OurVision />
      </div>

      <div id='Banners' className='scroll-mt-40 2xl:py-20 py-10 bg-darkBorders'>
        <Banners />
      </div>
      <div className='bg-darkBorders sm:block hidden'>
        <SliceLeft iconClass='w-full h-fit fill-cardBackground ' />
      </div>
      <div>
        <GuideCards />
      </div>
      <div id='Yomi' className='bg-cardBackground'>
        <YomiSection />
      </div>
      <div id='Calendar' className='scroll-mt-20 '>
        <CalendarIO />
      </div>
      <div className='bg-darkBorders'>
        <SliceLeft iconClass='w-full h-fit fill-cardBackground rotate-[180deg] -translate-y-1 sm:block hidden' />
      </div>
      <div id='Radar' className='bg-darkBorders scroll-mt-40 2xl:py-20 py-10'>
        <Radar />
      </div>
      <div
        id='Rarity'
        className='2xl:py-20 py-10 bg-darkBorders scroll-mt-40 pb-32'
      >
        <Rarity />
      </div>
      <div id='Mission' className='2xl:py-20 py-10 bg-darkBorders scroll-mt-20'>
        <Why />
      </div>
      <div className='2xl:py-20 py-10 bg-darkBorders'>
        <LinkTree />
      </div>
      <div className='bg-darkBorders  sm:block hidden'>
        <SliceLeft iconClass='w-full h-fit fill-cardBackground ' />
      </div>
      <div className='2xl:py-20 py-10 bg-cardBackground'>
        <HelpIO />
      </div>
      <Footer />
    </div>
  );
}
