import React, { useState } from "react";
import { addSubscription } from "../api";

export default function EmailLandingInput() {
  const [form, setForm] = useState({
    email: "",
  });

  const [errormsg, setErrorMsg] = useState("");
  const [placeholder, setPlacholder] = useState("Enter your email");

  function updateForm(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emailInput = document.getElementById("email-input").value;
    if (!regEmail.test(emailInput)) {
      setErrorMsg("Please enter your email correctly");
      return setForm((prev) => {
        return { ...prev, ...value };
      });
    } else {
      setErrorMsg("");
      return setForm((prev) => {
        return { ...prev, ...value };
      });
    }
  }
  async function onSubmit(e) {
    const newGiveaway = {
      email: form.email,
    };

    await addSubscription(newGiveaway);
    setForm({ email: "" });

    alert("Thank you for joining the Rogue Studios mailing list.");
  }

  return (
    <div>
      <p className="text-left px-20 pt-5 text-2xl text-background font-source font-semibold whitespace-nowrap leading-tight tracking-wide">
        Sign up to
        <br />
        the newsletter and
        <br />
        <span className="font-bold">Follow what we’re Building</span>
      </p>
      <div className="flex justify-center mt-3">
        <div className="flex-row sm:w-full w-2/3">
          <div className="bg-cardBackground dark:bg-darkCB rounded-lg">
            <div className="flex justify-between">
              <input
                type="email"
                className="bg-cardBackground dark:bg-darkBackground py-4 pl-4 pr-0 w-full focus:outline-none appearance-none text-sm text-body dark:text-darkBody rounded-lg border-transparent"
                placeholder={placeholder}
                value={form.email}
                id="email-input"
                onChange={(e) => updateForm({ email: e.target.value })}
              ></input>
              <button
                className="text-background dark:text-darkCB rounded-lg w-auto px-6 my-1 mr-1 text-sm bg-heading hover:bg-transparent dark:hover:bg-transparent border-heading dark:border-heading hover:text-body dark:hover:text-bodySecondary"
                onClick={onSubmit}
                type="submit"
              >
                Subscribe
              </button>
            </div>
          </div>
          <p className="text-md ml-1 my-2 font-semibold text-background text-center font-source whitespace-nowrap">
            We won't spam. Pinky promise
          </p>
        </div>
      </div>
    </div>
  );
}
