import React, { PureComponent } from "react";

import EmailInput from "./EmailInput";

export default class EmailSignup extends PureComponent {
  render() {
    return (
      <div>
        <div className='w-11/12 mx-auto py-20'>
          <h3 className='text-center capitalize'>
            Join the Rogue mailing list
          </h3>
          <p className='text-center mb-3 mt-1'>
            Sign up to the newsletter and keep up to date with what we're
            building
          </p>
          <div className='flex justify-center'>
            <div className='w-full max-w-xl'>
              <EmailInput />
              <p className='text-sm ml-1 my-2 font-semibold text-center'>
                We won't spam. Pinky promise
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
