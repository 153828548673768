import React from "react";
import { Link } from "react-router-dom";

import MobileNav from "./MobileNav";
import { SunIcon, MoonIcon } from "../icons/FunctionalIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Nav() {
  return (
    <div>
      <MobileNav />
      <div className='w-full backdrop-blur-md fixed top-0 hidden lg:block blurmask z-50'>
        <nav className='w-11/12 max-w-6xl mx-auto py-4 pb-10 flex flex-nowrap justify-between'>
          <div>
            <a href='https://roguestudios.io' target='_blank' rel='noreferrer'>
              <img
                alt=''
                src='https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/red-logo.png'
                className='w-[160px]'
              ></img>
            </a>
          </div>

          <div className='w-fit my-auto'>
            <ul className='flex flex-wrap justify-center gap-2 text-lg'>
              <Link to='/' onClick={topScroll}>
                <li className='bg-cardBackground text-body shadow-lg text-xs px-5 text-center py-2 rounded-lg font-semibold duration-500 border-2 border-borders hover:bg-borders w-full dark:bg-darkBackground dark:text-darkBody dark:border-darkBorders dark:shadow-darkShadows dark:hover:bg-darkBorders'>
                  Home
                </li>
              </Link>
              <a
                href='https://yomi.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
              >
                <li className='bg-cardBackground text-body shadow-lg text-xs px-5 text-center py-2 rounded-lg font-semibold duration-500 border-2 border-borders hover:bg-borders w-full dark:bg-darkBackground dark:text-darkBody dark:border-darkBorders dark:shadow-darkShadows dark:hover:bg-darkBorders'>
                  YOMI
                </li>
              </a>

              <a
                href='https://radar.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
              >
                <li className='bg-cardBackground text-body shadow-lg text-xs px-5 text-center py-2 rounded-lg font-semibold duration-500 border-2 border-borders hover:bg-borders w-full dark:bg-darkBackground dark:text-darkBody dark:border-darkBorders dark:shadow-darkShadows dark:hover:bg-darkBorders'>
                  Radar
                </li>
              </a>

              <a
                href='https://banners.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
              >
                <li className='bg-cardBackground text-body shadow-lg text-xs px-5 text-center py-2 rounded-lg font-semibold duration-500 border-2 border-borders hover:bg-borders w-full dark:bg-darkBackground dark:text-darkBody dark:border-darkBorders dark:shadow-darkShadows dark:hover:bg-darkBorders'>
                  Banners
                </li>
              </a>

              <a
                href='https://rarity.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
              >
                <li className='bg-cardBackground text-body shadow-lg text-xs px-5 text-center py-2 rounded-lg font-semibold duration-500 border-2 border-borders hover:bg-borders w-full dark:bg-darkBackground dark:text-darkBody dark:border-darkBorders dark:shadow-darkShadows dark:hover:bg-darkBorders'>
                  Rarities
                </li>
              </a>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Nav;
