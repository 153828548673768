import React, { PureComponent } from "react";
import CalendarCardRadar from "./CalendarCardRadar";
import CalendarCardRarity from "./CalendarCardRarity";
import CalendarCardNews from "./CalendarCardNews";
import CalendarCardMarketplace from "./CalendarCardMarketplace";
import CalendarCardYomi from "./CalendarCardYomi";
import CalendarCardBanners from "./CalendarCardBanners";
import CalendarCardNoCode from "./CalendarCardNoCode";
import CalendarCardPlugin from "./CalendarCardPlugin";

export default class CalendarIO extends PureComponent {
  render() {
    return (
      <div className="w-full bg-cardBackground pb-32 py-20">
        <div className="w-11/12 mx-auto">
          <h2 className="w-full flex flex-wrap uppercase flex-grow justify-center mb-10 text-5xl text-center">
            Release Calendar
          </h2>

          <div className="grid grid-cols-1 grid-rows-8 sm:grid-cols-2 sm:grid-rows-3 xl:grid-rows-2 xl:grid-cols-4 gap-[2px] mx-auto border-2 max-w-6xl border-bodySecondary bg-bodySecondary">
            <CalendarCardRadar />
            <CalendarCardYomi />
            <CalendarCardBanners />

            <CalendarCardRarity />
            <CalendarCardNews />
            <CalendarCardNoCode />
            <CalendarCardPlugin />
            <CalendarCardMarketplace />
          </div>
        </div>
      </div>
    );
  }
}
