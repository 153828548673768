import React from "react";
/*
-------------------------------------------------------------------------------------------------
    Icons use a prop with the 'iconClass' tag rather than 'classname'
-------------------------------------------------------------------------------------------------
*/

/*
-------------------------------------------------------------------------------------------------
    Template
-------------------------------------------------------------------------------------------------
Title
export const AVAXIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width=""
            height=""
            viewBox=""
            preserveAspectRatio="xMidYMid meet"
            className={props.iconClass}
            >
            <g
                transform=""
                fill={props.fill}
                stroke="none"
            >
                <path d="" />
            </g>
        </svg>
    );
};
*/

/*
-------------------------------------------------------------------------------------------------
    Discord
-------------------------------------------------------------------------------------------------
*/
export const DiscordIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 218.000000 218.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,218.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M645 1891 c-111 -29 -237 -72 -287 -97 -69 -35 -227 -348 -292 -579 -50 -177 -79 -459 -62 -616 l7 -64 68 -47 c95 -66 258 -150 373 -192 53 -20 101 -36 105 -36 12 0 113 164 113 183 0 9 -5 17 -11 17 -17 0 -159 72 -159 81 0 5 9 13 20 19 16 9 39 4 113 -24 160 -60 280 -81 457 -81 177 0 297 21 457 81 74 28 97 33 113 24 11 -6 20 -14 20 -19 0 -8 -98 -59 -153 -80 -18 -7 -26 -16 -21 -23 32 -59 110 -178 117 -178 4 0 52 16 105 36 115 43 252 113 361 185 l80 52 7 76 c24 264 -43 625 -165 886 -55 117 -149 273 -175 290 -49 32 -375 135 -428 135 -8 0 -25 -24 -40 -52 l-25 -53 -253 1 -253 0 -26 52 c-14 30 -32 52 -41 52 -8 -1 -64 -14 -125 -29z m163 -687 c138 -69 154 -279 29 -378 -35 -28 -46 -31 -107 -31 -54 0 -75 4 -96 21 -119 88 -134 254 -34 354 64 64 128 74 208 34z m747 -12 c78 -54 110 -155 81 -253 -18 -61 -37 -87 -91 -123 -64 -44 -173 -29 -227 31 -33 36 -58 107 -58 163 0 169 166 271 295 182z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Instagram
-------------------------------------------------------------------------------------------------
*/
export const InstagramIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M1220 5105 c-628 -87 -1121 -584 -1206 -1215 -20 -155 -20 -2505 0 -2660 42 -312 180 -592 402 -814 221 -221 502 -360 814 -402 155 -20 2505 -20 2660 0 636 86 1130 580 1216 1216 20 155 20 2505 0 2660 -86 636 -580 1130 -1216 1216 -148 19 -2526 19 -2670 -1z m2562 -445 c244 -23 445 -118 609 -287 160 -165 246 -354 269 -591 13 -144 13 -2300 0 -2444 -23 -237 -109 -426 -269 -591 -164 -169 -365 -264 -609 -287 -144 -13 -2300 -13 -2444 0 -244 23 -445 118 -609 287 -160 165 -246 354 -269 591 -13 144 -13 2300 0 2444 23 244 118 445 287 609 159 155 356 247 573 268 127 12 2333 13 2462 1z" />
        <path d="M3813 4239 c-75 -29 -144 -95 -178 -171 -97 -212 50 -453 284 -466 113 -6 228 51 290 145 41 61 55 113 54 196 -2 222 -238 378 -450 296z" />
        <path d="M2380 3865 c-290 -41 -542 -167 -750 -375 -224 -223 -348 -488 -381 -813 -33 -311 66 -658 260 -912 58 -77 179 -198 256 -256 254 -194 601 -293 913 -260 324 33 589 157 812 381 224 223 348 488 381 812 27 257 -35 543 -168 773 -268 466 -793 724 -1323 650z m380 -465 c311 -75 565 -329 640 -640 27 -116 27 -284 0 -400 -59 -248 -233 -463 -463 -575 -126 -61 -233 -85 -377 -85 -144 0 -251 24 -377 85 -230 112 -404 327 -463 575 -27 116 -27 284 0 400 74 308 327 564 635 639 112 28 291 28 405 1z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Twitter
-------------------------------------------------------------------------------------------------
*/
export const TwitterIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 218.000000 218.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,218.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M1408 1974 c-216 -52 -364 -253 -344 -467 l6 -68 -57 6 c-303 35 -542 148 -760 358 l-103 100 -21 -48 c-73 -165 -35 -350 99 -488 l57 -59 -45 7 c-25 4 -67 16 -95 26 -27 11 -51 19 -52 19 -10 0 0 -79 17 -133 38 -122 140 -231 262 -279 71 -28 59 -38 -45 -38 -77 0 -78 0 -71 -22 13 -42 70 -123 118 -166 58 -53 156 -101 225 -109 28 -3 51 -9 51 -12 0 -11 -101 -73 -176 -107 -105 -49 -228 -76 -352 -77 -118 -1 -118 -3 -2 -62 90 -46 223 -91 327 -112 131 -26 376 -23 511 6 254 54 458 166 633 348 218 227 348 533 364 856 l4 98 54 44 c46 39 91 87 147 159 14 18 13 19 -5 13 -76 -24 -156 -45 -190 -51 l-40 -7 35 28 c68 54 150 163 150 200 0 7 -29 -2 -73 -22 -40 -19 -101 -41 -134 -51 l-60 -17 -54 41 c-73 54 -109 71 -187 88 -81 17 -120 17 -194 -2z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Medium
-------------------------------------------------------------------------------------------------
*/
export const MediumIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M1365 3874 c-200 -20 -377 -68 -530 -144 -397 -197 -671 -568 -745
            -1008 -100 -592 206 -1189 748 -1456 193 -96 386 -140 607 -140 220 0 415 45
            605 139 101 50 226 130 260 165 3 3 31 27 63 54 63 53 183 197 242 291 204
            322 258 742 144 1127 -64 214 -172 392 -343 564 -169 168 -340 274 -556 343
            -143 46 -377 76 -495 65z"
        />
        <path
          d="M3541 3794 c-293 -79 -532 -494 -590 -1026 -15 -138 -14 -433 3 -568
            47 -375 182 -691 369 -866 116 -109 224 -152 352 -140 301 28 550 401 642 961
            27 169 25 546 -5 715 -55 310 -151 551 -287 718 -72 89 -121 131 -202 172 -96
            49 -186 60 -282 34z"
        />
        <path
          d="M4647 3661 c-84 -55 -154 -289 -193 -638 -32 -292 -29 -842 7 -1113
            42 -317 115 -538 192 -579 23 -12 31 -12 53 0 211 112 301 1325 149 2008 -40
            180 -93 299 -146 326 -34 18 -28 19 -62 -4z"
        />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Github
-------------------------------------------------------------------------------------------------
*/
export const GithubIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512.000000pt"
      height="512.000000pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M2360 5049 c-154 -11 -357 -47 -516 -93 -902 -259 -1603 -1017 -1790
            -1934 -136 -669 -8 -1355 354 -1908 255 -390 580 -686 968 -886 141 -73 341
            -154 403 -164 58 -9 109 19 133 73 18 40 18 60 12 286 l-7 243 -86 -14 c-97
            -15 -256 -9 -386 13 -105 19 -211 71 -278 139 -53 53 -67 76 -136 229 -63 139
            -135 231 -232 297 -66 46 -121 106 -117 128 6 30 48 43 121 38 141 -10 288
            -113 393 -274 72 -110 143 -179 230 -222 62 -31 79 -35 169 -38 103 -4 207 12
            291 44 41 16 43 18 58 85 19 86 56 164 106 228 l39 49 -82 11 c-264 38 -452
            102 -627 215 -229 148 -365 379 -431 731 -20 109 -23 389 -5 492 29 167 98
            319 200 445 l45 55 -20 62 c-52 168 -42 372 28 574 18 50 22 52 103 48 118 -6
            371 -108 543 -218 l71 -46 56 11 c30 6 87 18 127 27 271 58 655 58 926 0 40
            -9 97 -21 127 -27 l55 -10 95 58 c226 137 484 230 575 206 26 -7 33 -17 53
            -75 43 -125 55 -210 50 -351 -4 -95 -11 -148 -26 -195 l-21 -64 44 -54 c89
            -109 155 -244 192 -389 22 -89 25 -417 4 -544 -32 -198 -114 -406 -210 -532
            -165 -217 -464 -366 -843 -418 l-87 -12 39 -49 c47 -60 85 -137 106 -221 14
            -52 17 -137 20 -503 5 -490 5 -489 72 -521 46 -21 83 -15 229 42 738 284 1320
            932 1533 1703 141 513 111 1108 -80 1601 -172 440 -475 842 -848 1122 -405
            303 -865 474 -1367 507 -175 12 -192 12 -375 0z"
        />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Share
-------------------------------------------------------------------------------------------------
*/
export const ShareIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 226.000000 226.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,226.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M1594 2120 c-12 -5 -26 -15 -30 -22 -5 -7 -11 -76 -14 -153 l-5 -140 -230 -6 c-248 -7 -339 -20 -475 -68 -292 -103 -432 -325 -418 -666 6 -132 40 -245 120 -395 61 -115 93 -160 117 -160 36 0 39 17 34 193 -8 262 24 377 132 475 103 95 238 130 528 139 l197 6 0 -137 c0 -76 4 -146 10 -156 12 -22 64 -41 88 -33 9 3 135 123 280 267 245 244 262 263 262 296 -1 32 -22 56 -253 291 -139 141 -262 262 -274 268 -25 13 -40 13 -69 1z" />
        <path d="M390 1959 c-108 -21 -217 -107 -263 -207 -22 -46 -22 -54 -25 -663 -3 -605 -2 -618 18 -673 30 -78 104 -157 188 -198 l67 -33 605 0 605 0 57 23 c80 32 150 99 190 180 l33 66 3 223 c3 218 3 222 -18 233 -17 9 -26 8 -43 -4 -12 -9 -39 -27 -59 -41 l-38 -25 0 -168 c0 -141 -3 -173 -19 -207 -22 -47 -64 -89 -104 -104 -19 -7 -223 -11 -608 -11 -484 0 -585 2 -608 14 -42 22 -99 91 -106 128 -3 18 -5 292 -3 610 l3 576 31 38 c58 72 73 78 202 85 107 5 124 9 192 41 41 19 87 40 103 46 33 14 38 56 8 72 -24 12 -346 12 -411 -1z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Website
-------------------------------------------------------------------------------------------------
*/
export const WebsiteIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 57.949 57.949"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill={props.fill} stroke="none">
        <path d="M29.975,27.985h11.972c-0.123-4.348-1.033-8.567-2.664-12.477c-3.01,0.874-6.131,1.355-9.308,1.44V27.985z" />
        <path
          d="M40.384,13.057c2.928-1.01,5.731-2.392,8.328-4.169l0.653-0.5C44.588,3.658,38.18,0.58,31.071,0.069L30.553,0.49
          C34.767,3.979,38.099,8.268,40.384,13.057z"
        />
        <path d="M29.975,2.628v12.32c2.891-0.082,5.731-0.513,8.473-1.283C36.421,9.505,33.557,5.753,29.975,2.628z" />
        <path
          d="M41.531,42.202c3.13,1.132,6.12,2.668,8.885,4.639l0.868,0.65c3.976-4.785,6.439-10.866,6.665-17.505H43.947
          C43.834,34.224,43.006,38.343,41.531,42.202z"
        />
        <path
          d="M9.237,8.888c2.597,1.777,5.401,3.158,8.328,4.169c2.285-4.788,5.617-9.077,9.832-12.567l-0.518-0.421
          c-7.125,0.512-13.547,3.603-18.328,8.35L9.237,8.888z"
        />
        <path d="M27.975,2.628c-3.583,3.125-6.446,6.878-8.473,11.036c2.742,0.77,5.582,1.202,8.473,1.283V2.628z" />
        <path
          d="M14.003,29.985H0c0.227,6.639,2.689,12.719,6.665,17.505l0.85-0.635c2.775-1.977,5.771-3.518,8.906-4.652
          C14.943,38.341,14.116,34.222,14.003,29.985z"
        />
        <path
          d="M16.752,14.89c-3.037-1.064-5.945-2.505-8.644-4.351L7.17,9.897C2.901,14.768,0.236,21.072,0,27.985h14.003
          C14.124,23.425,15.061,18.997,16.752,14.89z"
        />
        <path d="M16.003,27.985h11.972V16.948c-3.179-0.085-6.301-0.566-9.309-1.438C17.035,19.419,16.125,23.637,16.003,27.985z" />
        <path
          d="M27.297,57.391c-4.419-3.695-7.841-8.259-10.111-13.341c-2.992,1.074-5.851,2.538-8.492,4.42l-0.689,0.515
          c4.774,5.003,11.31,8.304,18.605,8.896L27.297,57.391z"
        />
        <path d="M27.975,40.023V29.985H16.003c0.113,4.019,0.905,7.924,2.313,11.58C21.429,40.627,24.669,40.111,27.975,40.023z" />
        <path
          d="M49.236,48.456c-2.631-1.875-5.482-3.335-8.469-4.406c-2.272,5.094-5.698,9.662-10.113,13.344l0.685,0.487
          c7.295-0.592,13.832-3.892,18.606-8.895L49.236,48.456z"
        />
        <path
          d="M49.885,10.507c-2.72,1.862-5.643,3.311-8.689,4.38c1.692,4.108,2.629,8.537,2.751,13.098h14.003
          c-0.237-6.935-2.917-13.256-7.209-18.133L49.885,10.507z"
        />
        <path d="M27.975,55.342V42.023c-3.039,0.085-6.016,0.556-8.882,1.4C21.136,47.932,24.141,51.998,27.975,55.342z" />
        <path d="M41.947,29.985H29.975v10.038c3.307,0.088,6.548,0.604,9.662,1.542C41.041,37.91,41.833,34.005,41.947,29.985z" />
        <path d="M38.86,43.423c-2.866-0.844-5.845-1.314-8.885-1.4v13.323C33.806,52.013,36.814,47.943,38.86,43.423z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Marketplace
-------------------------------------------------------------------------------------------------
*/
export const MarketplaceIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 487.67 487.67"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill={props.fill} stroke="none">
        <path
          d="M485.6,463.384c0-13.417-10.871-24.288-24.279-24.288h-10.25V204.002h-0.008c-12.6,0-24.517-4.109-34.442-11.037
          c-6.673,4.704-14.353,8.18-22.867,9.87c-3.895,0.778-7.869,1.167-11.797,1.167c-2.619,0-5.133-0.35-7.681-0.683v46.367H261.104
          v-48.508c-5.507,1.649-11.235,2.816-17.281,2.816c-6.03,0-11.751-1.166-17.265-2.816v48.508H113.386V203.32
          c-2.538,0.333-5.07,0.683-7.687,0.683c-3.903,0-7.863-0.389-11.838-1.175c-8.49-1.69-16.155-5.158-22.835-9.862
          c-9.925,6.927-21.826,11.037-34.417,11.037h-0.008v235.094H26.35c-13.417,0-24.286,10.871-24.286,24.288
          c0,13.417,10.869,24.286,24.286,24.286H461.32C474.729,487.67,485.6,476.801,485.6,463.384z M374.276,284.224v55.071
          c0,10.718-8.68,19.398-19.406,19.398h-93.765v-74.47H374.276z M226.559,284.224v74.47h-93.766c-10.719,0-19.408-8.68-19.408-19.398
          v-55.071H226.559z"
        />
        <path
          d="M26.723,176.645c3.292,0.983,6.617,1.451,9.893,1.451c14.869,0,28.603-9.679,33.078-24.666l1.816-6.07
          c1.23,11.021,7.673,20.732,17.312,26.167c3.11,1.729,6.426,3.157,10.108,3.887c2.277,0.462,4.546,0.682,6.776,0.682
          c16.145,0,30.578-11.369,33.839-27.809l0.842-4.212c0.864,11.718,7.577,22.041,17.557,27.618c4.007,2.246,8.515,3.729,13.386,4.222
          c1.165,0.126,2.324,0.175,3.466,0.175c17.528,0,32.547-13.298,34.331-31.119l0.255-2.563c0.316,12.583,7.362,23.382,17.677,29.157
          c4.982,2.794,10.654,4.524,16.764,4.524c6.132,0,11.798-1.73,16.78-4.524c10.307-5.775,17.36-16.574,17.686-29.157l0.245,2.563
          c1.786,17.82,16.797,31.119,34.331,31.119c1.15,0,2.301-0.049,3.468-0.175c4.863-0.493,9.371-1.976,13.392-4.222
          c9.974-5.577,16.695-15.9,17.542-27.618l0.842,4.212c3.276,16.447,17.7,27.809,33.839,27.809c2.237,0,4.498-0.221,6.784-0.682
          c3.664-0.73,6.997-2.158,10.092-3.887c9.655-5.436,16.098-15.146,17.328-26.167l1.816,6.07
          c4.476,14.987,18.209,24.666,33.085,24.666c3.27,0,6.594-0.468,9.886-1.451c18.289-5.46,28.682-24.707,23.224-42.979
          l-29.539-98.851C448.453,14.155,429.45,0,407.894,0H79.778C58.212,0,39.21,14.155,33.038,34.815L3.506,133.666
          C-1.952,151.939,8.443,171.186,26.723,176.645z"
        />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Yahoo
-------------------------------------------------------------------------------------------------
*/
export const YahooIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 45 45"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill="#A630DD" stroke="none">
        <path d="M9,5c0,0,1.4,0.3,2.5,0.3S14,5,14,5l10,17L34.1,5c0,0,1.1,0.4,2.4,0.4C37.9,5.4,39,5,39,5L26,27l0.4,16c0,0-1.4-0.4-2.4-0.4S21.5,43,21.5,43L22,27L9,5z"></path>
      </g>
    </svg>
  );
};

/*
  -------------------------------------------------------------------------------------------------
      Outlook
  -------------------------------------------------------------------------------------------------
*/
export const OutlookIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 45 45"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <path
        fill={props.fill}
        d="M28,13h14.533C43.343,13,44,13.657,44,14.467v19.066C44,34.343,43.343,35,42.533,35H28V13z"
      />
      <rect width="14" height="15.542" x="28" y="17.958" fill="#fff" />
      <polygon fill="#1976d2" points="27,44 4,39.5 4,8.5 27,4" />
      <path
        fill="#fff"
        d="M15.25,16.5c-3.176,0-5.75,3.358-5.75,7.5s2.574,7.5,5.75,7.5S21,28.142,21,24	S18.426,16.5,15.25,16.5z M15,28.5c-1.657,0-3-2.015-3-4.5s1.343-4.5,3-4.5s3,2.015,3,4.5S16.657,28.5,15,28.5z"
      />
      <rect width="2.7" height="2.9" x="28.047" y="29.737" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="29.737" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="29.737" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="28.047" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="38.25" y="26.159" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="28.047" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="38.25" y="22.706" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="31.448" y="19.112" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="34.849" y="19.112" fill="#1976d2" />
      <rect width="2.7" height="2.9" x="38.25" y="19.112" fill="#1976d2" />
    </svg>
  );
};

/*
  -------------------------------------------------------------------------------------------------
      Office
  -------------------------------------------------------------------------------------------------
*/
export const Office365Icon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 45 45"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill="#f04e1f" stroke="none">
        <path d="M7 12L29 4 41 7 41 41 29 44 7 36 29 39 29 10 15 13 15 33 7 36z" />
      </g>
    </svg>
  );
};

/*
  -------------------------------------------------------------------------------------------------
      Google Calendar
  -------------------------------------------------------------------------------------------------
*/
export const GoogleCalendar = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 45 45"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <rect width="22" height="22" x="13" y="13" fill="#fff" />
      <polygon
        fill="#1e88e5"
        points="25.68,20.92 26.688,22.36 28.272,21.208 28.272,29.56 30,29.56 30,18.616 28.56,18.616"
      />
      <path
        fill="#1e88e5"
        d="M22.943,23.745c0.625-0.574,1.013-1.37,1.013-2.249c0-1.747-1.533-3.168-3.417-3.168 c-1.602,0-2.972,1.009-3.33,2.453l1.657,0.421c0.165-0.664,0.868-1.146,1.673-1.146c0.942,0,1.709,0.646,1.709,1.44 c0,0.794-0.767,1.44-1.709,1.44h-0.997v1.728h0.997c1.081,0,1.993,0.751,1.993,1.64c0,0.904-0.866,1.64-1.931,1.64 c-0.962,0-1.784-0.61-1.914-1.418L17,26.802c0.262,1.636,1.81,2.87,3.6,2.87c2.007,0,3.64-1.511,3.64-3.368 C24.24,25.281,23.736,24.363,22.943,23.745z"
      />
      <polygon fill="#fbc02d" points="34,42 14,42 13,38 14,34 34,34 35,38" />
      <polygon fill="#4caf50" points="38,35 42,34 42,14 38,13 34,14 34,34" />
      <path
        fill="#1e88e5"
        d="M34,14l1-4l-1-4H9C7.343,6,6,7.343,6,9v25l4,1l4-1V14H34z"
      />
      <polygon fill="#e53935" points="34,34 34,42 42,34" />
      <path fill="#1565c0" d="M39,6h-5v8h8V9C42,7.343,40.657,6,39,6z" />
      <path fill="#1565c0" d="M9,42h5v-8H6v5C6,40.657,7.343,42,9,42z" />
    </svg>
  );
};
