import React from "react";
import Nav from "./navigation/Nav";
import Error404 from "./persistent/Error404";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeContext } from "./ThemeContext";
import Newsletter from "./persistent/Newsletter";
// import Dashboard from "./components/DOTIO/Dashboard";

import Wayfinder from "./components/wayfinder/Wayfinder";
import YomiWayfinder from "./components/wayfinder/YomiWayfinder";
import ToolsWayfinder from "./components/wayfinder/ToolsWayfinder";

import BrandKit from "./components/legal/BrandKit";
import PrivacyPolicy from "./components/legal/PrivacyPolicy";
import Terms from "./components/legal/Terms";
import Disclaimer from "./components/legal/Disclaimer";
import Homepage from "./components/homepage/Homepage";
import RarityLanding from "./Rarity";
import NewsLanding from "./NewsLanding";
import BannersLanding from "./BannersLanding";
import RadarLanding from "./RadarLanding";
document.body.style = "background: #000000;";

function App() {
  return (
    <div className='selection:bg-heading selection:text-background font-source z-0'>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/newsletter' exact element={<Newsletter />} />
          {/* <Route path="/dashboard" exact element={<Dashboard />} /> */}
          <Route path='/wayfinder' exact element={<Wayfinder />} />
          <Route path='/yomi-wayfinder' exact element={<YomiWayfinder />} />
          <Route path='/tools-wayfinder' exact element={<ToolsWayfinder />} />
          <Route path='/rarity' exact element={<RarityLanding />} />
          <Route path='/news' exact element={<NewsLanding />} />
          <Route path='/banners' exact element={<BannersLanding />} />{" "}
          <Route path='/radar' exact element={<RadarLanding />} />
          <Route path='/privacy-policy' exact element={<PrivacyPolicy />} />
          <Route path='/terms-and-conditions' exact element={<Terms />} />
          <Route path='/disclaimer' exact element={<Disclaimer />} />
          <Route path='/brand-kit' exact element={<BrandKit />} />
          <Route path='*' element={<Error404 />} />
        </Routes>
        <Nav />
      </Router>
    </div>
  );
}

export default App;
