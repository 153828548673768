import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../ThemeContext";

import { SunIcon, MoonIcon } from "../icons/FunctionalIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function MobileNav() {
  const [isOpen, setIsOpen] = useState(false);
  const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-body dark:bg-cardBackground transition ease transform duration-300`;

  function closeMenu() {
    this.setState(isOpen);
  }

  var translateLength = [];
  for (let i = 0; i < 5; i++) {
    if (window.location.hash === "#Featured") {
      var tLength = "translate-x-[106px]";
    } else if (window.location.hash === "#Search") {
      var tLength = "translate-x-[155px]";
    } else if (window.location.hash === "#Gallery") {
      var tLength = "translate-x-[205px]";
    } else if (window.location.pathname === "/rarity/form") {
      var tLength = "translate-x-[252px]";
    } else {
      var tLength = "translate-x-[60px]";
    }
    var translateLength = tLength;
  }

  return (
    <div className='w-full lg:hidden relative z-50'>
      <div
        className={
          isOpen
            ? "w-full backdrop-blur-md backdrop-brightness-75 dark:backdrop-brightness-100 fixed top-0 h-[350px]"
            : "w-full backdrop-blur-md backdrop-brightness-75 dark:backdrop-brightness-100 fixed top-0 duration-1000 blurmask"
        }
      >
        <div className='w-11/12 mx-auto py-4 flex flex-nowrap justify-between pb-20'>
          <ul
            className={
              isOpen
                ? "flex flex-col items-end mt-4 gap-2 fixed mx-auto top-10 duration-500 py-9 truncate w-11/12 pr-4"
                : "-translate-y-96 duration-0 fixed right-0 top-10 px-10 py-5 flex flex-col items-end mt-4 gap-2 duration-500 delay-[200ms]"
            }
          >
            <li
              className={
                isOpen
                  ? "text-darkCB dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold w-min translate-x-0 delay-500 opacity-100 duration-500"
                  : "text-body dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold duration-500 w-min translate-x-28 opacity-0"
              }
            >
              <Link
                onClick={closeMenu}
                // topscroll
                to='/'
                className='hover:opacity-60 duration-500 capitalize'
              >
                Home
              </Link>
            </li>
            <li
              className={
                isOpen
                  ? "text-darkCB dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold backdrop-blur-md w-min translate-x-0 delay-[550ms] opacity-100 duration-500"
                  : "text-body dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold duration-500 backdrop-blur-md w-min translate-x-28 opacity-0"
              }
            >
              <a
                href='https://yomi.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
                className='hover:opacity-60 duration-500 capitalize'
              >
                YOMI
              </a>
            </li>
            <li
              className={
                isOpen
                  ? "text-darkCB dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold  backdrop-blur-md w-min translate-x-0 delay-[600ms] opacity-100 duration-500"
                  : "text-body dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold duration-500 backdrop-blur-md w-min translate-x-28 opacity-0"
              }
            >
              <a
                href='https://radar.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
                className='hover:opacity-60 duration-500 capitalize'
              >
                radar
              </a>
            </li>
            <li
              className={
                isOpen
                  ? "text-darkCB dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold backdrop-blur-md w-min translate-x-0 delay-[650ms] opacity-100 duration-500"
                  : "text-body dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold duration-500 backdrop-blur-md w-min translate-x-28 opacity-0"
              }
            >
              <a
                href='https://banners.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
                className='hover:opacity-60 duration-500 capitalize'
              >
                banners
              </a>
            </li>
            <li
              className={
                isOpen
                  ? "text-darkCB dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold  backdrop-blur-md w-min translate-x-0 delay-[700ms] opacity-100 duration-500"
                  : "text-body dark:text-cardBackground text-md text-right py-2 rounded-lg font-bold duration-500  backdrop-blur-md w-min translate-x-28 opacity-0"
              }
            >
              <a
                href='https://rarity.roguestudios.io/'
                target='_blank'
                rel='noreferrer'
                className='hover:opacity-60 duration-500 capitalize'
              >
                Rarities
              </a>
            </li>
          </ul>
          <div>
            <a href='https://roguestudios.io' target='_blank' rel='noreferrer'>
              <img
                alt=''
                src='https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/red-logo.png'
                className='w-[160px] relative z-50'
              ></img>
            </a>
          </div>
          <div className='flex gap-3 sm:gap-6 items-center relative z-50'>
            <div>
              <button
                className='flex flex-col h-12 w-12 rounded-lg justify-center items-center group dark:border-darkBorders border-borders hover:bg-borders dark:hover:bg-darkBorders bg-background dark:bg-darkBackground'
                onClick={() => setIsOpen(!isOpen)}
              >
                <div
                  className={`${genericHamburgerLine} ${
                    isOpen
                      ? "rotate-45 translate-y-3 group-hover:opacity-100 delay-300"
                      : "group-hover:opacity-100 delay-300"
                  }`}
                ></div>

                <div
                  className={
                    isOpen
                      ? "translate-x-5 group-hover:opacity-100 z-10 delay-100 rotate-90 ease-in-out duration-300"
                      : "group-hover:opacity-100 duration-300 delay-[600ms]"
                  }
                >
                  <div
                    className={`${genericHamburgerLine} ${
                      isOpen
                        ? `"z-10 ease-in-out delay-[900ms] duration-300" ${translateLength}`
                        : " group-hover:opacity-100 duration-300"
                    }`}
                  />
                </div>

                <div
                  className={`${genericHamburgerLine} ${
                    isOpen
                      ? "-rotate-45 -translate-y-3 group-hover:opacity-100 delay-300"
                      : "group-hover:opacity-100 delay-300"
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileNav;
