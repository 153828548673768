import React, { useEffect, useState } from "react";
import { YomiIcon } from "../../../icons/BrandIcons";
import { LinkIcon } from "../../../icons/FunctionalIcons";
import {
  DiscordIcon,
  InstagramIcon,
  TwitterIcon,
  WebsiteIcon,
} from "../../../icons/SocialIcons";
import YomiCard from "./YomiCard";
import { useSpring, animated as a, to, config } from "react-spring";
import { SOLIcon } from "../../../icons/CryptoIcons";

function YomiSection() {
  // const [count, setCount] = useState(0);

  // let timer;
  // const updateCount = () => {
  //   timer =
  //     !timer &&
  //     setInterval(() => {
  //       setCount((prevCount) => prevCount + 1);
  //     }, 5000);

  //   if (count === 5) {
  //     setCount(0);
  //   }
  // };

  // useEffect(() => {
  //   updateCount();

  //   return () => clearInterval(timer);
  // }, [count]); // new

  return (
    <>
      <div className='w-11/12 mx-auto h-[1000px] flex items-center relative'>
        <div className='w-full flex mx-auto col-span-2 h-auto absolute left-0 top-0 overflow-hidden'>
          <div className='w-full h-auto mx-auto shadow-white shadow-inner z-20 relative'>
            <div className='w-full bottom-0 h-[500px] bg-gradient-to-t from-cardBackground absolute z-20 translate-y-1'></div>
            <div className='w-full top-0 h-[500px] bg-gradient-to-b from-cardBackground absolute z-20'></div>
            <div className='w-1/2 left-0 h-[1000px] from-cardBackground absolute z-20 bg-gradient-to-r'></div>
            <div className='w-1/2 right-0 h-[1000px] from-cardBackground absolute z-20 bg-gradient-to-l'></div>
            <video
              className='w-full h-[1000px] object-cover justify-self-center opacity-[0.6]  mx-auto relative z-0 overflow-hidden shadow-inner shadow-white'
              autoPlay
              loop
              muted
            >
              <source
                className='w-full h-full'
                src='../yomi.mp4'
                type='video/mp4'
              />
            </video>
          </div>
        </div>
        <div className='w-full mx-auto h-full relative z-20 flex justify-center place-content-center'>
          <div className='grid lg:grid-cols-2 grid-cols-1 place-content-center place-items-around mx-auto max-w-6xl'>
            <div className='w-full rounded-3xl'>
              <div className=' backdrop-blur-sm backdrop-brightness-[.9] rounded-3xl p-3'>
                <div className='flex relative justify-items-center flex-nowrap'>
                  <YomiIcon iconClass='fill-[#98e2fa] w-20 h-20 my-auto' />
                  <h2 className='text-white text-4xl capitalize py-5 h-auto align-bottom '>
                    <span className='text-[#98e2fa] text-8xl align-top px-1'>
                      {" "}
                      YOMI
                    </span>
                    <span className='italic text-4xl lg:text-left text-center'>
                      by Rogue Studios
                    </span>
                  </h2>
                </div>
                <p className='text-white pb-4 text-lg lg:text-left text-center'>
                  YOMI by Rogue Studios is a collection of high-quality 3D
                  Samurai NFTs. Holders earn a percentage of revenue generated
                  from Rogue Studios SaaS products.
                </p>
                <div className='grid grid-cols-2 gap-2 justify-center pb-4'>
                  <div className='w-full'>
                    <button className='bg-[#98e2fa] border-[#98e2fa] font-anton hover:bg-transparent hover:text-[#98e2fa] rounded-full w-full py-2 px-4 text-xl'>
                      <a href='https://yomi.roguestudios.io/'>Enter YOMI</a>
                    </button>
                  </div>
                  <div className='w-full'>
                    <button className='bg-black text-[#98e2fa] font-anton  border-black hover:bg-transparent hover:border-[#98e2fa] rounded-full w-full py-2 px-4 text-xl'>
                      <a href='https://docs.roguestudios.io/yomi-nfts/yomi-project-information'>
                        Project Docs
                      </a>
                    </button>
                  </div>
                </div>
                <div className='grid grid-cols-5 w-[60%] py-3 lg:visible invisible'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://twitter.com/yominft'
                  >
                    <span className='flex flex-nowrap gap-2 bg-[#98e2fa] rounded-full group duration-500 hover:bg-cardBackground mx-2 aspect-square'>
                      <TwitterIcon iconClass='w-7 h-7 m-auto fill-cardBackground  group-hover:fill-[#98e2fa] duration-500  self-start' />
                    </span>
                  </a>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://discord.gg/roguestudios'
                  >
                    <span className='flex flex-nowrap gap-2 bg-[#98e2fa] rounded-full group duration-500 hover:bg-cardBackground mx-2 aspect-square'>
                      <DiscordIcon iconClass='w-7 h-7 m-auto fill-cardBackground  group-hover:fill-[#98e2fa] duration-500  self-start' />
                    </span>
                  </a>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://instagram.com/yominft'
                  >
                    <span className='flex flex-nowrap gap-2 bg-[#98e2fa] rounded-full group duration-500 hover:bg-cardBackground mx-2 aspect-square'>
                      <InstagramIcon iconClass='w-7 h-7 m-auto fill-cardBackground group-hover:fill-[#98e2fa] duration-500  self-start' />
                    </span>
                  </a>

                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://roguestudios.io/yomi-wayfinder'
                  >
                    <span className='flex flex-nowrap gap-2 bg-[#98e2fa] rounded-full group duration-500 hover:bg-cardBackground mx-2 aspect-square'>
                      <LinkIcon iconClass='w-7 h-7 m-auto fill-[#9cardBackground group-hover:fill-[#98e2fa] duration-500 self-start' />
                    </span>
                  </a>

                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://yomi.roguestudios.io'
                  >
                    <span className='flex flex-nowrap gap-2 bg-[#98e2fa] rounded-full group duration-500 hover:bg-cardBackground mx-2 aspect-square'>
                      <WebsiteIcon iconClass='w-7 h-7 m-auto fill-cardBackground  group-hover:fill-[#98e2fa] duration-500 self-start' />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <YomiCard />
          </div>
        </div>
      </div>
    </>
  );
}

export default YomiSection;
