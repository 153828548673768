import React, { PureComponent } from "react";
import { YomiIcon } from "../../../icons/BrandIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class CalendarCardYomi extends PureComponent {
  render() {
    return (
      <div>
        <a
          href='https://yomi.roguestudios.io/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='h-full bg-cardBackground duration-500 hover:bg-borders'>
            <div className='p-6 h-full'>
              <div className='h-full'>
                <div className='flex flex-wrap h-full justify-left'>
                  <div>
                    <div>
                      <YomiIcon iconClass='w-fit h-12 sm:mx-0 mx-auto fill-heading' />
                    </div>
                    <div className='pt-4 h-min'>
                      <h4 className='w-full truncate text-darkCB/90 xl:text-3xl text-2xl sm:text-left text-center'>
                        Yomi
                      </h4>

                      <p className='text-sm my-2 pb-2 text-bodySecondary sm:text-left text-center'>
                        A collection of 5,000 high-quality 3D NFTs built on the
                        Solana blockchain. Participate in our staking model to
                        earn a percentage of revenue generated by the platforms
                        we are building.
                      </p>
                    </div>
                  </div>
                  <div className='h-min mt-auto sm:mx-0 mx-auto'>
                    <p className='py-2 px-4  rounded font-bold text-xs text-darkBody bg-darkCB border-DarkBorders border-2 hover:bg-darkBorders'>
                      Live
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
