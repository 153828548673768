import React, { useState } from "react";
import { addSubscription } from "../api";

export default function EmailInput() {
  const [form, setForm] = useState({
    email: "",
  });

  const [errormsg, setErrorMsg] = useState("");
  const [placeholder, setPlacholder] = useState("Enter your email");

  function updateForm(value) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var emailInput = document.getElementById("email-input").value;
    if (!regEmail.test(emailInput)) {
      setErrorMsg("Please enter your email correctly");
      return setForm((prev) => {
        return { ...prev, ...value };
      });
    } else {
      setErrorMsg("");
      return setForm((prev) => {
        return { ...prev, ...value };
      });
    }
  }
  async function onSubmit(e) {
    const newGiveaway = {
      email: form.email,
    };

    await addSubscription(newGiveaway);
    setForm({ email: "" });

    alert("Thank you for joining the Rogue Studios mailing list.");
  }

  return (
    <div>
      <div className="w-full flex justify-end items-center border-borders dark:border-darkBorders mb-4">
        <div className="w-full h-fit relative">
          <input
            placeholder={placeholder}
            value={form.email}
            id="email-input"
            onChange={(e) => updateForm({ email: e.target.value })}
            type="email"
            className="block w-full rounded-lg py-4 px-4 text-sm focus:outline-none appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders"
          />
          <div className="absolute right-0 top-0 bottom-0 w-fit h-full p-2">
            <button
              onClick={onSubmit}
              type="submit"
              className="bg-heading w-fit h-full px-5 text-sm rounded-md font-bold flex items-center text-center text-background dark:text-darkBody border-heading dark:border-heading hover:bg-transparent hover:text-body"
            >
              Subscribe
            </button>
          </div>
        </div>
        <p className="text-sm text-body text-center pb-1 pt-3">{errormsg}</p>
      </div>
    </div>
  );
}
