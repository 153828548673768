import React from "react";
/*
-------------------------------------------------------------------------------------------------
    Icons use a prop with the 'iconClass' tag rather than 'classname'
-------------------------------------------------------------------------------------------------
*/

/*
-------------------------------------------------------------------------------------------------
    Template
-------------------------------------------------------------------------------------------------
Title
export const AVAXIcon = (props) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        width=""
        height=""
        view ox=""
        preserveAspectRatio="xMidYMid meet"
        className={props.iconClass}
        >
        <g
            transform=""
            fill={props.fill}
            stroke="none"
        >
            <path d="" />
        </g>
      </svg>
    );
};
*/

/*
-------------------------------------------------------------------------------------------------
    Rogue Studios
-------------------------------------------------------------------------------------------------
*/
export const RogueIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="500.000000pt"
      height="500.000000pt"
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M2350 4875 c-8 -2 -55 -7 -103 -10 -49 -4 -86 -9 -83 -12 5 -5 188
                -32 219 -33 14 0 18 6 15 27 -3 27 -16 35 -48 28z"
        />
        <path
          d="M2472 4844 l25 -39 199 -27 c373 -51 757 -98 761 -95 8 9 -194 79
                -321 112 -169 45 -359 73 -545 82 l-143 6 24 -39z"
        />
        <path
          d="M1740 4761 c-132 -44 -398 -156 -389 -165 3 -3 266 -37 834 -106 l80
                -10 33 37 c33 38 102 165 102 189 0 8 -10 16 -22 18 -49 11 -495 66 -523 65
                -16 0 -68 -13 -115 -28z"
        />
        <path
          d="M2526 4669 c4 -19 10 -38 14 -44 15 -21 42 -116 36 -130 -7 -20 -33
                -19 -45 2 -9 17 -12 16 -36 -7 -31 -30 -24 -35 65 -44 36 -4 81 -9 100 -12 26
                -4 31 -3 20 5 -8 5 -11 13 -7 17 8 8 87 -16 87 -26 0 -4 18 -10 41 -14 36 -6
                43 -3 70 24 17 16 35 45 40 63 5 18 14 41 21 50 12 16 129 70 136 63 1 -2 -3
                -24 -10 -49 -9 -35 -9 -60 0 -108 6 -34 15 -66 19 -70 5 -5 139 -24 298 -44
                532 -64 770 -92 773 -90 6 5 -187 152 -288 218 l-115 75 -540 71 c-297 39
                -573 73 -613 77 l-72 7 6 -34z"
        />
        <path
          d="M1085 4436 c-60 -42 -128 -91 -150 -110 -40 -34 -40 -34 -15 -40 43
                -10 1352 -157 1357 -153 2 2 -13 42 -33 88 -24 54 -38 100 -38 129 l-1 45
                -420 51 c-231 29 -458 55 -505 59 l-85 6 -110 -75z"
        />
        <path
          d="M2397 4364 c-9 -9 -9 -66 -1 -88 5 -13 15 -11 65 14 71 37 92 37 109
                5 12 -22 20 -25 66 -25 37 0 54 4 58 15 3 8 12 12 21 9 13 -5 34 21 24 31 -2
                2 -62 10 -134 19 -71 9 -146 19 -165 22 -20 3 -39 2 -43 -2z"
        />
        <path
          d="M2845 4279 c-15 -28 -15 -32 -1 -48 9 -10 16 -22 16 -28 0 -17 45
                -53 67 -53 21 0 53 29 53 49 0 6 -11 34 -25 61 -23 45 -28 50 -60 50 -28 0
                -37 -5 -50 -31z"
        />
        <path
          d="M3110 4284 c0 -2 14 -52 31 -110 17 -59 29 -115 26 -124 -6 -22 -37
                -17 478 -75 779 -87 840 -93 844 -89 3 3 -82 106 -176 212 l-38 43 -560 70
                c-308 38 -570 70 -582 72 -13 3 -23 3 -23 1z"
        />
        <path
          d="M697 4108 c-97 -105 -131 -147 -125 -153 2 -2 53 -9 113 -15 61 -6
                386 -40 723 -75 338 -36 625 -65 638 -65 15 0 27 7 30 18 3 9 12 33 21 52 13
                30 13 35 -1 45 -13 10 -12 16 12 46 15 19 33 46 39 61 14 32 39 27 -332 68
                -154 17 -438 49 -632 70 -193 22 -363 40 -376 40 -19 0 -47 -23 -110 -92z"
        />
        <path
          d="M3058 3934 c-11 -11 -10 -92 1 -98 14 -9 44 -113 38 -130 -5 -11 4
                -16 36 -21 76 -10 1260 -134 1382 -145 66 -5 140 -13 163 -16 l44 -7 -23 44
                c-44 87 -130 219 -143 219 -7 0 -305 34 -662 75 -690 79 -825 92 -836 79z"
        />
        <path
          d="M423 3762 c-91 -137 -98 -151 -81 -156 7 -3 168 -19 358 -36 190 -18
                527 -49 749 -71 339 -32 407 -36 420 -25 9 7 23 15 32 19 9 3 28 29 44 59 15
                29 46 75 68 101 22 26 38 49 36 51 -2 2 -85 11 -184 21 -223 21 -1287 132
                -1336 140 l-35 5 -71 -108z"
        />
        <path
          d="M3098 3569 c-10 -10 -31 -19 -47 -19 -34 0 -57 -24 -53 -56 2 -13 -8
                -48 -22 -78 -14 -30 -26 -57 -26 -60 0 -3 16 -6 35 -6 32 0 227 -19 1412 -135
                254 -25 464 -45 467 -45 7 0 -35 116 -69 194 -14 31 -27 56 -30 56 -3 0 -278
                29 -613 64 -658 70 -1024 107 -1032 105 -3 0 -13 -9 -22 -20z"
        />
        <path
          d="M247 3453 c-46 -93 -91 -202 -86 -208 4 -4 216 -23 1154 -104 l220
                -19 25 24 c14 13 31 44 38 69 9 33 28 62 70 105 32 33 53 60 46 60 -17 0
                -1331 129 -1386 136 l-47 6 -34 -69z"
        />
        <path
          d="M2924 3235 c0 -14 3 -25 6 -25 4 0 12 -16 19 -35 9 -26 24 -40 59
                -58 29 -14 64 -45 92 -80 44 -55 46 -56 105 -61 33 -3 438 -39 900 -79 462
                -41 843 -72 847 -71 6 4 -17 123 -41 211 l-10 38 -458 44 c-252 24 -686 65
                -964 92 -279 27 -518 49 -532 49 -20 0 -25 -5 -23 -25z"
        />
        <path
          d="M102 3078 c-25 -86 -54 -209 -50 -213 3 -2 1008 -80 1168 -91 l95 -6
                18 39 c9 22 17 46 17 54 0 18 56 128 77 152 9 9 14 19 12 21 -4 3 -1270 117
                -1300 116 -11 0 -22 -22 -37 -72z"
        />
        <path
          d="M3224 2858 c4 -13 9 -45 13 -73 3 -27 11 -57 18 -65 7 -8 15 -31 19
                -50 11 -59 14 -60 147 -70 68 -5 449 -35 847 -66 397 -31 724 -55 727 -53 6 7
                -16 233 -24 242 -5 4 -140 19 -302 33 -162 14 -549 47 -860 75 -311 27 -572
                49 -579 49 -8 0 -10 -8 -6 -22z"
        />
        <path
          d="M26 2728 c-14 -93 -24 -233 -17 -240 6 -7 805 -67 1224 -92 117 -8
                124 -1 83 80 -24 47 -27 64 -24 125 3 63 1 70 -17 74 -26 5 -1170 95 -1211 95
                -30 0 -32 -3 -38 -42z"
        />
        <path
          d="M3339 2488 c-1 -13 0 -27 1 -33 1 -5 3 -23 5 -39 4 -33 24 -50 42
                -35 8 7 36 6 90 -4 121 -24 156 -27 168 -17 16 13 179 -18 202 -39 11 -9 28
                -21 39 -27 12 -7 20 -24 22 -48 3 -29 7 -36 25 -37 12 0 254 -17 537 -38 283
                -21 516 -37 518 -36 1 1 3 58 5 126 l2 124 -115 8 c-63 5 -422 33 -798 63
                -375 30 -696 54 -712 54 -24 0 -30 -4 -31 -22z"
        />
        <path
          d="M4 2254 c4 -74 9 -141 12 -149 8 -20 -83 -13 1408 -100 147 -9 270
                -14 272 -12 2 2 -7 17 -21 33 -46 53 -74 110 -86 178 -7 38 -18 70 -23 72 -8
                3 -1532 114 -1564 114 -3 0 -2 -61 2 -136z"
        />
        <path
          d="M4035 2089 c4 -12 26 -48 49 -81 22 -32 44 -64 47 -71 3 -7 11 -22
                17 -34 7 -13 12 -31 12 -42 0 -26 18 -31 118 -31 48 -1 213 -9 367 -19 154
                -10 282 -17 283 -16 6 5 23 84 36 167 l13 76 -66 6 c-36 4 -228 17 -426 31
                -198 13 -382 27 -408 30 -46 6 -48 5 -42 -16z"
        />
        <path d="M3917 2006 c-3 -8 0 -19 7 -25 10 -8 12 -6 11 9 -2 26 -11 34 -18 16z" />
        <path
          d="M35 1990 c-3 -5 6 -62 20 -127 14 -65 28 -131 32 -146 3 -16 11 -30
                17 -32 6 -2 204 -13 441 -25 992 -48 1433 -70 1483 -74 l52 -5 0 84 c0 51 -4
                87 -11 91 -8 4 -10 26 -6 60 l6 54 -32 2 c-18 1 -64 2 -102 3 -75 0 -72 2 -77
                -52 0 -10 2 -30 6 -44 7 -23 4 -28 -23 -37 -27 -10 -33 -8 -51 16 -60 78 -88
                121 -83 125 10 11 -70 17 -817 61 -415 25 -776 48 -802 50 -26 3 -50 1 -53 -4z"
        />
        <path
          d="M3980 1890 c11 -22 25 -40 30 -40 6 0 -2 18 -16 40 -15 22 -28 40
                 -31 40 -2 0 6 -18 17 -40z"
        />
        <path
          d="M3722 1657 l3 -93 -33 -32 -33 -32 28 0 c15 0 46 -3 68 -6 36 -5 38
                -4 22 9 -23 19 -32 77 -19 115 8 23 7 43 -5 81 -8 28 -19 51 -24 51 -5 0 -8
                -40 -7 -93z"
        />
        <path
          d="M4037 1739 c7 -7 8 -20 4 -33 -5 -11 -12 -43 -16 -70 -8 -58 -30 -96
                -55 -96 -21 0 -34 15 -60 66 -16 35 -19 37 -26 19 -4 -11 -24 -46 -45 -77 -30
                -46 -35 -58 -21 -58 9 0 237 -12 506 -26 268 -14 492 -22 496 -17 11 11 80
                215 80 235 0 18 -18 20 -434 45 -210 12 -301 14 -303 7 -2 -6 -10 -19 -17 -29
                -17 -24 -58 -14 -76 20 -7 14 -20 25 -29 25 -11 0 -12 -3 -4 -11z"
        />
        <path
          d="M3527 1677 l-48 -22 6 -70 c4 -38 7 -70 8 -71 1 -1 19 -4 40 -7 31
                -5 37 -3 37 11 0 10 7 27 15 39 11 16 13 36 9 82 -4 34 -10 61 -13 60 -3 0
                -28 -11 -54 -22z"
        />
        <path
          d="M130 1581 c0 -12 61 -170 93 -242 l26 -57 73 -6 c40 -3 201 -10 358
                -16 157 -6 454 -17 661 -26 371 -15 377 -15 420 4 24 11 50 22 57 24 7 2 -8
                18 -33 36 -66 46 -95 135 -65 193 10 17 7 19 -27 19 -21 0 -373 18 -783 40
                -848 45 -780 43 -780 31z"
        />
        <path
          d="M2000 1485 c0 -2 9 -9 19 -14 14 -8 20 -7 25 4 4 11 -2 15 -19 15
                -14 0 -25 -2 -25 -5z"
        />
        <path
          d="M3670 1358 c0 -30 -6 -62 -14 -73 -7 -11 -16 -34 -20 -51 -4 -17 -20
                -45 -37 -62 l-30 -30 73 -6 c130 -11 989 -45 1001 -40 13 5 142 242 135 249
                -2 2 -220 16 -483 29 -264 14 -513 28 -552 31 l-73 6 0 -53z"
        />
        <path
          d="M300 1185 c0 -10 102 -176 149 -242 l52 -73 97 0 c53 0 134 -3 179
                -6 l82 -7 29 33 c21 23 36 31 51 27 12 -3 22 1 25 9 3 8 18 14 34 14 15 0 51
                11 80 25 28 14 62 24 74 23 13 -1 49 11 81 25 32 15 60 27 63 27 3 0 33 13 67
                29 34 15 80 34 102 42 l40 13 -30 7 c-16 4 -273 17 -570 29 -297 11 -555 22
                -572 25 -18 2 -33 2 -33 0z"
        />
        <path
          d="M1880 1077 c-14 -7 -43 -18 -65 -26 -22 -7 -58 -22 -80 -31 -51 -23
                -281 -116 -360 -146 -35 -13 -54 -24 -45 -27 8 -3 107 -8 220 -11 193 -5 207
                -4 235 14 17 11 42 20 58 20 32 0 38 12 57 115 7 39 17 78 22 88 11 21 -5 23
                -42 4z"
        />
        <path
          d="M3470 1026 c0 -37 65 -207 83 -218 10 -5 17 -15 17 -23 0 -14 81 -20
                405 -30 99 -3 234 -8 300 -11 l120 -6 50 63 c28 35 72 94 98 131 l48 67 -43 5
                c-36 4 -1008 47 -1060 46 -12 0 -18 -8 -18 -24z"
        />
        <path d="M580 771 c0 -6 4 -12 9 -15 13 -8 42 4 35 15 -7 12 -44 12 -44 0z" />
        <path
          d="M1115 749 c-11 -7 -29 -19 -40 -29 -19 -16 -64 -32 -128 -45 -56 -11
                -125 -48 -136 -71 -6 -13 -17 -24 -26 -24 -27 0 -16 -17 44 -69 l59 -51 213
                -5 c118 -3 256 -7 306 -10 l93 -4 14 34 c17 39 56 63 120 71 50 7 52 10 79
                130 l14 62 -226 6 c-124 4 -257 9 -296 12 -44 3 -77 0 -90 -7z"
        />
        <path
          d="M3573 666 c4 -10 7 -39 7 -66 l0 -49 54 -11 c30 -6 67 -20 81 -32 23 -18 26 -27 23 -67 l-3 -46 70 -6 c39 -4 102 -7 141 -8 l70 -1 70 57 c38 32
                104 91 147 132 65 62 75 76 60 82 -10 3 -133 9 -273 13 -140 3 -300 9 -354 13 -92 6 -99 5 -93 -11z"
        />
        <path d="M1020 357 c0 -18 365 -236 395 -237 9 0 70 219 63 225 -6 5 -458 17 -458 12z" />
        <path d="M3765 290 c-6 -10 3 -70 10 -70 2 0 27 14 55 32 43 27 49 33 33 39 -28 11 -91 10 -98 -1z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Radar Icon
-------------------------------------------------------------------------------------------------
*/
export const RadarIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="400"
      viewBox="0 0 400 400"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill={props.fill} stroke="none">
        <path
          d="M200 400C310.457 400 400 310.457 400 200C400 89.5431 310.457 0 200
          0C89.5432 0 0 89.5431 0 200C0 310.457 89.5432 400 200 400ZM134.052
          63.2171C188.302 38.0137 250.598 45.6307 296.02 83.0171C300.92 87.0503
          308.123 94.0443 312.028 98.5591C318.082 105.56 319.117 107.651 319.063
          112.766L318.999 118.764L274.375 156.92L229.751 195.078L229.928
          200.415C230.16 207.427 225.09 217.82 218.894 223.034C213.299 227.742
          202.244 231.303 196.042 230.397C184.939 228.772 174.442 219.732 171.032
          208.856C168.426 200.544 168.743 195.553 172.364 187.878C178.051 175.821
          192.744 167.793 204.614 170.258C209.708 171.318 210.402 170.952 222.385
          160.868L234.854 150.376L230.096 147.494C222.778 143.061 206.962 138.839
          199.092 139.214C195.199 139.402 187.548 140.746 182.093 142.206C173.631
          144.468 170.536 146.235 161.013 154.249C151.099 162.592 149.312 164.828
          145.02 174.264C138.879 187.762 137.658 201.95 141.404 216.3C144.021
          226.33 145.4 228.84 153.743 238.755C161.758 248.28 164.505 250.551
          172.456 254.227C185.381 260.202 193.944 261.816 205.648 260.482C219.598
          258.892 231.978 253.134 241.651 243.738C249.63 235.986 257.103 222.72
          259.21 212.573C261.901 199.612 277.162 195.358 285.486 205.25C290.563
          211.284 290.213 218.518 284.117 233.629C268.93 271.27 232.236 293.836
          191.87 290.358C166.603 288.182 146.643 277.832 130.336 258.452C114.028
          239.073 107.242 217.638 109.416 192.37C111.589 167.103 121.937 147.144
          141.314 130.838C160.691 114.533 182.124 107.749 207.392 109.926C223.391
          111.304 237.845 116.302 250.663 124.891L258.723 130.291L270.417
          120.451C276.848 115.039 281.716 110.142 281.234 109.569C280.752 108.996
          275.643 105.155 269.883 101.034C234.918 76.0239 186.06 71.8147 147.339
          90.4767C132.972 97.4023 111.236 115.269 101.788 127.921C92.942 139.767
          82.546 163.832 80.2288 177.824C73.944 215.776 82.9124 249.307 107.036
          278.058C120.947 294.638 133.97 304.314 152.882 312.12C195.85 329.854
          242.024 322.69 277.577 292.773C301.493 272.648 314.874 248.571 320.018
          216.408C321.474 207.306 323.576 198.417 324.689 196.656C327.608 192.041
          335.858 189.972 341.683 192.392C350.062 195.878 352.28 202.178 350.404
          217.18C347.126 243.42 338.272 266.63 323.333 288.158C311.684 304.946
          283.742 328.458 265.21 337.067C214.599 360.579 157.482 355.658 111.632
          323.836C94.9076 312.232 71.3224 284.233 62.7856 265.851C39.2299 215.135
          44.1168 157.969 75.9284 112.126C87.578 95.3383 115.52 71.8259 134.052
          63.2171Z"
        />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Rarity Icon
-------------------------------------------------------------------------------------------------
*/
export const RarityIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="400"
      viewBox="0 0 400 400"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill={props.fill} stroke="none">
        <path
          d="M200 400C310.457 400 400 310.457 400 200C400 89.5431 310.457 0 200 0C89.5432 0 0 89.5431 0 200C0 310.457 89.5432 400 200 400ZM130.399 78.0471C130.506 77.9959 130.615 77.9475 130.732 77.8943C132.287 77.1883 133.808 77.1171 147.307 77.1171C161.007 77.1171 162.31 77.1803 163.949 77.9239C167.539 79.5531 169.582 84.2863 168.267 87.9227C167.64 89.6575 127.545 148.904 125.502 151.116C125.154 151.493 124.867 151.81 124.54 152.078C122.768 153.524 119.799 153.505 99.4168 153.505H77.1912L75.2584 152.28C71.8196 150.1 70.5524 145.593 72.3212 141.832C72.776 140.865 83.3992 128.802 96.9944 113.816C127.319 80.3875 128.689 78.8615 130.399 78.0471ZM230.86 89.3159C228.556 85.1223 230.492 79.7303 235.004 77.7747C236.237 77.2403 239.109 77.1291 252.027 77.1151C267.35 77.0987 267.591 77.1115 269.335 78.0535C270.588 78.7303 279.216 87.9655 298.936 109.737C314.244 126.638 327.074 141.054 327.446 141.774C329.101 144.974 327.957 149.486 324.854 151.997L323.253 153.293L299.885 153.41L276.516 153.526L274.761 152.498C273.256 151.616 270.062 147.153 252.411 121.262C241.083 104.648 231.386 90.2727 230.86 89.3159ZM193.315 94.7687C193.936 94.0983 195.018 93.2939 195.719 92.9811C197.769 92.0667 201.396 92.2423 203.363 93.3519C205.038 94.2963 208.048 98.5343 234.786 137.603C238.772 143.429 239.192 145.293 237.362 149.033C236.48 150.836 235.749 151.656 234.226 152.55L232.24 153.717H199.329C166.867 153.717 166.394 153.705 164.741 152.838C163.82 152.355 162.49 151.25 161.787 150.382C160.741 149.089 160.481 148.328 160.348 146.167C160.259 144.716 160.374 143.052 160.604 142.468C161.138 141.116 191.802 96.3999 193.315 94.7687ZM275.62 184.492C276.4 182.619 278.153 180.736 279.803 180C281.445 179.267 323.412 179.129 325.321 179.85C329.284 
            181.346 331.591 186.7 329.957 190.608C329.445 191.833 243.63 315.016 241.882 317.036C240.565 318.557 237.124 317.355 237.124 315.373C237.124 314.507 274.752 186.575 275.62 184.492ZM149.562 189.973C148.738 186.469 150.616 182.722 154.102 180.913C155.231 180.328 160.367 180.252 199.24 180.25C242.2 180.247 243.137 180.264 244.76 181.092C247.696 182.589 249.845 186.656 249.186 189.464C247.946 194.738 201.724 346.958 201.178 347.56C200.266 348.568 198.386 348.562 197.466 347.546C196.861 346.877 150.929 195.793 149.562 189.973ZM69.3456 186.99C69.9492 184.976 71.8512 182.723 73.7292 181.798C74.9452 181.198 77.746 181.114 96.744 181.106C117.928 181.096 118.412 181.115 120.235 181.996C121.29 182.507 122.531 183.564 123.101 184.437C124.32 186.304 162.488 315.702 162.296 317.32C162.14 318.646 160.38 319.706 158.936 319.343C158.144 319.145 155.043 315.013 146.38 302.617C140.054 293.563 120.152 265.092 102.154 239.349C70.9932 194.778 68.3388 191.174 68.9616 188.273C69.0488 187.868 69.1996 187.477 69.3456 186.99Z"
        />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Banner Icon
-------------------------------------------------------------------------------------------------
*/
export const BannerIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="400"
      viewBox="0 0 400 400"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill={props.fill} stroke="none">
        <path
        d="M200 400C310.457 400 400 310.457 400 200C400 89.5431 310.457 0 200 0C89.5432 0 0 89.5431 0 200C0 310.457 89.5432 400 200 400ZM130.376 109.91C146.555 107.263 161.51 106.487 173.297 107.685C184.398 108.813 203.531 114.76 213.91 120.311C218.724 122.884 230.003 130.428 238.975 137.075C247.948 143.722 257.823 150.069 260.919 151.181C270.418 154.59 284.091 153.958 307.135 149.046C318.922 146.534 329.322 144.664 330.248 144.891C330.388 144.926 330.708 144.92 331.146 144.911C333.605 144.865 339.744 144.749 338.126 151.339L337.395 154.319L321.42 219.416C308.878 270.521 306.834 283.364 299.635 287.758C296.108 289.91 291.343 290.034 283.501 291.636C261.887 296.05 251.944 296.419 236.205 293.39C220.23 290.316 215.022 287.61 196.704 272.859C179.902 259.328 172.041 255.122 156.663 251.443C137.864 246.944 112.108 248.129 83.0704 254.828C70.5348 257.721 64.6876 255.45 63.9204 247.389C63.2704 240.552 91.8012 125.656 95.3376 120.87C98.4964 114.702 105.046 113.73 119.546 111.578C122.751 111.103 126.344 110.57 130.376 109.91Z"
         />
      </g>
    </svg>
  );
};


/*
-------------------------------------------------------------------------------------------------
    Newsletter
-------------------------------------------------------------------------------------------------
*/
export const NewsletterIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="1760.000000pt"
      height="1761.000000pt"
      viewBox="0 0 1760.000000 1761.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,1761.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M545 17361 c-38 -4 -80 -12 -92 -19 -12 -6 -30 -12 -41 -12 -10 0
            -29 -9 -42 -20 -13 -11 -31 -20 -41 -20 -10 0 -22 -6 -26 -13 -4 -6 -27 -25
            -50 -40 -43 -30 -84 -72 -118 -121 -42 -62 -55 -82 -60 -96 -3 -8 -15 -32 -25
            -52 -11 -20 -20 -53 -20 -72 0 -19 -7 -41 -15 -50 -13 -13 -15 -825 -15 -7189
            0 -6345 2 -7178 15 -7203 9 -18 15 -56 15 -99 0 -40 9 -100 20 -140 11 -38 20
            -83 20 -98 0 -16 5 -38 12 -50 6 -12 15 -40 19 -62 8 -46 36 -127 55 -167 8
            -14 14 -37 14 -51 0 -13 7 -30 15 -37 8 -7 15 -19 15 -27 0 -9 8 -32 18 -52
            11 -20 38 -74 61 -120 24 -46 51 -98 62 -115 10 -17 26 -46 36 -64 9 -17 20
            -32 25 -32 4 0 8 -5 8 -10 0 -13 22 -45 122 -175 104 -136 326 -360 418 -423
            19 -13 48 -35 65 -50 32 -29 56 -45 93 -61 12 -6 22 -15 22 -20 0 -5 12 -14
            27 -20 16 -6 42 -20 58 -31 17 -11 41 -25 55 -32 14 -7 29 -18 33 -25 4 -7 16
            -13 27 -13 10 0 25 -7 33 -15 8 -7 26 -17 40 -20 15 -4 31 -13 38 -21 6 -8 22
            -14 34 -14 12 0 28 -7 35 -15 7 -8 21 -15 32 -15 10 0 36 -9 58 -20 22 -11 47
            -20 57 -20 10 0 27 -7 37 -15 11 -8 31 -15 45 -15 14 -1 44 -9 66 -20 22 -11
            56 -20 75 -20 19 0 46 -7 60 -15 14 -8 46 -14 71 -15 25 0 70 -8 100 -17 51
            -17 394 -18 6624 -18 6241 0 6571 1 6595 17 13 10 31 18 40 18 37 0 176 89
            231 147 44 46 109 139 109 154 0 6 6 23 14 37 8 15 24 57 35 93 26 80 29 258
            6 299 -8 14 -14 34 -15 45 0 10 -18 57 -41 103 -32 65 -58 101 -121 164 -44
            44 -94 86 -111 94 -18 8 -39 18 -47 23 -88 49 -198 72 -377 80 -100 4 -156 10
            -165 19 -6 6 -28 12 -48 12 -19 0 -52 9 -73 20 -20 11 -44 20 -53 20 -9 0 -28
            6 -42 13 -139 70 -176 92 -259 155 -93 70 -189 165 -229 226 -16 22 -33 46
            -38 51 -15 14 -33 44 -50 81 -8 17 -19 35 -25 38 -6 4 -11 15 -11 25 0 10 -7
            24 -15 31 -8 7 -15 20 -15 29 0 9 -7 30 -16 46 -9 17 -20 46 -24 65 -5 19 -13
            49 -18 65 -37 108 -36 -58 -42 7335 -5 6799 -6 7167 -23 7197 -9 18 -17 40
            -18 50 0 10 -13 41 -30 68 -16 28 -33 59 -38 70 -12 25 -140 152 -172 170 -56
            31 -129 65 -139 65 -6 0 -23 8 -37 18 -26 16 -350 17 -6443 18 -3528 0 -6446
            -2 -6485 -5z m10605 -2980 c78 -30 124 -58 169 -101 52 -51 91 -103 91 -122 0
            -8 8 -25 18 -38 16 -24 17 -121 17 -1705 0 -1584 -1 -1682 -17 -1708 -10 -14
            -18 -35 -18 -47 0 -25 -132 -160 -180 -185 -19 -9 -57 -23 -85 -29 -59 -13
            -8280 -14 -8355 -1 -25 4 -56 16 -69 26 -13 11 -30 19 -37 19 -18 0 -134 116
            -134 134 0 7 -9 25 -20 39 -11 14 -20 36 -20 49 0 12 -7 41 -15 63 -21 60 -21
            3220 0 3280 8 22 15 51 15 63 0 13 9 35 20 49 11 14 20 31 20 37 0 17 111 123
            137 130 11 4 27 13 33 21 7 8 23 15 35 15 13 0 36 7 51 15 26 13 511 15 4169
            12 3483 -2 4146 -4 4175 -16z m65 -5476 c79 -39 150 -103 178 -162 11 -21 26
            -53 35 -72 20 -41 24 -214 5 -252 -6 -13 -21 -45 -33 -71 -23 -52 -140 -168
            -168 -168 -10 0 -24 -6 -30 -12 -36 -36 -60 -36 -4227 -37 -4287 -1 -4179 -2
            -4255 41 -71 39 -112 72 -144 114 -20 26 -36 52 -36 59 0 7 -7 18 -15 25 -8 7
            -15 23 -15 37 0 13 -7 47 -17 75 -14 44 -15 57 -3 97 7 25 16 62 20 81 4 19
            15 48 23 65 24 43 116 145 132 145 7 0 15 7 19 15 3 8 11 15 18 15 7 1 31 9
            53 20 39 19 110 19 4214 20 l4174 0 72 -35z m-5359 -2297 c64 -34 153 -121
            178 -173 10 -22 25 -53 32 -68 21 -42 21 -2511 0 -2554 -51 -104 -65 -128
            -101 -163 -22 -21 -71 -55 -110 -74 l-69 -36 -1498 0 c-1479 0 -1499 0 -1535
            20 -21 11 -43 20 -50 20 -7 0 -43 30 -81 68 -66 64 -112 133 -112 170 0 9 -7
            35 -15 57 -21 60 -21 2370 0 2430 8 22 15 48 15 58 0 41 69 141 132 192 56 45
            98 66 148 76 19 3 705 6 1525 5 l1490 -1 51 -27z m5340 11 c11 -9 29 -20 42
            -24 33 -10 134 -108 154 -149 10 -20 25 -53 35 -73 21 -43 25 -215 6 -254 -6
            -13 -21 -44 -32 -69 -12 -25 -31 -56 -44 -70 -34 -39 -113 -100 -127 -100 -7
            0 -22 -8 -33 -17 -18 -17 -114 -18 -1727 -18 -1613 0 -1709 1 -1727 18 -11 9
            -28 17 -38 17 -10 0 -25 7 -34 15 -63 59 -80 76 -108 110 -18 21 -33 45 -33
            53 0 9 -8 24 -17 35 -15 16 -18 39 -18 141 0 99 3 125 18 146 9 13 17 31 17
            39 0 15 62 96 95 122 11 9 31 26 44 38 13 12 32 21 41 21 10 0 23 7 30 14 6 8
            30 17 53 19 23 2 794 3 1713 3 1531 -1 1673 -2 1690 -17z m-94 -2280 c40 -6
            80 -15 88 -20 8 -5 30 -15 48 -24 46 -20 160 -137 168 -172 3 -15 14 -39 23
            -53 24 -37 23 -211 -2 -265 -33 -72 -49 -100 -71 -125 -33 -39 -108 -97 -136
            -105 -14 -4 -34 -14 -45 -21 -16 -12 -297 -14 -1705 -14 l-1685 0 -70 36 c-67
            35 -115 76 -162 142 -13 17 -23 36 -23 42 0 6 -8 23 -17 37 -15 22 -18 49 -18
            143 0 93 3 120 18 140 9 13 17 31 17 39 0 16 65 99 94 120 11 8 27 22 36 31
            27 27 99 60 133 61 18 1 43 5 57 9 41 13 3167 12 3252 -1z"
        />
        <path
          d="M14952 14618 c-9 -4 -30 -7 -46 -7 -37 -1 -110 -61 -136 -111 -20
            -39 -20 -83 -20 -5969 0 -5469 1 -5933 16 -5968 53 -121 114 -191 205 -236 45
            -21 82 -28 224 -42 94 -9 190 -20 215 -25 106 -21 173 -39 183 -49 6 -6 21
            -11 34 -11 22 0 33 -5 171 -73 63 -31 177 -101 192 -117 3 -3 24 -18 47 -33
            53 -36 228 -217 268 -277 16 -25 34 -48 38 -51 9 -7 27 -35 52 -84 15 -29 24
            -45 45 -82 6 -10 15 -29 20 -43 6 -14 17 -37 25 -52 8 -14 15 -35 15 -45 0
            -11 7 -28 15 -39 8 -10 15 -29 15 -41 0 -12 9 -45 20 -73 11 -28 20 -68 20
            -89 0 -22 6 -44 15 -51 8 -7 15 -19 15 -27 0 -29 78 -89 138 -106 51 -14 63
            -14 98 -2 21 8 45 14 52 15 22 0 147 133 217 229 14 19 28 37 32 40 11 8 53
            69 53 76 0 3 16 27 35 52 19 25 35 52 35 59 0 8 4 14 9 14 5 0 23 28 41 63 31
            62 41 82 60 115 6 9 15 28 20 42 12 30 37 84 56 123 8 16 14 34 14 40 0 7 9
            29 20 50 11 20 20 47 20 60 0 13 7 32 15 43 8 10 15 30 15 44 0 14 9 51 20 83
            11 31 20 74 20 96 0 22 6 44 15 51 13 11 15 691 15 6010 0 5322 -2 5999 -15
            6004 -8 3 -15 14 -15 24 0 11 -9 31 -20 45 -11 14 -20 28 -20 31 0 12 -44 69
            -86 111 -48 50 -170 131 -210 141 -16 4 -40 14 -54 23 -21 14 -115 16 -860 19
            -459 1 -951 3 -1093 5 -142 1 -266 -1 -275 -5z"
        />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    News Icon
-------------------------------------------------------------------------------------------------
*/
export const NewsIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="400"
      viewBox="0 0 400 400"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill={props.fill} stroke="none">
        <path d="M200 400C310.457 400 400 310.457 400 200C400 89.5432 310.457 0 200 0C89.5428 0 0 89.5432 0 200C0 310.457 89.5428 400 200 400ZM100.583 98.5412C101.218 97.582 102.448 96.4224 103.316 95.9644C104.872 95.1436 105.977 95.1324 184.357 95.1148C259.201 95.098 263.905 95.1396 265.263 95.8312C266.055 96.2352 267.285 97.292 267.995 98.1804L269.287 99.7944L269.497 188.72C269.701 275.049 269.518 279.262 271.475 281.87C271.756 282.245 272.082 282.587 272.46 283.136C274.729 286.432 279.661 289.196 283.282 289.202C288.323 289.209 292.217 292.645 292.191 297.063C292.179 299.328 290.869 301.914 289.021 303.322L287.431 304.534L206.673 304.65C128.596 304.762 122.912 304.964 118.382 302.776C117.637 302.416 116.923 301.992 115.924 301.492C112.89 299.975 111.359 298.853 108.657 296.17C103.465 291.014 100.684 285.673 99.6388 278.846C99.3384 276.885 99.2344 247.899 99.3124 188.125L99.4276 100.286L100.583 98.5412ZM279.594 202.894C279.588 135.876 279.641 130.184 280.283 129.475C280.931 128.76 281.952 128.706 295.041 128.706C310.885 128.706 311.065 128.736 313.272 131.781L314.484 133.454V206.539C314.484 276.706 314.635 281.704 312.737 285.737C312.444 286.36 312.101 286.961 311.701 287.776C310.037 291.171 306.721 295.474 305.401 295.95C303.705 296.562 302.613 295.504 301.464 292.136C298.942 284.747 293.631 280.402 285.996 279.481C282.565 279.067 281.015 278.295 280.119 276.554C279.713 275.764 279.599 259.717 279.594 202.894ZM239.247 134.6L237.797 133.019L236.347 131.437L185.125 131.33C137.003 131.23 133.815 131.268 132.432 131.96C131.882 132.236 131.436 132.454 131.072 132.768C129.476 134.15 129.49 137.394 129.49 155.532C129.489 177.271 129.496 177.333 131.943 178.993C133.006 179.714 135.654 179.759 183.516 179.878C217.296 179.962 234.464 179.86 235.466 179.57C236.289 179.332 237.425 178.646 237.989 178.046L239.015 176.955L239.131 155.778L239.247 134.6ZM237.563
         206.537C240.01 204.351 239.557 200.134 236.716 198.666C235.633 198.107 229.219 198.029 184.225 198.029H132.966L131.808 198.94C129.634 200.648 129.164 202.923 130.447 205.522C130.572 205.777 130.653 206.002 130.776 206.2C131.75 207.769 135.374 207.692 184.75 207.692H236.268L237.563 206.537ZM173.636 245.084C173.636 228.804 173.579 228.329 171.442 226.834C170.627 226.263 168.081 226.18 151.784 226.19C134.92 226.2 132.282 226.186 130.996 227.497C130.741 227.756 130.539 228.068 130.29 228.442C129.523 229.594 129.168 257.838 129.89 260.283C130.14 261.133 130.924 262.315 131.632 262.909L132.917 263.991H151.405C171.688 263.991 171.701 263.989 172.998 261.484C173.533 260.449 173.636 257.819 173.636 245.084ZM239.225 231.01C239.225 229.588 238.213 227.66 237.031 226.834C236.213 226.261 233.427 226.178 214.914 226.178H193.733L192.303 227.607C191.083 228.826 190.874 229.324 190.874 231.01C190.874 232.696 191.083 233.194 192.303 234.413L193.733 235.842H214.914C233.835 235.842 236.745 235.905 237.907 234.512C238.171 234.195 238.345 233.802 238.597 233.316C238.943 232.648 239.225 231.611 239.225 231.01ZM236.716 263.353C239.557 261.885 240.01 257.669 237.563 255.483L236.268 254.328H215.001H193.733L192.303 255.756C191.083 256.975 190.874 257.474 190.874 259.159C190.874 260.845 191.083 261.343 192.303 262.562L193.733 263.991H214.608C232.445 263.991 235.662 263.898 236.716 263.353Z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Yomi Icon
-------------------------------------------------------------------------------------------------
*/
export const YomiIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="400"
      height="400"
      viewBox="0 0 400 400"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g fill={props.fill} stroke="none">
        <path d="M363.012 316.014C386.302 283.305 400 243.288 400 200.071C400 89.5748 310.457 0 200 0C89.5432 0 0 89.5748 0 200.071C0 280.03 46.8896 349.034 114.663 381.067C115.022 379.794 115.688 378.494 116.659 377.272C119.615 373.55 120.084 371.454 118.502 369.039C116.702 366.29 117.571 363.315 121.228 359.7C125.347 355.629 130.66 346.594 130.783 343.45C130.838 342.062 131.54 340.109 132.344 339.11C133.147 338.112 134.817 336.029 136.056 334.479L138.308 331.663L136.722 330.4C135.85 329.705 134.95 328.275 134.724 327.223C134.497 326.17 133.789 323.904 133.15 322.187C131.779 318.502 129.603 306.562 129.599 302.71C129.596 299.432 132.959 295.362 136.216 294.702C137.414 294.46 141.319 292.809 144.895 291.034C150.925 288.041 151.948 287.565 151.896 287.299C151.88 287.22 151.771 287.16 151.669 287.058C151.468 286.857 147.909 287.907 143.76 289.391C137.114 291.767 134.388 293.393 120.859 303.045C92.336 323.394 81.2224 331.05 80.2012 331.05C78.8152 331.05 75.2876 329.397 75.2876 328.748C75.2876 328.238 81.7088 323.07 98.2352 310.28C102.652 306.861 107.091 303.416 108.1 302.625C109.815 301.279 109.852 301.096 108.674 299.794C107.981 299.027 107.414 298.06 107.414 297.643C107.414 297.225 111.456 295.186 116.395 293.111C121.998 290.756 127.437 287.799 130.856 285.247C136.247 281.224 136.314 281.133 134.879 279.698C134.078 278.896 133.421 277.729 133.421 277.106C133.421 276.483 132.531 275.749 131.442 275.475C129.679 275.032 129.515 274.704 129.936 272.464C130.382 270.082 130.168 269.782 125.884 266.776C123.396 265.029 120.318 262.302 119.042 260.714C117.262 258.497 115.377 257.335 110.918 255.708C107.725 254.543 104.894 253.237 104.629 252.807C104.363 252.376 104.042 245.98 103.917 238.592C103.658 223.303 103.835 222.627 109.3 218.055C111.179 216.484 113.088 214.142 113.54 212.852C114.268 210.779 114.164 210.37 112.652 209.356C108.085 206.295 107.866 
    205.956 109.154 203.943C110.318 202.125 113.534 188.094 113.534 184.837C113.534 183.834 112.935 183.034 112.004 182.79C111.162 182.57 110.482 182 110.49 181.522C110.522 179.913 113.28 174.54 114.664 173.395C116.927 171.52 118.888 165.932 118.888 161.355C118.888 152.849 122.184 147.798 128.106 147.228C130.638 146.984 130.732 146.846 132.431 140.895C133.664 136.575 133.92 134.658 133.311 134.282C132.299 133.656 132.844 129.932 134.928 123.252C135.715 120.726 136.592 116.943 136.876 114.843C137.688 108.858 138.844 107.296 143.561 105.809C148.265 104.324 163.208 104.027 171.661 105.249C174.916 105.72 176.89 105.704 177.391 105.202C177.901 104.692 177.831 104.236 177.181 103.833C176.643 103.501 176.425 102.656 176.695 101.952C177.464 99.9481 175.81 100.456 174.361 102.669C172.764 105.108 171.014 104.09 172.388 101.521C172.863 100.634 172.935 99.9085 172.548 99.9085C172.162 99.9085 171.666 100.597 171.446 101.439C171.226 102.281 170.46 102.97 169.745 102.97C168.936 102.97 168.602 102.559 168.86 101.885C169.089 101.289 168.787 100.613 168.19 100.384C167.563 100.143 167.278 99.3879 167.516 98.598C167.82 97.5909 167.59 97.6841 166.645 98.9518C164.321 102.069 162.793 
    100.99 163.122 96.4641C163.217 95.1532 163.006 95.2352 161.65 97.0383C160.779 98.1955 159.79 99.143 159.454 99.143C158.471 99.143 158.049 95.4725 158.93 94.5898C159.493 94.0272 159.467 93.7855 158.843 93.7855C158.354 93.7855 157.584 94.4742 157.134 95.3161C155.983 97.4676 154.265 97.238 154.265 94.9335C154.265 93.8812 154.567 93.0201 154.935 93.0201C155.302 93.0201 155.604 92.6863 155.604 92.2786C155.604 91.8713 155.088 91.736 154.456 91.9777C153.826 92.2202 153.309 92.8616 153.309 93.403C153.309 94.2433 151.744 95.3161 150.518 95.3161C149.867 95.3161 151.186 91.8937 152.052 91.3395C152.508 91.047 152.606 90.5312 152.269 90.1927C151.931 89.8553 150.964 90.5272 150.12 91.686C149.276 92.8448 148.255 93.5887 147.851 93.3386C147.4 93.0593 147.461 92.4667 148.011 91.804C148.504 91.2102 148.627 90.724 148.284 90.724C147.942 90.724 148.33 90.2183 149.147 89.6004C150.439 88.6225 150.465 88.4732 149.345 88.4524C148.637 88.4384 147.839 89.1167 147.572 89.9586C146.977 91.8352 146.141 91.8865 144.138 90.1714C140.182 86.7814 143.965 83.2886 156.038 79.1816C161.058 77.4734 168.952 74.7608 173.58 73.1534C178.207 71.5469 186.598 68.6522 192.227 66.7219C197.855 64.7909 205.828 61.4809 209.946 59.3645C214.063 57.2482 217.547 55.524 217.688 
    55.5316C217.829 55.54 220.108 58.7255 222.751 62.6113C227.228 69.1928 233.434 76.4646 245.78 89.598C248.561 92.5563 252.337 96.6894 254.171 98.7825C256.006 100.875 259.82 105.016 262.649 107.985C268.097 113.703 270.064 117.467 268.82 119.794C267.887 121.536 267.027 121.822 262.119 122.019C259.332 122.131 258.103 121.887 258.103 121.223C258.103 120.48 257.899 120.471 257.185 121.186C255.877 122.494 254.432 122.321 254.143 120.819C253.914 119.629 253.798 119.633 252.564 120.868C250.891 122.541 249.707 121.864 249.628 119.189C249.592 117.968 249.387 117.592 249.123 118.263C247.864 121.473 245.1 122.302 245.1 119.471C245.1 118.443 244.759 117.392 244.343 117.135C243.91 116.867 243.782 117.173 244.042 117.855C244.338 118.625 244.065 119.042 243.269 119.042C242.593 119.042 242.04 119.587 242.04 120.254C242.04 122.282 240.16 120.98 239.644 118.597C239.379 117.369 239.12 116.795 239.07 117.321C239.021 117.847 238.271 118.277 237.405 118.277C236.539 118.277 236.028 118.598 236.27 118.99C237.112 120.353 235.254 120.59 234.251 119.247C232.97 117.528 228.554 118.514 228.271 119.736C228.271 120.748 225.858 121.992 224.703 121.576C223.743 121.23 223.306 122.298 222.451 127.079C222.15 128.763 221.765 129.729 221.596 129.226C221.128 127.837 219.092 128.957 219.092 130.605C219.092 131.402 218.748 132.054 218.327 132.054C217.906 132.054 217.562 133.26 217.562 134.733C217.562 137.205 217.741 137.411 219.882 137.411C223.034 137.411 227.609 135.421 229.186 133.364C231.46 130.398 231.992 132.558 231.14 141.296C230.57 147.137 230.59 150.227 231.208 152.394L232.065 155.398L231.768 152.123L231.47 148.847L233.841 151.166C235.144 152.441 236.435 153.484 236.709 153.484C237.76 153.484 241.276 161.087 242.053 165.036C243.877 174.324 244.29 175.261 247.255 176.849C250.778 178.737 252.802 183.586 251.502 187.029C250.786 188.928 250.947 189.486 252.824 191.587C256.905 
    196.157 256.332 201.981 251.588 204.144C248.87 205.383 249.156 209.404 252.082 211.085C254.679 212.577 259.478 219.849 260.153 223.314C260.421 224.688 261.618 226.824 262.814 228.061C265.326 230.662 265.437 231.333 263.756 233.735C262.735 235.195 262.669 236.035 263.369 238.689C263.833 240.446 264.914 245.673 265.771 250.303C266.628 254.934 267.662 259.32 268.071 260.051C268.478 260.782 268.812 262.763 268.812 264.452C268.812 266.142 269.177 267.524 269.622 267.524C270.067 267.524 270.21 267.884 269.939 268.323C269.667 268.762 269.858 269.619 270.362 270.226C270.866 270.834 271.084 271.835 270.849 272.45C270.56 273.201 270.784 273.429 271.528 273.143C272.192 272.889 272.636 273.178 272.636 273.866C272.636 275.11 274.196 275.943 276.524 275.943C277.331 275.943 277.991 276.298 277.991 276.732C277.991 278.078 275.796 278.451 272.619 277.644C269.354 276.815 268.992 277.049 270.606 278.944C271.836 280.386 275.696 290.017 275.696 291.642C275.696 292.268 275.091 292.781 274.352 292.781C273.228 292.781 273.053 293.346 273.281 296.225C273.526 299.302 273.757 299.67 275.441 299.67C276.478 299.67 277.99 300.025 278.8 300.459C280.517 301.378 283.621 307.323 282.384 307.323C278.317 307.323 290.444 309.305 305.91 311.166C309.697 311.622 313.827 312.128 315.09 312.291C316.352 312.454 319.794 312.799 322.739 313.06C325.684 313.32 329.47 313.669 331.153 313.835C339.374 314.649 345.417 315.067 363.012 316.014ZM358.831 321.674C352.514 322.26 347.79 322.335 340.332 322.137C327.774 321.802 305.7 319.732 292.162 317.618C283.385 316.248 281.738 316.471 280.262 319.231C278.798 321.968 275.292 323.655 272.136 323.143C270.728 322.914 269.577 323.042 269.577 323.426C269.577 325.166 272.818 329.184 275.087 330.256C278.554 331.893 279.716 334.54 278.585 338.223C277.716 341.058 277.747 341.13 281.028 343.959C284.183 346.676 284.33 346.985 283.921 350.034C283.607 352.378 283.759 353.245 284.484 353.245C285.653 
    353.245 286.405 354.925 286.405 357.539C286.405 358.569 287.45 361.482 288.726 364.012C290.002 366.543 290.81 368.997 290.52 369.466C290.228 369.94 290.337 370.108 290.764 369.843C291.189 369.58 291.862 370.13 292.261 371.064C292.738 372.179 292.961 374.969 292.926 377.28C318.737 363.708 341.22 344.658 358.831 321.674ZM290.697 378.434C289.755 376.773 288.503 374.387 287.204 371.745C284.744 366.743 282.142 362.425 281.423 362.149C280.4 361.756 280.234 361.941 280.658 362.995C280.956 363.736 282.007 369.165 282.994 375.058C283.376 377.338 283.784 379.611 284.166 381.616C286.366 380.594 288.543 379.533 290.697 378.434ZM216.019 399.509L215.909 395.766C215.744 390.107 215.378 385.246 215.096 384.964C214.814 384.682 213.423 385.169 212.004 386.046C209.464 387.616 209.404 387.779 208.067 396.848L207.602 400C210.423 399.894 213.229 399.731 216.019 399.509ZM244.847 182.46C243.621 183.478 242.805 182.859 242.805 180.91C242.805 179.25 243.801 179.09 245.015 180.553C245.671 181.344 245.63 181.81 244.847 182.46ZM247.713 180.935C248.351 181.968 247.709 183.139 246.932 182.361C246.678 182.107 246.615 181.464 246.792 180.932C247.051 180.154 247.231 180.154 247.713 180.935ZM247.483 194.266C246.518 195.232 246.234 195.016 245.554 192.8C245.22 191.713 245.431 191.024 246.185 190.734C247.595 190.193 248.659 193.089 247.483 194.266ZM250.006 198.339C249.082 197.984 249.056 197.643 249.856 196.362C250.387 195.511 251.083 194.814 251.403 194.814C252.346 194.814 252.065 200.031 251.089 200.635C250.497 201.002 250.351 200.779 250.658 199.978C250.927 199.276 250.653 198.587 250.006 198.339ZM147.448 221.985L149.68 218.349C152.111 214.386 152.62 214.108 154.122 215.92C154.952 216.92 154.936 217.246 154.025 217.822C153.421 218.204 152.456 218.522 151.881 218.529C151.152 218.537 150.995 219.062 151.363 220.263C151.653 221.21 152.498 224.223 153.242 226.96C154.498 231.586 154.736 
    231.924 156.646 231.788C157.775 231.707 158.498 231.966 158.254 232.362C158.009 232.758 157.35 233.082 156.788 233.082C155.525 233.082 154.638 235.164 155.638 235.782C156.04 236.031 156.369 236.626 156.369 237.105C156.369 237.584 155.164 236.807 153.692 235.378C152.219 233.95 151.01 232.075 151.004 231.211C150.999 230.345 150.197 227.916 149.222 225.811L147.448 221.985ZM274.166 303.496C273.759 302.865 273.42 302.163 273.413 301.937C273.378 300.724 276.535 301.564 278.042 303.167L279.788 305.027H278.1C277.064 305.027 276.144 304.361 275.72 303.305C275.084 301.722 275.023 301.706 274.968 303.114C274.918 304.387 274.783 304.451 274.166 303.496ZM274.693 321.372C274.479 321.158 274.876 319.978 275.574 318.75C276.272 317.522 277.102 316.515 277.417 316.512C278.272 316.505 278.11 318.639 277.214 319.194C276.786 319.459 276.612 319.96 276.827 320.307C277.261 321.01 275.306 321.986 274.693 321.372ZM271.561 321.405C270.849 321.131 270.369 320.377 270.496 319.728C270.862 317.839 272.401 317.819 272.891 319.696C273.437 321.785 273.234 322.047 271.561 321.405ZM276.912 335.445C276.304 336.054 274.931 334.832 274.931 333.683C274.931 333.076 275.239 332.581 275.614 332.581C276.39 332.581 277.446 334.912 276.912 335.445Z" />
      </g>
    </svg>
  );
};
