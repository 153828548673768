import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import { BackIcon } from "../../icons/FunctionalIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class YomiLinks extends PureComponent {
  render() {
    return (
      <div>
        <Link to='/wayfinder' onClick={topScroll}>
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative xs:pl-10'>
              <BackIcon iconClass='absolute w-7 h-auto left-4 fill-body  ' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate '>
                Back to Main
              </p>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
