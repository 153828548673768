import React, { PureComponent } from "react";
import { NewsIcon } from "../../../icons/BrandIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class CalendarCard3 extends PureComponent {
  render() {
    return (
      <div className='h-full bg-cardBackground duration-500 hover:bg-borders'>
        <div className='p-6 h-full'>
          <div className='h-full'>
            <div className='flex flex-wrap h-full justify-left'>
              <div>
                <div>
                  <NewsIcon iconClass='w-fit sm:mx-0 mx-auto h-12 fill-heading s' />
                </div>

                <div className='pt-4 h-min'>
                  <h4 className='w-full text-darkCB/90 truncate xl:text-3xl text-2xl sm:text-left text-center'>
                    Rogue News
                  </h4>

                  <p className='text-sm my-2 pb-2 text-bodySecondary sm:text-left text-center'>
                    Stay up to date with the latest crypto, blockchain,
                    metaverse and nft news. We aim to provide complicated and
                    technical information in an easy-to-understand format.
                  </p>
                </div>
              </div>
              <div className='h-min mt-auto sm:mx-0 mx-auto'>
                {/* <div className="py-2 px-4 rounded bg-[#d89393] font-bold text-xs text-background">
                  Beta
                </div> */}
                <div className='py-2 px-4  rounded bg-darkBorders font-bold text-xs text-background'>
                  Development
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
