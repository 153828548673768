import React from "react";
import { Link } from "react-router-dom";

import { TwitterIcon, DiscordIcon, InstagramIcon } from "../icons/SocialIcons";
import { LinkIcon } from "../icons/FunctionalIcons";
import { NewsletterIcon } from "../icons/BrandIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Fottom(_) {
  return (
    <div className='bg-cardBackground dark:bg-darkBackground pt-2'>
      {/* <div className="flex flex-wrap gap-4 sm:gap-0 sm:flex-nowrap pb-5 m-auto pt-5 border-t border-borders dark:border-darkBorders text-sm md:flex-row w-10/12">
        <p>© Rogue Studios 2022. All Rights Reserved.</p>
        <div className="flex-auto flex-row-reverse justify-between sm:justify-start flex my-auto">
          <a
            href="https://roguestudios.io/wayfinder"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon iconClass="w-6 md:w-6 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2" />
          </a>
          <a
            href="https://roguestudios.io/newsletter"
            target="_blank"
            rel="noreferrer"
          >
            <NewsletterIcon iconClass="w-6 md:w-6 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2" />
          </a>

          <a
            href="https://discord.gg/roguestudios"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon iconClass="w-6 md:w-6 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2" />
          </a>
          <a
            // href="https://instagram.com/roguestudios.io"
            href="https://www.instagram.com/yominft/"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon iconClass="w-6 md:w-6 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2" />
          </a>
          <a
            // href="https://twitter.com/roguestudios_io"
            href="https://twitter.com/YomiNFT"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon iconClass="w-6 md:w-6 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2" />
          </a>
        </div>
      </div> */}

      <div className='flex flex-wrap gap-4 sm:gap-0 sm:flex-nowrap pb-5 m-auto pt-5 border-t border-borders dark:border-darkBorders text-sm md:flex-row w-10/12'>
        <p>© Rogue Studios 2022. All Rights Reserved.</p>
        <div className='flex-auto flex-row-reverse justify-between sm:justify-start flex my-auto'>
          <Link onClick={topScroll} to='/wayfinder'>
            <LinkIcon iconClass='w-6 md:w-6 lg:w8 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2' />
          </Link>

          <a
            href='https://discord.gg/roguestudios'
            target='_blank'
            rel='noreferrer'
          >
            <DiscordIcon iconClass='w-6 md:w-6 lg:w8 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2' />
          </a>
          <a
            // href="https://instagram.com/roguestudios.io"
            href='https://www.instagram.com/yominft/'
            target='_blank'
            rel='noreferrer'
          >
            <InstagramIcon iconClass='w-6 md:w-6 lg:w8 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2' />
          </a>
          <a
            // href="https://twitter.com/roguestudios_io"
            href='https://twitter.com/YomiNFT'
            target='_blank'
            rel='noreferrer'
          >
            <TwitterIcon iconClass='w-6 md:w-6 lg:w8 h-auto hover:scale-125 fill-body dark:fill-darkBody hover:fill-heading dark:hover:fill-heading duration-500 mx-2' />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Fottom;
