import React, { PureComponent } from "react";

import Footer from "../../navigation/Footer";
import YomiLinks from "./YomiLinks";
import Back from "./Back";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class YomiWayfinder extends PureComponent {
  render() {
    return (
      <div>
        <div className='bg-gradient-to-t from-cardBackground via-background to-background dark:from-darkCB dark:via-darkShadows dark:to-darkCB w-full py-20 mt-10'>
          <div className='w-full h-full'>
            <div className='w-11/12 mx-auto max-w-6xl'>
              <div className='w-full flex flex-wrap justify-center'>
                <h2 className='uppercase tracking-wider font-bold lg:text-8xl md:text-7xl text-6xl font-anton sm:text-left pb-4 text-center my-auto '>
                  <a
                    href='https://yomi.roguestudios.io/'
                    className='duration-1000 hover:text-heading'
                    target='_blank'
                    rel='noreferrer'
                  >
                    yomi
                  </a>
                </h2>
                <p className='text-2xl italic text-bodySecondary dark:text-bodySecondary font-anton'>
                  by Rogue Studios
                </p>
              </div>

              <YomiLinks />

              <Back />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
