import React from "react";

function GuideWrap(props) {
  return (
    <div className='h-full bg-borders dark:bg-darkCB shadow-lg dark:shadow-darkShadows rounded-lg'>
      <div className='p-2 h-full'>
        <div className='h-full'>
          <div className='flex flex-wrap h-full'>
            <div>{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GuideWrap;
