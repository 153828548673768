import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { DiscordIcon } from "../../../icons/SocialIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class Help1 extends PureComponent {
  render() {
    return (
      <a
        href='https://discord.gg/roguestudios'
        target='_blank'
        rel='noreferrer'
      >
        <div className='h-full bg-borders hover:shadow-lg rounded-xl duration-500'>
          <div className='p-6 h-full'>
            <div className='h-full'>
              <div className='flex flex-nowrap gap-6 h-full'>
                <div>
                  <DiscordIcon iconClass='w-10 h-auto fill-darkCB/90 mt-[10px]' />
                </div>
                <div className='flex flex-wrap h-full justify-left'>
                  <div>
                    <div className='mt-3 h-min'>
                      <p className='text-darkCB/90 text-3xl font-anton font-normal'>
                        Discord Help Server
                      </p>

                      <p className='text-sm my-2 pb-2 text-left text-bodySecondary'>
                        We provide support in our Discord server for common
                        questions and requests, open a ticket and a real person
                        will assist you.
                      </p>
                    </div>
                  </div>

                  <div className='h-min mt-auto'>
                    <button className='py-2 px-6 font-bold text-xs rounded-md text-darkBody bg-darkCB border-DarkBorders border-2 hover:bg-darkBorders'>
                      Join
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
}
