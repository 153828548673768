import React, { PureComponent, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper";
import { Link } from "react-router-dom";

function BannersMarquee(element) {
  const data = [
    {
      src: "../../banners/banner1.png",
    },
    {
      src: "../../banners/banner2.png",
    },
    {
      src: "../../banners/banner3.png",
    },
    {
      src: "../../banners/banner4.png",
    },
    {
      src: "../../banners/banner5.png",
    },
    {
      src: "../../banners/banner6.png",
    },
    {
      src: "../../banners/banner7.png",
    },
    {
      src: "../../banners/banner8.png",
    },
    {
      src: "../../banners/banner9.png",
    },
    {
      src: "../../banners/banner10.png",
    },
    {
      src: "../../banners/banner11.png",
    },
    {
      src: "../../banners/banner12.png",
    },
    {
      src: "../../banners/banner13.png",
    },
    {
      src: "../../banners/banner14.png",
    },
  ];
  SwiperCore.use([Autoplay]);

  const slides = [];
  return (
    <>
      <Swiper
        spaceBetween={2}
        slidesPerView={2}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
          reverseDirection: element.dir,
        }}
        speed={2000}
        loop={true}
        freeMode={true}
        watchSlidesProgress={false}
      >
        {data.map((props, index) => {
          return (
            <SwiperSlide id={index} key={index}>
              <div className='h-auto w-auto hover:scale-105 cursor-pointer duration-500'>
                <div className='flex flex-nowrap items-center justify-around'>
                  <div className='w-full h-full my-auto items-start'>
                    <div className='w-full h-full overflow-hidden'>
                      <Link
                        to={`https://banners.roguestudios.io/builder/${props._id}`}
                      >
                        <img
                          alt=''
                          src={props.src}
                          className='w-full h-full px-2 rounded-xl object-cover'
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}{" "}
      </Swiper>
    </>
  );
}
export default BannersMarquee;
