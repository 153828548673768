import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../ThemeContext";

import Footer from "../navigation/Footer";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Error404() {
  return (
    <div>
      <div className='h-screen w-full flex relative'>
        <img
          alt=''
          src='./404.png'
          className={
            "absolute h-full w-full mx-auto object-cover brightness-75"
          }
        ></img>
        <div className='bg-gradient-to-t from-background h-1/2 translate-y-[100%] w-full'></div>
      </div>
      <div className='absolute z-20 top-0 h-full w-full flex flex-wrap items-center justify-center'>
        <div className='flex justify-center flex-wrap'>
          <h1 className='w-full font-anton font-normal uppercase tracking-wide lg:text-8xl md:text-7xl text-6xl text-body dark:text-darkBody text-center'>
            Error 404
          </h1>
          <p className='uppercase font-bold lg:text-4xl md:text-3xl text-2xl text-center w-full'>
            Page Not Found
          </p>
          <button className='text-body dark:text-darkCB text-2xl mx-auto mt-5 px-12 py-2 bg-cardBackground dark:bg-bodySecondary hover:bg-cardBackground/75 dark:hover:bg-transparent border-borders dark:border-bodySecondary hover:text-body dark:hover:text-bodySecondary'>
            <Link onClick={topScroll} to='/'>
              Return Home
            </Link>
          </button>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Error404;
