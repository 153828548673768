import { useRef } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  useGLTF,
  PresentationControls,
  Environment,
  ContactShadows,
  PerspectiveCamera,
} from "@react-three/drei";
import FloatingCase from "./FloatingCase";
import {
  Bloom,
  DepthOfField,
  EffectComposer,
  Noise,
  Outline,
  SSAO,
  Vignette,
} from "@react-three/postprocessing";
import { KernelSize } from "postprocessing";

function Camera({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/camera.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[-0.02, 0.06, 2.84]} rotation={[1.56, 0, 0.02]}>
        <PerspectiveCamera
          makeDefault={true}
          far={1000}
          near={0.1}
          fov={22.9}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/camera.glb");

export default function App() {
  return (
    <Canvas shadows dpr={[1, 2]}>
      <Camera />
      <ambientLight intensity={1} />
      <spotLight
        position={[10, 10, 10]}
        angle={0.15}
        penumbra={0.8}
        shadow-mapSize={[512, 512]}
        castShadow
      />
      <PresentationControls
        global
        config={{ mass: 2, tension: 500 }}
        snap={{ mass: 4, tension: 1500 }}
        rotation={[0, 0.3, 0]}
        polar={[-Math.PI / 3, Math.PI / 3]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]}
      >
        <FloatingCase rotation={[0, 0, 0]} position={[0, 100, 0]} scale={0.6} />
      </PresentationControls>
      <EffectComposer>
        <Bloom
          kernelSize={KernelSize.HUGE}
          luminanceThreshold={0}
          luminanceSmoothing={0}
          intensity={0.1}
        />
        <DepthOfField
          focusDistance={0}
          focalLength={0.02}
          bokehScale={2}
          height={480}
        />
        <Outline blur edgeStrength={100} />
        <Bloom luminanceThreshold={0} luminanceSmoothing={1.5} height={300} />
        <Noise opacity={0.05} />
        <Vignette eskil={false} offset={0.1} darkness={1.1} />
      </EffectComposer>
      <Environment preset='city' />
    </Canvas>
  );
}
