import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import Footer from "../../navigation/Footer";
import YomiTop from "./YomiTop";

import {
  TwitterIcon,
  DiscordIcon,
  WebsiteIcon,
  MediumIcon,
  GithubIcon,
} from "../../icons/SocialIcons";
import { LinkIcon } from "../../icons/FunctionalIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class Wayfinder extends PureComponent {
  render() {
    return (
      <div>
        <div className='bg-gradient-to-t from-cardBackground via-background to-background dark:from-darkCB dark:via-darkShadows dark:to-darkCB w-full py-20 mt-10'>
          <div className='w-full h-full'>
            <div className='w-11/12 mx-auto max-w-6xl'>
              <h2 className='uppercase tracking-wider font-bold lg:text-8xl md:text-7xl text-6xl font-anton pb-4 text-center my-auto overflow-hidden'>
                Wayfinder
              </h2>

              <YomiTop />

              <a
                href='https://www.roguestudios.io'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <WebsiteIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate '>
                      Rogue Studios Website
                    </p>
                  </div>
                </div>
              </a>

              <Link to='/tools-wayfinder' onClick={topScroll}>
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <LinkIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      Rogue Tools
                    </p>
                  </div>
                </div>
              </Link>

              <a
                href='https://twitter.com/RogueStudios_io'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <TwitterIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      Rogue Studios twitter
                    </p>
                  </div>
                </div>
              </a>

              <a
                href='https://discord.gg/roguestudios'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <DiscordIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      Rogue Studios discord
                    </p>
                  </div>
                </div>
              </a>

              <a
                href='https://medium.com/@RogueStudio'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <MediumIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      Rogue Studios Medium Page
                    </p>
                    <p className='sm:hidden sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate group-hover:text-background dark:group-hover:text-darkCB duration-1000'>
                      Medium Page
                    </p>
                  </div>
                </div>
              </a>

              <a
                href='https://github.com/roguestudiosio'
                target='_blank'
                rel='noreferrer'
              >
                <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none focus:border-bodySecondary dark:focus:border-bodySecondary hover:bg-borders bg-cardBackground dark:bg-darkCB dark:text-darkBody text-body border-2 border-borders dark:border-darkBorders'>
                  <div className='flex w-full h-full justify-center items-center relative xs:pl-12'>
                    <GithubIcon iconClass='absolute w-8 h-auto left-4 fill-body' />
                    <p className='sm:text-lg md:text-xl capitalize font-bold text-ellipsis truncate'>
                      Rogue github{" "}
                      <span className='text-sm hidden md:inline italics text-bodySecondary group-hover:duration-1000'>
                        We might make some open-source stuff in the future!
                      </span>
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
