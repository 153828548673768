import React from "react";

function VisionWrapper(props) {
  return (
    <div className="h-full hover:shadow-lg hover:shadow-darkShadows rounded-xl duration-500">
      <div className="p-4 h-full">
        <div className="h-full">
          <div className="flex flex-wrap h-full justify-center">
            <div>
              <div>{props.children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VisionWrapper;
