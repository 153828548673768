import React, { PureComponent } from "react";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class CalendarCardNoCode extends PureComponent {
  render() {
    return (
      <div className='h-full bg-cardBackground duration-500 hover:bg-borders'>
        <div className='p-6 h-full'>
          <div className='h-full'>
            <div className='flex flex-wrap h-full justify-center items-center'>
              <div className='h-min'>
                <h4 className='w-full truncate xl:text-3xl text-2xl sm:text-left text-center text-bodySecondary '>
                  Wordpress Plugin
                </h4>

                <p className='text-sm my-2 sm:text-left text-center text-heading'>
                  Coming Soon
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
