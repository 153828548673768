import React, { PureComponent } from "react";
import Help1 from "./Help1";
import Help2 from "./Help2";

export default class HelpIO extends PureComponent {
  render() {
    return (
      <div>
        <div className='w-11/12 mx-auto'>
          <h2 className='w-full flex flex-wrap uppercase flex-grow justify-center pb-10 text-5xl text-center bg-cardBackground'>
            Need Help?
          </h2>
          <div className='grid grid-rows-1 lg:grid-cols-2 gap-4 grid-cols-1 max-w-6xl mx-auto'>
            <Help1 />
            <Help2 />
          </div>
        </div>
      </div>
    );
  }
}
