import React, { PureComponent } from "react";

function Radar(props) {
  return (
    <div className='w-11/12 max-w-6xl mx-auto'>
      <div className='w-full grid grid-rows-2 grid-cols-1 md:grid-rows-1 md:grid-cols-2 gap-10'>
        <div className='rounded-2xl w-full shadow-lg shadow-darkShadows row-start-2 md:row-start-auto'>
          <img
            src='../artwork.png'
            alt=''
            className='object-cover w-full xs:h-full h-[350px] sm:h-[250px] md:h-[360px] rounded-2xl'
          />
        </div>
        <div className='bg-darkCB shadow-lg shadow-darkShadows rounded-2xl w-full h-full p-8 flex items-center flex-wrap row-start-1 md:row-start-auto'>
          <div>
            <h2 className='mb-4 text-4xl lg:text-5xl w-full text-darkBody'>
              the rogue Radar
            </h2>
            <p className='mb-6 text-xs lg:text-sm text-bodySecondary text-center sm:text-left'>
              stay up to date with the latest drops and giveaways with the rogue
              radar! Increase your odds of winning a giveaway by entering them
              all! For anything related to upcoming drops & giveaways, the rogue
              radar has you covered.
            </p>
            <div className='w-full flex flex-wrap gap-4 justify-center sm:justify-start'>
              <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md text-darkBody bg-darkCB hover:bg-darkBorders border-darkBorders '>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://radar.roguestudios.io/upcoming'
                >
                  upcoming Drops
                </a>
              </button>
              <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md text-body bg-cardBackground border-borders border-2 hover:bg-borders'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://radar.roguestudios.io/giveaways'
                >
                  live giveaways
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Radar;
