import React, { PureComponent } from "react";
import {
  ChainIcon,
  CodeIcon,
  SecureIcon,
  UpdateIcon,
} from "../../../icons/DisplayIcons";
import GuideCardsSection from "./GuideCardsSection";
import GuideWrap from "./GuideWrap";

export default class GuideCards extends PureComponent {
  render() {
    return (
      <div className="w-full bg-cardBackground py-32">
        <div className="w-11/12 mx-auto">
          <h2 className="w-full flex text-darkCB/90 flex-wrap capitalize flex-grow justify-center mb-10 text-5xl text-center">
            We've got you covered
          </h2>

          <div className="grid grid-cols-1 rounded-xl grid-rows-4 sm:grid-rows-2 sm:grid-cols-2 gap-6 max-w-6xl mx-auto">
            <GuideCardsSection />
          </div>
        </div>
      </div>
    );
  }
}
