import React from "react";
import { Link } from "react-router-dom";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function scrollControlFeatured() {
  document.getElementById("Featured").scrollIntoView({ behavior: "smooth" });
}

function scrollControlSearch() {
  document.getElementById("Search").scrollIntoView({ behavior: "smooth" });
}

function scrollControlGallery() {
  document.getElementById("Gallery").scrollIntoView({ behavior: "smooth" });
}

function Fanners(_) {
  return (
    <div>
      <div>
        <a
          href="https://banners.roguestudios.io/"
          target="_blank"
          rel="noreferrer"
        >
          <p className="uppercase font-medium hover:text-heading text-xs duration-500">
            Rogue Banners
          </p>
        </a>
      </div>
      <a
        href="https://banners.roguestudios.io/#Featured"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Featured
        </p>
      </a>
      <a
        href="https://banners.roguestudios.io/#Search"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Search
        </p>
      </a>
      <a
        href="https://banners.roguestudios.io/#Gallery"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          The Gallery
        </p>
      </a>
      <a
        href="https://banners.roguestudios.io/register"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Register
        </p>
      </a>

      {/*
        <div>
          <Link onClick={topScroll} to="/">
            <p className="uppercase font-medium hover:text-heading text-xs duration-500">
              Rogue Banners
            </p>
          </Link>
        </div>
        <Link to="/#Featured" onClick={scrollControlFeatured}>
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Featured
          </p>
        </Link>
        <Link to="/#Search" onClick={scrollControlSearch}>
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Search
          </p>
        </Link>
        <Link to="/#Gallery" onClick={scrollControlGallery}>
          <p className="my-3 block hover:text-heading text-sm duration-500">
            The Gallery
          </p>
        </Link>
        <Link onClick={topScroll} to="/register">
          <p className="my-3 block hover:text-heading text-sm duration-500">
            Register
          </p>
        </Link>
      */}
    </div>
  );
}

export default Fanners;
