import React, { PureComponent } from "react";
import { RadarIcon } from "../../../icons/BrandIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class CalendarCard1 extends PureComponent {
  render() {
    return (
      <div>
        <a
          href='https://radar.roguestudios.io/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='h-full bg-cardBackground duration-500 hover:bg-borders'>
            <div className='p-6 h-full'>
              <div className='h-full'>
                <div className='flex flex-wrap h-full justify-left'>
                  <div>
                    <div>
                      <RadarIcon iconClass='w-fit h-12 sm:mx-0 mx-auto fill-heading' />
                    </div>
                    <div className='pt-4 h-min'>
                      <h4 className='w-full text-darkCB/90 truncate xl:text-3xl text-2xl sm:text-left text-center'>
                        Rogue Radar
                      </h4>

                      <p className='text-sm my-2 pb-2 text-bodySecondary sm:text-left text-center'>
                        Discover upcoming NFT drops and giveaways. Vote on your
                        favorite drops and participate in our live leaderboard.
                        Browse a calendar of hundreds of collections and never
                        miss a release again.
                      </p>
                    </div>
                  </div>
                  <div className='h-min mt-auto sm:mx-0 mx-auto'>
                    <p className='py-2 px-4  rounded font-bold text-xs text-darkBody bg-darkCB border-DarkBorders border-2 hover:bg-darkBorders'>
                      Live
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
