import React from "react";
import BrokenLinkWrapper from "./BrokenLinkWrapper";
import Rarity from "./components/homepage/Rarity";
import Footer from "./navigation/Footer";
function RarityLanding() {
  return (
    <>
      <BrokenLinkWrapper>
        <Rarity />
      </BrokenLinkWrapper>
      <Footer />
    </>
  );
}

export default RarityLanding;
