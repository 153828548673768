import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import { YomiIcon } from "../../icons/BrandIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class YomiLinks extends PureComponent {
  render() {
    return (
      <div>
        <Link to='/yomi-wayfinder' onClick={topScroll}>
          <div className='group h-16 text-md my-5 rounded-lg font-bold w-full duration-150  appearance-none  bg-darkCB text-darkBody  border-2 border-darkBorders hover:bg-darkBorders'>
            <div className='flex w-full h-full justify-center items-center relative'>
              <YomiIcon iconClass='absolute w-9 h-auto left-4 fill-background' />
              <p className='text-lg md:text-xl capitalize font-bold text-ellipsis truncate text-background '>
                YOMI
              </p>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
