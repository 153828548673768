const axios = require("axios");

let url;
if (process.env.NODE_ENV === "production") {
  url = "https://api.roguestudios.io/newsletter";
} else {
  url = "http://localhost:5000/newsletter";
}

export const addSubscription = (email) => axios.post(url, email);
export const deleteSubscription = (id) => axios.delete(`${url}/giveaway/${id}`);
