import React, { PureComponent } from "react";
import Vision from "./Vision";

export default class OurVision extends PureComponent {
  render() {
    return (
      <>
        <div className="w-full bg-darkBorders opacity-100">
          <div className="w-full max-w-6xl mx-auto py-16">
            <div className="w-full flex flex-wrap justify-center overflow-hidden">
              <h2 className="w-full flex flex-wrap capitalize flex-grow justify-center mb-10 text-borders text-6xl text-center dark:text-darkCB">
                our vision
              </h2>
            </div>

            <div className="grid grid-cols-1 grid-rows-3 sm:grid-cols-2 sm:grid-rows-2 xl:grid-rows-1 xl:grid-cols-3 gap-6 mx-auto relative z-10">
              <Vision />
            </div>
          </div>
        </div>
      </>
    );
  }
}
