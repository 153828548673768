import React, { PureComponent } from "react";
import BannersMarquee from "./BannersMarquee";

function Banners(props) {
  return (
    <div className='w-11/12 max-w-6xl mx-auto'>
      <div className='h-[500px] rounded-2xl w-full md:grid md:grid-rows-1 md:grid-cols-2 overflow-hidden bg-darkCB shadow-lg shadow-darkShadows relative'>
        <div className='w-full h-full flex rounded-2xl flex-wrap items-center md:relative absolute z-20 md:backdrop-blur-none backdrop-blur-sm md:backdrop-brightness-100 backdrop-brightness-90'>
          <div className='w-full relative z-10 md:pl-8 '>
            <h1 className='mb-4 text-center md:text-left text-darkBody text-6xl'>
              rogue banners
            </h1>
            <p className='mb-4 max-w-xl text-center lg:text-left mx-auto text-xs lg:text-sm lg:mx-0 text-bodySecondary'>
              Build custom NFT Twitter banners from some of your favorite
              projects!
            </p>
            <div className='w-full flex lg:flex-nowrap flex-wrap   gap-y-2 '>
              <div className='h-fit w-full flex md:justify-start justify-center mx-auto'>
                <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md  text-cardBackground bg-darkCB border-darkBorders hover:bg-darkBorders'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://banners.roguestudios.io/'
                  >
                    Try the Banner Builder
                  </a>
                </button>
              </div>
              <div className='h-fit w-full flex md:justify-start justify-center mx-auto'>
                <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md text-body bg-cardBackground border-borders border-2 hover:bg-borders'>
                  <a
                    target='_blank'
                    rel='noreferrer'
                    href='https://banners.roguestudios.io/'
                  >
                    Search Projects
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className='w-full h-full relative'>
          <div className='w-full relative z-10 h-full flex flex-wrap pt-8 opacity-50 md:opacity-100 blur-sm md:blur-0'>
            <BannersMarquee
              images={[
                "../../banners/banners1.png",
                "../../banners/banners2.png",
                "../../banners/banners3.png",
                "../../banners/banners4.png",
              ]}
              dir={true}
            />
            <BannersMarquee
              images={[
                "../../banners/banners5.png",
                "../../banners/banners6.png",
                "../../banners/banners7.png",
                "../../banners/banners8.png",
              ]}
              dir={false}
            />
            <BannersMarquee
              images={[
                "../../banners/banners9.png",
                "../../banners/banners10.png",
                "../../banners/banners11.png",
                "../../banners/banners12.png",
              ]}
              dir={true}
            />
            <BannersMarquee
              images={[
                "../../banners/banners13.png",
                "../../banners/banners14.png",
                "../../banners/banners15.png",
                "../../banners/banners16.png",
              ]}
              dir={false}
            />
          </div>
          <div className='w-1/2 bg-gradient-to-r left-0 top-0 h-full from-darkCB absolute z-20 hidden md:block'></div>
        </div>
      </div>
    </div>
  );
}
export default Banners;
