import React, { useState } from "react";
import EmailSignup from "../persistent/EmailSignup";
import Fadar from "./Fadar";
import Fome from "./Fome";
import Fomi from "./Fomi";
import Fanners from "./Fanners";
import Farities from "./Farities";
import Fegal from "./Fegal";
import Fottom from "./Fottom";

const Footer = () => {
  return (
    <div>
      <div className='bg-cardBackground  dark:from-darkBackground dark:to-darkCB w-full'>
        <EmailSignup />
        <div className='w-10/12 m-auto Secondary grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 gap-2'>
          <Fome />
          <Fadar />
          <Fomi />
          <Fanners />
          <Farities />
          <Fegal />
        </div>
      </div>
      <Fottom />
    </div>
  );
};

export default Footer;
