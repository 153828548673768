import React from "react";
import BrokenLinkWrapper from "./BrokenLinkWrapper";
import Banners from "./components/homepage/Banners";
import Footer from "./navigation/Footer";

function BannersLanding() {
  return (
    <>
      <BrokenLinkWrapper>
        <Banners />
      </BrokenLinkWrapper>
      <Footer />
    </>
  );
}

export default BannersLanding;
