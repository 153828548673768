import React, { PureComponent } from "react";

import Footer from "../../navigation/Footer";

export default function Disclaimer() {
  return (
    <div className='sm:w-full w-11/12 m-auto mt-10'>
      <div className='grid grid-rows-2 grid-cols-7 h-80 md:h-96 lg:h-128 gap-1'>
        <img
          alt=' '
          className='w-full h-80 md:h-96 lg:h-128 object-cover object-bottom absolute'
          src={
            "https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/Legal.png"
          }
        ></img>

        <h1 className='row-span-1 col-span-7 self-end text-center dark:text-darkBackground md:text-left md:col-start-2 md:col-span-6 uppercase lg:text-8xl md:text-7xl text-6xl px-2 z-10'>
          <span className='bk'>Disclaimer</span>
        </h1>

        <p className='row-span-1 bk dark:text-darkBackground col-span-7 self-start text-center md:text-left md:col-start-2 md:col-span-4 uppercase font-bold px-2 z-10'>
          Last updated on 02//5//22
        </p>
      </div>
      <div className='md:grid md:grid-cols-7 mb-40'>
        <div className='md:col-span-4 md:col-start-2 md:w-full w-11/12 m-auto'>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            Interpretation and Definitions
          </h2>
          <h3 className='mb-2 capitalize'>Interpretation</h3>
          <p className='m-auto pb-5'>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h3 className='mb-2 capitalize'>Definitions</h3>
          <ul className='text-body dark:text-cardBackground font-semibold leading-6 font-source m-auto pb-5'>
            <li>
              • Company (referred to as either "the Company", "We", "Us" or
              "Our" in this Disclaimer) refers to Rogue Studios.
            </li>
            <li>• Service refers to the Website.</li>
            <li>
              • You means the individual accessing the Service, or the company,
              or other legal entity on behalf of which such individual is
              accessing or using the Service, as applicable.
            </li>
            <li>
              • Website refers to Rogue Studios, accessible from
              https://www.roguestudios.io/
            </li>
          </ul>
          <p className='m-auto pb-8'>
            The information contained on the Service is for general information
            purposes only. The Company assumes no responsibility for errors or
            omissions in the contents of the Service. In no event shall the
            Company be liable for any special, direct, indirect, consequential,
            or incidental damages or any damages whatsoever, whether in an
            action of contract, negligence or other tort, arising out of or in
            connection with the use of the Service or the contents of the
            Service. The Company reserves the right to make additions,
            deletions, or modifications to the contents on the Service at any
            time without prior notice. This Disclaimer has been created with the
            help of the Disclaimer Template. The Company does not warrant that
            the Service is free of viruses or other harmful components.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            External Links Disclaimer
          </h2>
          <p className='m-auto pb-8'>
            The Service may links to external websites that are not provided or
            maintained by or in any way affiliated with the Company. Please note
            that the Company does not guarantee contain the accuracy, relevance,
            timeliness, or completeness of any information on these external
            websites.{" "}
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            Errors and Omissions Disclaimer
          </h2>
          <p className='m-auto pb-8'>
            The information given by the Service is for general guidance on
            matters of interest only. Even if the Company takes every precaution
            to insure that the content of the Service is both current and
            accurate, errors can occur. Plus, given the changing nature of laws,
            rules and regulations, there may be delays, omissions or
            inaccuracies in the information contained on the Service. The
            Company is not responsible for any errors or omissions, or for the
            results obtained from the use of this information.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            Fair Use Disclaimer
          </h2>
          <p className='m-auto pb-8'>
            The Company may use copyrighted material which has not always been
            specifically authorized by the copyright owner. The Company is
            making such material available for criticism, comment, news
            reporting, teaching, scholarship, or research. The Company believes
            this constitutes a "fair use" of any such copyrighted material as
            provided for in section 107 of the United States Copyright law. If
            You wish to use copyrighted material from the Service for your own
            purposes that go beyond fair use, You must obtain permission from
            the copyright owner.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            Views Expressed Disclaimer
          </h2>
          <p className='m-auto pb-8'>
            The Service may contain views and opinions which are those of the
            authors and do not necessarily reflect the official policy or
            position of any other author, agency, organization, employer or
            company, including the Company. Comments published by users are
            their sole responsibility and the users will take full
            responsibility, liability and blame for any libel or litigation that
            results from something written in or as a direct result of something
            written in a comment. The Company is not liable for any comment
            published by users and reserves the right to delete any comment for
            any reason whatsoever.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            No Responsibility Disclaimer
          </h2>
          <p className='m-auto pb-8'>
            The information on the Service is provided with the understanding
            that the Company is not herein engaged in rendering legal,
            accounting, tax, or other professional advice and services. As such,
            it should not be used as a substitute for consultation with
            professional accounting, tax, legal or other competent advisers. In
            no event shall the Company or its suppliers be liable for any
            special, incidental, indirect, or consequential damages whatsoever
            arising out of or in connection with your access or use or inability
            to access or use the Service.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            "Use at Your Own Risk" Disclaimer
          </h2>{" "}
          <p className='m-auto pb-8'>
            All information in the Service is provided "as is", with no
            guarantee of completeness, accuracy, timeliness or of the results
            obtained from the use of this information, and without warranty of
            any kind, express or implied, including, but not limited to
            warranties of performance, merchantability and fitness for a
            particular purpose. The Company will not be liable to You or anyone
            else for any decision made or action taken in reliance on the
            information given by the Service or for any consequential, special
            or similar damages, even if advised of the possibility of such
            damages.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            Contact Us
          </h2>
          <p className='m-auto'>
            If you have any questions about this Disclaimer, you can contact us
            by email:{" "}
            <a
              href='mailto: help@roguestudios.io'
              className='text-heading font-bold hover:opacity-80 duration-500'
            >
              help@roguestudios.io
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
