import React from "react";

function BrokenLinkWrapper(props) {
  return (
    <div className='bg-cardBackground sm:h-screen h-[130vh] flex place-items-center pt-[150px]'>
      {props.children}
    </div>
  );
}

export default BrokenLinkWrapper;
