import React from "react";
import {
  ChainIcon,
  CodeIcon,
  SecureIcon,
  UpdateIcon,
} from "../../../icons/DisplayIcons";
import GuideWrap from "./GuideWrap";

function GuideCardsSection() {
  return (
    <>
      <GuideWrap>
        <>
          <div className='mx-2 sm:mx-4 pt-4 h-min'>
            <div className='w-min flex h-min bg-background/25 mb-2 rounded-full aspect-square'>
              <ChainIcon iconClass='w-fit h-14 w-14 lg:h-16 lg:w-16 p-3 lg:m-0 fill-darkCB/90 ' />
            </div>
            <h4 className='w-full capitalize lg:text-3xl text-2xl xl:text-4xl text-darkCB/90'>
              Cross-chain Compatibility
            </h4>

            <p
              className={
                "text-xs lg:text-sm xl:text-base my-2 pb-2 text-bodySecondary"
              }
            >
              We provide support for the Ethereum, Solana, Polygon, Avalanche,
              Near, Tezos, Binance and Cardano blockchains.
            </p>
          </div>
        </>
      </GuideWrap>
      <GuideWrap>
        <>
          <div className='mx-2 sm:mx-4 pt-4 h-min'>
            <div className='w-min flex h-min bg-background/25 mb-2 rounded-full aspect-square'>
              <UpdateIcon iconClass='w-fit h-14 w-14 lg:h-16 lg:w-16 p-3 lg:m-0 fill-darkCB/90 ' />
            </div>
            <h4 className='w-full capitalize lg:text-3xl text-2xl xl:text-4xl text-darkCB/90'>
              Continuous Improvement
            </h4>

            <p className='text-xs lg:text-sm xl:text-base my-2 pb-2 text-bodySecondary'>
              Our team works hard to ensure our platforms are always up to date,
              and we regularly add new features.
            </p>
          </div>
        </>
      </GuideWrap>
      <GuideWrap>
        <div className='mx-2 sm:mx-4 pt-4 h-min'>
          <div className='w-min flex h-min bg-background/25 mb-2 rounded-full aspect-square'>
            <SecureIcon iconClass='w-fit h-14 w-14 lg:h-16 lg:w-16 p-3 lg:m-0 fill-darkCB/90 ' />
          </div>
          <h4 className='w-full capitalize lg:text-3xl text-2xl xl:text-4xl text-darkCB/90'>
            Secure & Private
          </h4>

          <p className='text-xs lg:text-sm xl:text-base my-2 pb-2 text-bodySecondary'>
            We never save or record user information, and we constantly work to
            ensure our services are always safe and secure.
          </p>
        </div>
      </GuideWrap>
      <GuideWrap>
        <>
          <div className='mx-2 sm:mx-4 pt-4 h-min'>
            <div className='w-min flex h-min bg-background/25 mb-2 rounded-full aspect-square'>
              <CodeIcon iconClass='w-fit h-14 w-14 lg:h-16 lg:w-16 p-3 lg:m-0 fill-darkCB/90 ' />
            </div>
            <h4 className='w-full capitalize lg:text-3xl text-2xl xl:text-4xl text-darkCB/90'>
              No Coding required
            </h4>

            <p className='text-xs lg:text-sm xl:text-base my-2 pb-2 text-bodySecondary'>
              No coding experience is required to use any of our services, we
              handle everything for you.
            </p>
          </div>
        </>
      </GuideWrap>
    </>
  );
}

export default GuideCardsSection;
