import React, { useCallback, useRef, useState } from "react";
import { YomiIcon } from "../../../icons/BrandIcons";
import { useSpring, animated as a, to, config } from "react-spring";
import { useGesture } from "@use-gesture/react";
import { SOLIcon } from "../../../icons/CryptoIcons";

const calc = (x, y) => [
  -(y - window.innerHeight / 2) / 20,
  (x - window.innerWidth / 2) / 20,
  1.1,
];
const trans1 = (x, y, s) =>
  ` rotateX(${x}deg) rotateY(${y}deg) perspective(600px) scale(${s})`;

function YomiCard() {
  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: config.wobbly,
  }));

  return (
    <>
      <a.div
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
        onMouseLeave={() => set({ xy: [0, 0, 1] })}
        style={{ transform: to(props.xy, trans1) }}
        className='w-full flex flex-wrap gap-2 place-content-center'
      >
        <div className='w-[70%] flex flex-wrap group place-content-center relative mb-7'>
          <div className='bg-[#98e2fa] shadow-md shadow-shadows w-10 h-10 rounded-full absolute z-20 left-0 -translate-x-7' />
          <div className='bg-[#98e2fa] shadow-md shadow-shadows w-9 h-9 rounded-full absolute z-20 left-5 top-4' />
          <div className='bg-[#98e2fa] shadow-md shadow-shadows w-6 h-6 rounded-full absolute z-20 left-7 top-20' />
          <div className='bg-[#98e2fa] shadow-md shadow-shadows w-6 h-6 rounded-full absolute z-20 left-[72px] top-0 -translate-y-[10px]' />
          <div className='shadow-md shadow-shadows border-2 border-transparent  backdrop-blur-sm backdrop-brightness-[0.9] w-10 h-10 rounded-full absolute z-20 left-20 top-8 -translate-x-4' />
          <div className='shadow-md shadow-shadows border-2 border-transparent  backdrop-blur-sm backdrop-brightness-[0.9] w-8 h-8 rounded-full absolute z-20 left-0 top-14 -translate-x-4' />
          <div className='shadow-md shadow-shadows backdrop-blur-sm backdrop-brightness-[0.9] rounded-full w-20 h-20 absolute z-30  top-0 right-0 translate-x-[30px] -translate-y-[30px] flex justify-center items-center'>
            <YomiIcon iconClass='w-auto h-auto p-[6px] fill-[#98e2fa]' />
          </div>
          <div className='shadow-md shadow-shadows  backdrop-blur-sm backdrop-brightness-[0.9] absolute right-0 z-30 flex rounded-full bottom-[80px] translate-x-14'>
            <SOLIcon iconClass='fill-SOL m-auto w-14 h-14 p-3' />
            <p className='text-2xl text-SOL my-auto pr-2'>Solana</p>
          </div>
          <img
            className='rounded-3xl w-full relative z-0 shadow-md shadow-shadows duration-500'
            alt=''
            src='../yomi-bubbles.png'
            draggable={false}
          ></img>
          <div className='backdrop-blur-sm backdrop-brightness-[0.9] shadow-md shadow-shadows rounded-full w-auto absolute bottom-0 z-30 translate-y-7 '>
            <p className='text-[#98e2fa] font-anton text-center py-4 px-8 text-3xl'>
              YOMI #509
            </p>
          </div>
        </div>
      </a.div>{" "}
    </>
  );
}

export default YomiCard;
