import React from "react";
/*
-------------------------------------------------------------------------------------------------
    Icons use a prop with the 'iconClass' tag rather than 'classname'
-------------------------------------------------------------------------------------------------
*/

/*
-------------------------------------------------------------------------------------------------
    Template
-------------------------------------------------------------------------------------------------
Title
export const AVAXIcon = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width=""
            height=""
            viewBox=""
            preserveAspectRatio="xMidYMid meet"
            className={props.iconClass}
            >
            <g
                transform=""
                fill={props.fill}
                stroke="none"
            >
                <path d="" />
            </g>
        </svg>
    );
};
*/

/*
-------------------------------------------------------------------------------------------------
    ADA
-------------------------------------------------------------------------------------------------
*/
export const ADAIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 386.000000 386.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,386.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M1864 3692 c-55 -44 -59 -109 -10 -158 26 -26 42 -34 71 -34 55 0 105 52 105 110 0 59 -41 100 -102 100 -26 0 -52 -7 -64 -18z" />
        <path d="M945 3585 c-35 -34 -33 -78 5 -117 26 -25 36 -29 63 -24 41 8 65 32 73 73 5 27 1 37 -24 63 -39 38 -83 40 -117 5z" />
        <path d="M2790 3590 c-50 -50 -13 -140 57 -140 72 0 111 90 60 138 -30 29 -89 30 -117 2z" />
        <path d="M1193 3226 c-49 -22 -75 -71 -70 -129 14 -160 250 -152 250 9 0 94 -95 158 -180 120z" />
        <path d="M2543 3220 c-18 -10 -39 -33 -48 -49 -67 -130 101 -253 204 -150 106 106 -28 277 -156 199z" />
        <path d="M1855 3111 c-93 -57 -107 -164 -31 -239 30 -31 43 -36 93 -40 49 -3 64 0 92 20 97 67 90 213 -13 262 -45 21 -104 20 -141 -3z" />
        <path d="M3333 2865 c-33 -14 -63 -59 -63 -95 0 -58 53 -105 116 -103 108 3 134 167 32 202 -39 14 -46 13 -85 -4z" />
        <path d="M404 2836 c-47 -47 -47 -95 0 -142 69 -69 176 -26 176 71 0 97 -107 140 -176 71z" />
        <path d="M1537 2706 c-84 -30 -148 -103 -167 -191 -37 -168 105 -330 275 -312 129 13 223 109 233 239 14 186 -166 326 -341 264z" />
        <path d="M2145 2704 c-101 -36 -165 -131 -165 -243 1 -151 108 -256 260 -255 144 1 249 108 250 255 0 182 -173 304 -345 243z" />
        <path d="M2770 2593 c-51 -27 -80 -76 -80 -136 0 -88 58 -148 144 -149 82 -2 146 56 153 139 4 39 0 55 -19 82 -50 74 -130 99 -198 64z" />
        <path d="M960 2593 c-8 -3 -26 -15 -38 -27 -81 -73 -64 -204 31 -250 162 -76 294 130 163 253 -20 19 -39 25 -84 28 -31 1 -64 0 -72 -4z" />
        <path d="M1250 2181 c-78 -24 -146 -88 -175 -164 -21 -56 -17 -155 9 -205 23 -44 75 -94 122 -118 56 -27 174 -26 230 3 58 31 110 93 128 154 20 66 20 92 0 158 -37 125 -191 209 -314 172z" />
        <path d="M2470 2181 c-119 -37 -203 -162 -185 -279 24 -163 174 -263 327 -218 72 21 138 79 168 148 18 42 21 65 18 118 -6 77 -24 117 -79 170 -61 59 -168 85 -249 61z" />
        <path d="M535 2053 c-99 -25 -127 -151 -50 -222 35 -31 41 -33 93 -29 46 4 60 10 83 36 38 43 46 91 23 138 -30 59 -92 91 -149 77z" />
        <path d="M3222 2040 c-64 -40 -79 -135 -30 -188 50 -54 121 -62 179 -21 59 42 64 138 11 192 -40 40 -112 48 -160 17z" />
        <path d="M3719 1991 c-17 -18 -29 -40 -29 -56 0 -36 50 -85 87 -85 45 1 83 42 83 90 0 30 -53 80 -85 80 -16 0 -38 -12 -56 -29z" />
        <path d="M39 1997 c-21 -16 -39 -51 -39 -77 0 -30 53 -80 84 -80 34 0 75 38 82 77 12 62 -77 118 -127 80z" />
        <path d="M1554 1650 c-154 -48 -232 -226 -161 -367 43 -84 113 -132 206 -140 156 -14 281 98 281 253 0 106 -42 180 -130 228 -57 31 -143 42 -196 26z" />
        <path d="M2157 1646 c-116 -42 -184 -144 -175 -265 5 -66 27 -116 71 -161 162 -167 442 -52 441 180 -1 177 -174 304 -337 246z" />
        <path d="M977 1546 c-125 -45 -144 -189 -36 -269 38 -28 106 -32 149 -10 101 52 110 205 15 263 -44 26 -85 31 -128 16z" />
        <path d="M2772 1543 c-96 -47 -110 -175 -27 -253 23 -22 36 -25 95 -25 59 0 72 3 95 25 38 36 55 73 55 122 0 109 -119 180 -218 131z" />
        <path d="M455 1193 c-30 -7 -73 -52 -80 -83 -13 -60 44 -130 105 -130 51 0 110 57 110 105 0 65 -72 122 -135 108z" />
        <path d="M3335 1188 c-75 -41 -74 -148 2 -187 42 -21 83 -13 119 23 26 26 34 42 34 71 0 74 -91 129 -155 93z" />
        <path d="M1839 1007 c-123 -82 -67 -277 80 -277 153 0 209 200 79 280 -46 28 -115 26 -159 -3z" />
        <path d="M1191 862 c-37 -20 -71 -73 -71 -110 0 -97 105 -161 191 -117 92 48 88 191 -6 231 -46 19 -73 18 -114 -4z" />
        <path d="M2549 865 c-61 -33 -83 -128 -43 -183 51 -70 141 -77 201 -17 28 28 33 40 33 80 0 54 -21 95 -60 119 -34 20 -96 20 -131 1z" />
        <path d="M2825 410 c-29 -11 -44 -32 -51 -68 -5 -26 -1 -36 24 -62 39 -38 83 -40 117 -5 35 34 33 78 -4 116 -30 30 -50 34 -86 19z" />
        <path d="M952 387 c-50 -53 -13 -137 59 -137 47 0 79 34 79 81 0 71 -90 107 -138 56z" />
        <path d="M1875 348 c-37 -20 -55 -52 -55 -96 0 -108 144 -143 195 -47 48 88 -53 191 -140 143z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    AVAX
-------------------------------------------------------------------------------------------------
*/
export const AVAXIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 500.000000 500.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M2355 4591 c-57 -15 -95 -41 -133 -94 -38 -50 -172 -273 -299 -497 -149 -262 -348 -611 -383 -670 -21 -36 -149 -261 -285 -500 -136 -239 -274 -482 -307 -540 -33 -58 -186 -325 -340 -595 -154 -269 -297 -519 -318 -555 -114 -197 -186 -330 -208 -383 -58 -143 7 -301 143 -348 32 -11 156 -14 655 -14 695 0 670 -2 835 80 112 56 204 130 273 220 58 77 165 254 357 595 294 519 340 600 412 724 42 72 110 190 151 261 41 72 108 188 149 258 95 163 175 325 199 402 15 47 19 92 19 210 0 223 -5 234 -255 675 -352 619 -414 716 -491 755 -34 18 -132 27 -174 16z" />
        <path d="M3890 2107 c-74 -23 -145 -126 -437 -632 -188 -324 -230 -397 -285 -489 -147 -246 -172 -331 -128 -434 26 -61 92 -125 146 -143 29 -9 216 -13 715 -16 719 -5 847 0 917 36 64 32 117 114 128 195 12 84 -11 133 -262 561 -55 94 -122 208 -149 255 -148 259 -332 555 -371 599 -35 39 -58 54 -97 66 -58 17 -126 18 -177 2z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    Bitcoin Icon
-------------------------------------------------------------------------------------------------
*/
export const BTCIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100.000000pt"
      height="100.000000pt"
      viewBox="0 0 100.000000 100.000000"
      space="preserve"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path
          d="M461 943 c-5 -21 -13 -50 -17 -65 -7 -33 -6 -33 -94 -9 -38 11 -72
                17 -74 13 -2 -4 -8 -23 -14 -43 -10 -36 -9 -37 28 -48 68 -20 68 -20 15 -231
                -26 -104 -53 -194 -60 -200 -9 -8 -25 -8 -48 -2 l-35 10 -20 -48 -20 -48 76
                -17 c43 -10 80 -19 83 -21 4 -2 -1 -33 -9 -69 -9 -35 -14 -67 -11 -69 2 -2 20
                -7 40 -11 l35 -7 15 64 c14 61 16 63 44 60 17 -2 32 -5 34 -7 3 -4 -11 -69
                -25 -117 -4 -13 4 -19 33 -27 44 -12 45 -11 63 66 l12 55 56 -5 c114 -12 180
                16 220 94 19 35 23 56 19 103 -3 54 -7 63 -39 90 l-35 31 31 15 c61 29 85 133
                46 197 -16 26 -94 82 -134 96 -5 2 -2 31 7 67 9 36 15 66 13 67 -2 2 -20 4
                -41 6 l-39 2 -14 -58 c-14 -58 -15 -59 -45 -53 -17 3 -32 7 -33 9 -2 2 4 29
                12 61 8 32 12 62 7 66 -4 4 -22 11 -40 15 -30 6 -32 5 -42 -32z m124 -234 c60
                -22 85 -49 85 -91 0 -41 -35 -68 -89 -68 -47 0 -121 16 -121 27 0 6 29 123 35
                141 6 17 26 15 90 -9z m-20 -258 c52 -23 78 -54 78 -91 0 -39 -27 -68 -71 -76
                -49 -9 -172 14 -172 32 0 13 25 125 35 161 4 14 70 1 130 -26z"
        />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    BSC
-------------------------------------------------------------------------------------------------
*/
export const BSCIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 277.000000 277.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,277.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M960 2345 l-425 -425 158 -157 157 -158 267 268 268 267 267 -267 268 -268 157 158 158 157 -425 425 -425 425 -425 -425z" />
        <path d="M1227 1542 l-157 -157 158 -158 157 -157 158 158 157 157 -158 158 -157 157 -158 -158z" />
        <path d="M152 1537 l-152 -152 153 -153 c84 -83 157 -152 162 -152 13 0 305 292 305 305 0 13 -292 305 -305 305 -6 0 -79 -69 -163 -153z" />
        <path d="M2297 1542 c-81 -81 -147 -152 -147 -157 0 -13 292 -305 305 -305 6 0 79 69 163 153 l152 152 -153 153 c-84 83 -157 152 -162 152 -6 0 -77 -66 -158 -148z" />
        <path d="M690 1005 l-155 -155 425 -425 425 -425 425 425 425 425 -158 157 -157 158 -268 -268 -267 -267 -265 265 c-146 146 -267 265 -270 265 -3 0 -75 -70 -160 -155z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    ETH
-------------------------------------------------------------------------------------------------
*/
export const ETHIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 848.000000 848.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,848.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M4039 8138 c-888 -1508 -2339 -3978 -2339 -3982 0 -2 82 -56 182 -118 1751 -1094 2346 -1462 2358 -1462 8 0 256 150 550 334 294 183 859 536 1255 783 396 247 724 452 728 456 4 5 -449 786 -1009 1737 -559 951 -1129 1921 -1267 2157 -138 235 -254 427 -257 427 -3 0 -93 -150 -201 -332z" />
        <path d="M1710 3627 c0 -3 303 -439 673 -969 370 -530 938 -1342 1262 -1806 324 -463 592 -841 597 -840 8 3 2528 3607 2528 3615 0 7 41 32 -1293 -800 -675 -422 -1232 -767 -1237 -767 -5 0 -561 345 -1237 767 -1354 845 -1293 807 -1293 800z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    MATIC
-------------------------------------------------------------------------------------------------
*/
export const MATICIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 170.000000 170.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,170.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M225 1472 c-131 -77 -204 -126 -212 -142 -9 -18 -13 -92 -13 -262 l0 -238 28 -29 c15 -17 110 -79 212 -138 151 -88 192 -107 225 -107 34 0 92 30 380 199 286 167 346 199 380 200 31 0 63 -13 140 -58 55 -32 112 -71 128 -88 27 -29 27 -30 27 -178 0 -180 4 -173 -147 -262 -138 -81 -147 -81 -285 -1 -145 85 -150 92 -157 201 l-6 89 -77 -46 -78 -45 0 -93 c0 -88 2 -95 28 -123 15 -17 109 -78 210 -136 149 -85 191 -105 223 -105 33 0 76 21 238 115 134 78 202 124 212 142 11 20 14 79 14 269 l0 244 -30 29 c-16 16 -113 77 -215 135 -139 80 -193 106 -220 106 -36 0 -152 -63 -560 -306 -209 -123 -203 -123 -358 -29 -73 44 -118 78 -128 96 -19 38 -19 293 0 329 19 34 240 160 281 160 37 0 226 -107 268 -151 25 -27 27 -35 27 -119 0 -50 2 -90 5 -90 3 0 42 21 86 46 l81 47 -4 97 c-2 78 -7 102 -21 118 -11 11 -104 70 -208 131 -162 96 -194 111 -231 111 -36 -1 -74 -19 -243 -118z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    NEAR
-------------------------------------------------------------------------------------------------
*/
export const NEARIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 164.000000 164.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,164.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M121 1633 c-13 -3 -43 -24 -66 -48 -33 -33 -45 -53 -50 -86 -3 -24 -5 -346 -3 -715 l3 -671 29 -37 c58 -77 169 -96 241 -42 17 13 105 133 195 267 90 134 172 255 182 268 24 32 23 68 -2 76 -23 7 -50 -13 -240 -180 -125 -110 -136 -118 -150 -110 -12 7 -14 903 -2 923 11 16 4 23 364 -408 167 -201 362 -434 433 -519 72 -84 143 -170 158 -190 63 -81 127 -140 164 -150 92 -26 179 -1 229 65 l29 37 0 708 0 707 -31 39 c-68 83 -177 94 -253 25 -53 -50 -392 -557 -385 -576 3 -9 14 -18 25 -21 19 -6 41 10 260 200 57 50 110 94 117 99 9 6 12 -91 12 -470 0 -443 -1 -475 -17 -463 -9 8 -138 160 -287 339 -149 178 -320 383 -381 455 -60 72 -165 196 -231 276 -67 79 -133 154 -146 167 -40 37 -116 50 -197 35z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    SOL
-------------------------------------------------------------------------------------------------
*/
export const SOLIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 194.000000 194.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,194.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M166 1560 c-147 -146 -169 -172 -160 -187 11 -17 58 -18 804 -21 l794 -2 168 167 c122 121 168 174 166 188 -3 20 -11 20 -802 23 l-800 2 -170 -170z" />
        <path d="M7 1153 c-4 -3 -7 -14 -7 -23 0 -22 319 -337 350 -344 14 -4 373 -5 799 -4 724 3 775 4 785 20 9 15 -14 42 -159 188 l-170 170 -796 0 c-437 0 -799 -3 -802 -7z" />
        <path d="M168 423 c-122 -121 -168 -174 -166 -188 3 -20 11 -20 802 -23 l800 -2 170 170 c147 146 169 172 160 187 -11 17 -58 18 -804 21 l-794 2 -168 -167z" />
      </g>
    </svg>
  );
};

/*
-------------------------------------------------------------------------------------------------
    TEZOS
-------------------------------------------------------------------------------------------------
*/
export const TEZOSIcon = (props) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="200.000000pt"
      height="200.000000pt"
      viewBox="0 0 253.000000 253.000000"
      preserveAspectRatio="xMidYMid meet"
      className={props.iconClass}
    >
      <g
        transform="translate(0.000000,253.000000) scale(0.100000,-0.100000)"
        fill={props.fill}
        stroke="none"
      >
        <path d="M650 2492 c0 -32 7 -45 45 -82 l45 -44 0 -168 0 -168 -198 0 c-109 0 -201 -2 -203 -4 -2 -2 -3 -36 -1 -75 l4 -71 198 0 199 0 3 -458 3 -457 33 -67 c66 -134 168 -183 382 -183 140 0 178 12 259 82 82 70 126 187 85 227 -26 27 -44 18 -105 -48 -134 -146 -284 -157 -351 -26 l-28 54 0 438 0 438 390 0 c215 0 390 -3 390 -6 0 -3 -126 -175 -280 -381 -238 -319 -280 -381 -280 -409 l0 -34 110 56 c130 66 191 77 285 50 187 -55 279 -218 279 -496 -1 -248 -76 -407 -227 -476 -54 -25 -73 -29 -157 -28 -112 0 -196 22 -242 63 l-32 28 32 17 c76 39 97 183 39 259 -50 66 -167 82 -240 33 -52 -35 -69 -79 -64 -166 4 -96 37 -171 109 -246 97 -103 200 -139 398 -138 209 0 351 50 475 166 115 107 185 288 185 479 0 286 -114 497 -324 599 -115 56 -208 69 -306 44 -16 -4 84 104 290 310 l315 316 -55 55 -54 55 -518 0 -518 0 0 223 0 223 -55 27 c-52 25 -64 27 -185 27 l-130 0 0 -38z" />
      </g>
    </svg>
  );
};
