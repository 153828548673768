import React, { PureComponent } from "react";
import { TwitterIcon } from "../../../icons/SocialIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class Help2 extends PureComponent {
  render() {
    return (
      <a
        href='https://twitter.com/RogueStudios_io'
        target='_blank'
        rel='noreferrer'
      >
        <div className='h-full bg-borders rounded-xl duration-500'>
          <div className='p-6 h-full'>
            <div className='h-full'>
              <div className='flex flex-nowrap gap-6 h-full'>
                <div>
                  <TwitterIcon iconClass='w-10 h-auto fill-darkCB/90 mt-[10px]' />
                </div>
                <div className='flex flex-wrap h-full justify-left'>
                  <div>
                    <div className='mt-3 h-min'>
                      <p className='text-darkCB/90 text-3xl font-anton font-normal'>
                        Message us on Twitter
                      </p>

                      <p className='text-sm my-2 pb-2 text-left text-bodySecondary'>
                        Send us an direct message on twitter and recieve a
                        response from our support team within 24 hours, or
                        browse our tweets to see if your question has already
                        been answered.
                      </p>
                    </div>
                  </div>
                  <div className='h-min mt-auto'>
                    <button className='py-2 px-6 font-bold text-xs rounded-md text-darkBody bg-darkCB border-DarkBorders border-2 hover:bg-darkBorders'>
                      View Profile
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    );
  }
}
