import React, { PureComponent } from "react";

function Rarity(props) {
  return (
    <div className='w-11/12 max-w-6xl mx-auto'>
      <div className='bg-darkCB shadow-lg shadow-darkShadows h-[500px] rounded-2xl w-full relative overflow-hidden'>
        <div className='w-full h-full px-8 absolute flex flex-wrap items-center'>
          <div className='w-full relative z-10'>
            <h2 className='mb-4 text-center lg:text-left text-black text-6xl'>
              rogue rarities
            </h2>
            <p className='mb-4 max-w-xl text-center lg:text-left mx-auto text-xs lg:text-sm lg:mx-0 text-bodySecondary'>
              The first 100% self-serve Solana based rarity tool, and it's 100%
              free! We have by far the smoothest onboarding process in the
              entire Solana ecosystem. No DMs, no payments, no hassle.
            </p>
            <div className='w-full flex flex-wrap gap-4 justify-center lg:justify-start'>
              <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md text-darkBody bg-darkCB hover:bg-darkBorders border-darkBorders '>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://rarity.roguestudios.io'
                >
                  Rogue Rarities
                </a>
              </button>
              <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md text-body bg-cardBackground border-borders border-2 hover:bg-borders'>
                <a
                  target='_blank'
                  rel='noreferrer'
                  href='https://rarity.roguestudios.io/rarity-form'
                >
                  List your Project
                </a>
              </button>
            </div>
          </div>
        </div>
        <div className='flex w-full h-full justify-end blur-sm lg:blur-none opacity-50 lg:opacity-100'>
          <div className='w-fit h-fit sm:h-full grid grid-cols-4 grid-rows-4 xs:gap-2 gap-4 scale-[1.5] rotate-[-20deg] translate-y-10 sm:-translate-y-12 sm:translate-x-20 xs:scale-[2] xs:translate-y-32'>
            <div className='w-full h-full'>
              <img
                src='../rarity1.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity2.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity3.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity4.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity5.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity6.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity7.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity8.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity9.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity10.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity11.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity12.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity13.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity14.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity15.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
            <div className='w-full h-full'>
              <img
                src='../rarity16.png'
                alt=''
                className='object-cover h-full aspect-square rounded-xl'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Rarity;
