import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import Footer from "../../navigation/Footer";
import { DownloadIcon } from "../../icons/FunctionalIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function scrollControlBrand() {
  document
    .getElementById("BrandGuidelines")
    .scrollIntoView({ behavior: "smooth" });
}

function scrollControlAssets() {
  document
    .getElementById("DownloadAssets")
    .scrollIntoView({ behavior: "smooth" });
}

function scrollControlPress() {
  document
    .getElementById("PressInquiries")
    .scrollIntoView({ behavior: "smooth" });
}

export default function Brandkit() {
  return (
    <div className='sm:w-full w-11/12 m-auto mt-10'>
      <div className='grid grid-rows-2 grid-cols-7 h-80 md:h-96 lg:h-128 gap-1'>
        <img
          alt=' '
          className='w-full h-80 md:h-96 lg:h-128 object-cover object-bottom absolute'
          src={
            "https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/Legal.png"
          }
        ></img>

        <h1 className='row-span-1 col-span-7 self-end text-center dark:text-darkBackground md:text-left md:col-start-2 md:col-span-6 uppercase lg:text-8xl md:text-7xl text-6xl px-2 z-10'>
          <span className='bk'>Brand Kit</span>
        </h1>

        <p className='row-span-1 bk dark:text-darkBackground col-span-7 self-start text-center md:text-left md:col-start-2 md:col-span-4 uppercase font-bold px-2 z-10'>
          Last updated on 27//5//22
        </p>
      </div>

      <div className='lg:grid lg:grid-cols-7'>
        <div className='hidden lg:block sticky top-32 h-min col-span-2 col-start-6'>
          <div className='w-2/3 2xl:w-1/2 mx-auto'>
            <p className='text-md font-bold pb-2 whitespace-nowrap'>Contents</p>
            <Link to='/brand-kit#BrandGuidelines' onClick={scrollControlBrand}>
              <p className='text-lg font-semibold py-3 border-b-2 border-borders dark:border-darkBorders whitespace-nowrap'>
                <span className='font-bold'>1</span> Brand Guidelines
              </p>
            </Link>
            <Link to='/brand-kit#DownloadAssets' onClick={scrollControlAssets}>
              <p className='text-lg font-semibold py-3 border-b-2 border-borders dark:border-darkBorders whitespace-nowrap'>
                <span className='font-bold'>2</span> Download Assets
              </p>
            </Link>
            <Link to='/brand-kit#PressInquiries' onClick={scrollControlPress}>
              <p className='text-lg font-semibold py-3 border-b-2 border-borders dark:border-darkBorders whitespace-nowrap'>
                <span className='font-bold'>3</span> Press Inquiries
              </p>
            </Link>
          </div>
        </div>
        <div className='lg:col-span-4 lg:col-start-2 lg:w-full w-11/12 m-auto row-start-1'>
          <p className='m-auto mb-10'>
            Welcome to Rogue Studios Brand and Marketing page
            <br></br>
            Use these guidelines when publishing or promoting Rogue Studios in
            marketing communications, such as websites, advertisements,
            articles, or any printed promotions.
          </p>

          <h2
            className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'
            id='BrandGuidelines'
          >
            Rogue Studios Brand
          </h2>

          <h3 className='text-heading dark:text-heading'>Logotype</h3>

          <img
            alt=' '
            className='py-5 w-11/12 xl:w-2/3'
            src=' ..\redLogo.png'
          ></img>

          <p className='m-auto mt-3 mb-8'>
            Rogue Studies logo symbolizes the vision of the company which is to
            bring the power back to the people, going “rogue” from other
            centralized avenues. It resembles a samurai expressing the NFTs
            behind the project, while embodying the sense of independency the
            brand strives to create.
          </p>

          <h3 className='text-heading dark:text-heading'>Clearspace</h3>

          <p className='m-auto mt-2'>
            To prevent the logomark from becoming cluttered by surrounded text,
            images, page edges, and other assets, adequate space surrounding it
            is needed. The diagram below includes the minimum spacing needed
            around the logomark and wordmark.
          </p>
          <div className='flex flex-wrap justify-between gap-8 py-5'>
            <img
              alt=' '
              className='w-full lg:w-5/12 h-full lg:justify-self-start'
              src=' ..\wordmark.png'
            ></img>

            <img
              alt=' '
              className='inline-block w-full lg:w-5/12 h-full lg:justify-self-end'
              src=' ..\logomark.png'
            ></img>
          </div>

          <h3 className='text-heading dark:text-heading mt-3'>Logo Dont's</h3>

          <p className='m-auto mt-2'>
            Here are some things you should never do with the Rogue Studios
            logomark.
          </p>

          <div className='grid grid-cols-1 grid-rows-3 w-full mt-3'>
            <div className='flex flex-wrap justify-between gap-8 py-5 border-b-2 border-borders dark:border-darkBorders'>
              <div className='w-full lg:w-5/12 h-fit lg:justify-self-start'>
                <img
                  alt=' '
                  className='rounded-xl'
                  src=' ..\blurredLogo.png'
                ></img>
                <p className='text-center mt-3 text-xl uppercase font-bold pb-5 lg:pb-0 border-b-2 lg:border-0 border-borders dark:border-darkBorders'>
                  Don't blur the logo
                </p>
              </div>
              <div className='inline-block w-full lg:w-5/12 h-fit lg:justify-self-end'>
                <img
                  alt=' '
                  className='rounded-xl'
                  src=' ..\lowContrast.png'
                ></img>
                <p className='text-center mt-3 text-xl uppercase font-bold'>
                  Don't use the logo on low contrast backgrounds
                </p>
              </div>
            </div>

            <div className='flex flex-wrap justify-between gap-8 py-5 border-b-2 border-borders dark:border-darkBorders'>
              <div className='w-full lg:w-5/12 h-fit lg:justify-self-start'>
                <img alt=' ' className='rounded-xl' src=' ..\outline.png'></img>
                <p className='text-center mt-3 text-xl uppercase font-bold pb-5 lg:pb-0 border-b-2 lg:border-0 border-borders dark:border-darkBorders'>
                  Don't add an outline to the logo
                </p>
              </div>
              <div className='inline-block w-full lg:w-5/12 h-fit lg:justify-self-end'>
                <img
                  alt=' '
                  className='rounded-xl'
                  src=' ..\shadowLogo.png'
                ></img>
                <p className='text-center mt-3 text-xl uppercase font-bold'>
                  Don't add shadows behind the logo
                </p>
              </div>
            </div>

            <div className='flex flex-wrap justify-between gap-8 py-5 '>
              <div className='w-full lg:w-5/12 h-fit lg:justify-self-start'>
                <img
                  alt=' '
                  className='rounded-xl'
                  src=' ..\stretchedLogo.png'
                ></img>
                <p className='text-center mt-3 text-xl uppercase font-bold pb-5 lg:pb-0 border-b-2 lg:border-0 border-borders dark:border-darkBorders'>
                  Don't stretch the logo
                </p>
              </div>
              <div className='inline-block w-full lg:w-5/12 h-fit lg:justify-self-end'>
                <img
                  alt=' '
                  className='rounded-xl'
                  src=' ..\frameLogo.png'
                ></img>
                <p className='text-center mt-3 text-xl uppercase font-bold'>
                  Don't use the logo as an image frame
                </p>
              </div>
            </div>
          </div>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            Colors
          </h2>
          <img
            alt=' '
            className='rounded-lg mt-6 mb-14'
            src=' ../palette.png'
          ></img>

          <h2
            className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'
            id='DownloadAssets'
          >
            Download Assets
          </h2>

          <a href='https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/assets.zip'>
            <div className='w-full py-3 flex flex-nowrap gap-4'>
              <DownloadIcon iconClass='w-12 h-12 col-span-1 p-2 rounded-lg duration-500 cursor-pointer bg-body dark:bg-bodySecondary border-2 border-body dark:border-bodySecondary hover:bg-transparent dark:hover:bg-transparent fill-background dark:fill-darkCB hover:fill-body dark:hover:fill-bodySecondary' />

              <p className='text-xl font-bold my-auto'>
                Download Assets (.zip):
              </p>
            </div>
          </a>

          <p className='m-auto mt-3 mb-8'>
            This .zip includes 7 files. Three colors of the logomark, three
            colors of the wordmark, and one image of the color palette.
          </p>

          <h2
            className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'
            id='PressInquiries'
          >
            Press Inquiries
          </h2>

          <p className='m-auto'>
            Please direct inquiries to{" "}
            <a
              href='mailto: help@roguestudios.io'
              className='text-heading font-bold hover:opacity-80 duration-500'
            >
              help@roguestudios.io
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}
