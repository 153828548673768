import React, { PureComponent } from "react";
import { RarityIcon } from "../../../icons/BrandIcons";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export default class CalendarCard2 extends PureComponent {
  render() {
    return (
      <div>
        <a
          href='https://rarity.roguestudios.io/'
          target='_blank'
          rel='noreferrer'
        >
          <div className='h-full bg-cardBackground duration-500 hover:bg-borders'>
            <div className='p-6 h-full'>
              <div className='h-full'>
                <div className='flex flex-wrap h-full justify-left'>
                  <div>
                    <div>
                      <RarityIcon iconClass='w-fit sm:mx-0 mx-auto h-12 fill-heading' />
                    </div>
                    <div className='pt-4 h-min'>
                      <h4 className='w-full text-darkCB/90  truncate xl:text-3xl text-2xl sm:text-left text-center'>
                        Rogue rarities
                      </h4>

                      <p className='text-sm my-2 pb-2 text-bodySecondary sm:text-left text-center'>
                        The best way to check the rarity of NFT collections
                        built on Solana is Rogue Rarities. Project founders can
                        enter their creator wallet address and we take care of
                        the rest. The best part, it's 100% free!
                      </p>
                    </div>
                  </div>
                  <div className='h-min mt-auto sm:mx-0 mx-auto'>
                    <p className='py-2 px-4  rounded font-bold text-xs text-darkBody bg-darkCB border-DarkBorders border-2 hover:bg-darkBorders'>
                      Live
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  }
}
