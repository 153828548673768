import React from "react";
import { Link } from "react-router-dom";
import { DiscordIcon } from "../../icons/SocialIcons";

function LinkTree() {
  return (
    <div className='w-11/12 mx-auto'>
      <div className='w-full max-w-6xl mx-auto'>
        <div className=' rounded-2xl w-full md:grid md:grid-rows-1 md:grid-cols-2 overflow-hidden bg-darkCB shadow-lg shadow-darkShadows relative bg-gradient-to-r'>
          <div className='w-full'>
            <h2 className='mb-4 text-4xl px-8 pt-8 lg:text-5xl w-full text-darkBody text-center sm:text-left'>
              Navigate it all.
            </h2>
            <p className='mb-6 px-8 text-xs w-full lg:text-sm text-bodySecondary text-center sm:text-left'>
              We do a lot of things, but we make it pretty easy to navigate. Our
              Wayfinders' are one of a kind with nested links to everything we
              do.
            </p>
            <div className='w-full px-8 flex flex-wrap gap-4 justify-center sm:justify-start'>
              <Link to={`/wayfinder`}>
                <button className='py-2 px-6 font-bold text-xs lg:text-sm capitalize rounded-md text-darkBody bg-darkCB hover:bg-darkBorders border-darkBorders'>
                  Explore the Wayfinder
                </button>
              </Link>
            </div>
          </div>
          <div className='w-full overflow-hidden relative'>
            <img
              className='w-full origin-bottom-right'
              alt=''
              src='../icons.png'
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkTree;
