import React from "react";
import BrokenLinkWrapper from "./BrokenLinkWrapper";
import Footer from "./navigation/Footer";
import EmailSignup from "./persistent/EmailSignup";

function NewsLanding() {
  return (
    <>
      <BrokenLinkWrapper>
        <EmailSignup />
      </BrokenLinkWrapper>
      <Footer />
    </>
  );
}

export default NewsLanding;
