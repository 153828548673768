import React from "react";

const Background = ({ children }) => {
  return (
    <body className='bg-background dark:bg-darkCB transition-all'>
      {children}
    </body>
  );
};

export default Background;
