import React, { useState } from "react";
import VisionWrapper from "./VisionWrapper";
import {
  CubeIcon,
  TerminalIcon,
  UtilityIcon,
} from "../../../icons/DisplayIcons";
function Vision() {
  return (
    <>
      <VisionWrapper>
        <UtilityIcon iconClass='w-fit h-12 my-4 mx-auto fill-borders' />
        <h4 className='w-full truncate xl:text-3xl text-3xl text-center capitalize text-borders '>
          Utility
        </h4>

        <p className='text-xs lg:text-sm xl:text-base my-2 pb-2 text-center text-bodySecondary max-w-sm'>
          Our mission is to equip both individuals and businesses the tools they
          need to allow for active participation in the web3 ecosystem.
        </p>
      </VisionWrapper>
      <VisionWrapper>
        <CubeIcon iconClass='w-fit h-12 my-4 mx-auto fill-borders' />
        <h4 className='w-full truncate xl:text-3xl text-3xl text-center capitalize text-borders '>
          Innovation
        </h4>

        <p className='text-xs lg:text-sm xl:text-base my-2 pb-2 text-center text-bodySecondary max-w-sm'>
          We aim to make buying and selling NFTs safer, faster, and better for
          all users.
        </p>
      </VisionWrapper>

      <div className='h-full hover:shadow-lg hover:shadow-darkShadows rounded-xl duration-500 sm:col-span-2 xl:col-span-1'>
        <div className='p-4 h-full'>
          <div className='h-full'>
            <div className='flex flex-wrap h-full justify-center'>
              <div>
                <TerminalIcon iconClass='w-fit h-12 my-4 mx-auto fill-borders' />
                <h4 className='w-full truncate xl:text-3xl text-3xl text-center capitalize text-borders '>
                  Build First
                </h4>
                <p className='text-xs lg:text-sm xl:text-base my-2 pb-2 text-center text-bodySecondary max-w-sm'>
                  We don't make empty promises. We build first.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Vision;
