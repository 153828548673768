import React, { PureComponent } from "react";

import Footer from "../../navigation/Footer";

export default function PrivacyPolicy() {
  return (
    <div className='sm:w-full w-11/12 m-auto mt-10'>
      <div className='grid grid-rows-2 grid-cols-7 h-80 md:h-96 lg:h-128 gap-1'>
        <img
          alt=' '
          className='w-full h-80 md:h-96 lg:h-128 object-cover object-bottom absolute'
          src={
            "https://elasticbeanstalk-us-east-1-807449453691.s3.amazonaws.com/Legal.png"
          }
        ></img>

        <h1 className='row-span-1 col-span-7 self-end text-center dark:text-darkBackground md:text-left md:col-start-2 md:col-span-6 uppercase lg:text-8xl md:text-7xl text-6xl px-2 z-10'>
          <span className='bk'>Privacy Policy</span>
        </h1>

        <p className='row-span-1 bk dark:text-darkBackground col-span-7 self-start text-center md:text-left md:col-start-2 md:col-span-4 uppercase font-bold px-2 z-10'>
          Last updated on 12//7//22
        </p>
      </div>
      <div className='md:grid md:grid-cols-7 mb-40'>
        <div className='md:col-span-4 md:col-start-2 md:w-full w-11/12 m-auto'>
          <p className='m-auto mb-10'>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You. We use Your Personal data to provide and improve the
            Service. By using the Service, You agree to the collection and use
            of information in accordance with this Privacy Policy.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            Interpretation and Definitions
          </h2>
          <h3 className='mb-2 capitalize'>Interpretation</h3>
          <p className='m-auto pb-5'>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h3 className='mb-2 capitalize'>Definitions</h3>
          <p className='m-auto pb-8'>
            <span className='font-bold text-lg'>
              For the purposes of this Privacy Policy:
            </span>
            <br></br>
            <br></br>
            <span className='underline'>Account</span> <span> </span>
            means a unique account created for You to access our Service or
            parts of our Service.
            <br></br>
            <br></br>
            <span className='underline'>Company</span> <span> </span>
            (referred to as either "the Company", "We", "Us" or "Our" in this
            Agreement) refers to RogueStudios.
            <br></br>
            <br></br>
            <span className='underline'>Cookies</span> <span> </span>
            are small files that are placed on Your computer, mobile device or
            any other device by a website, containing the details of Your
            browsing history on that website among its many uses.
            <br></br>
            <br></br>
            <span className='underline'>Country</span> <span> </span>
            refers to: Ontario, Canada Device means any device that can access
            the Service such as a computer, a cellphone or a digital tablet.
            <br></br>
            <br></br>
            <span className='underline'>Personal Data</span> <span> </span>
            is any information that relates to an identified or identifiable
            individual.
            <br></br>
            <br></br>
            <span className='underline'>Service</span> <span> </span>
            refers to the Website.
            <br></br>
            <br></br>
            <span className='underline'>Service Provider</span> <span> </span>
            means any natural or legal person who processes the data on behalf
            of the Company. It refers to third-party companies or individuals
            employed by the Company to facilitate the Service, to provide the
            Service on behalf of the Company, to perform services related to the
            Service or to assist the Company in analyzing how the Service is
            used.
            <br></br>
            <br></br>
            <span className='underline'>Usage Data</span> <span> </span>
            refers to data collected automatically, either generated by the use
            of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit).Website refers to Rogue
            Studios, accessible from https://www.roguestudios.io/ You means the
            individual accessing or using the Service, or the company, or other
            legal entity on behalf of which such individual is accessing or
            using the Service, as applicable.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            COLLECTING AND USING YOUR PERSONAL DATA
          </h2>
          <h3 className='mb-5 capitalize'>Types of data collected</h3>
          <h4 className='mb-2 capitalize'>personal data</h4>
          <p className='m-auto pb-5'>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <h4 className='mb-2 capitalize'>usage data</h4>
          <p className='m-auto pb-5'>
            Usage Data is collected automatically when using the Service. Usage
            Data may include information such as Your Device's Internet Protocol
            address (e.g. IP address), browser type, browser version, the pages
            of our Service that You visit, the time and date of Your visit, the
            time spent on those pages, unique device identifiers and other
            diagnostic data. When You access the Service by or through a mobile
            device, We may collect certain information automatically, including,
            but not limited to, the type of mobile device You use, Your mobile
            device unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data. We may also
            collect information that Your browser sends whenever You visit our
            Service or when You access the Service by or through a mobile
            device.
          </p>
          <h4 className='mb-2 capitalize'>tracking technologies and cookies</h4>
          <p className='m-auto pb-8'>
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
            <br></br>
            <br></br>
            <span className='underline'>Cookies or Browser Cookies:</span>{" "}
            <span> </span>A cookie is a small file placed on Your Device. You
            can instruct Your browser to refuse all Cookies or to indicate when
            a Cookie is being sent. However, if You do not accept Cookies, You
            may not be able to use some parts of our Service. Unless you have
            adjusted Your browser setting so that it will refuse Cookies, our
            Service may use Cookies.
            <br></br>
            <br></br>
            <span className='underline'>Flash Cookies:</span> <span> </span>
            Certain features of our Service may use local stored objects (or
            Flash Cookies) to collect and store information about Your
            preferences or Your activity on our Service. Flash Cookies are not
            managed by the same browser settings as those used for Browser
            Cookies. For more information on how You can delete Flash Cookies,
            please read "Where can I change the settings for disabling, or
            deleting local shared objects?" available here.
            <br></br>
            <br></br>
            <span className='underline'>Web Beacons:</span> <span> </span>
            Certain sections of our Service and our emails may contain small
            electronic files known as web beacons (also referred to as clear
            gifs, pixel tags, and single-pixel gifs) that permit the Company,
            for example, to count users who have visited those pages or opened
            an email and for other related website statistics (for example,
            recording the popularity of a certain section and verifying system
            and server integrity). Cookies can be "Persistent" or "Session"
            Cookies.Persistent Cookies remain on Your personal computer or
            mobile device when You go offline, while Session Cookies are deleted
            as soon as You close Your web browser. We use bothSession and
            Persistent Cookies for the purposes set out below:
            <br></br>
            <br></br>
            <span className='underline'>
              Necessary / Essential Cookies:
            </span>{" "}
            <span> </span>
            Session Cookies Administered by: Us Purpose: These Cookies are
            essential to provide You with services available through the Website
            and to enable You to use some of its features. They help to
            authenticate users and prevent fraudulent use of user accounts.
            Without these Cookies, the services that You have asked for cannot
            be provided, and We only use these Cookies to provide You with those
            services.
            <br></br>
            <br></br>
            <span className='underline'>
              Cookies Policy / Notice Acceptance Cookies:
            </span>{" "}
            <span> </span>
            Persistent Cookies Administered by: Us Purpose: These Cookies
            identify if users have accepted the use of cookies on the Website.
            <br></br>
            <br></br>
            <span className='underline'>Functionality Cookies:</span>{" "}
            <span> </span>
            Persistent Cookies Administered by: Us Purpose: These Cookies allow
            us to remember choices You make when You use the Website, such as
            remembering your login details or language preference. The purpose
            of these Cookies is to provide You with a more personal experience
            and to avoid You having to re-enter your preferences every time You
            use the Website. For more information about the cookies we use and
            your choices regarding cookies, please visit our Cookies Policy or
            the Cookies section of our Privacy Policy.
          </p>
          <h3 className='mb-2 capitalize'>use of your personal data</h3>
          <p className='m-auto pb-8'>
            <span className='underline'>
              THE COMPANY MAY USE PERSONAL DATA FOR THE FOLLOWING PURPOSES:
            </span>
            <br></br>
            <br></br>
            <span className='underline'>
              To provide and maintain our Service:
            </span>{" "}
            <span> </span>
            Including to monitor the usage of our Service.
            <br></br>
            <br></br>
            <span className='underline'>To manage Your Account:</span>{" "}
            <span> </span>
            To manage Your registration as a user of the Service. The Personal
            Data You provide can give You access to different functionalities of
            the Service that are available to You as a registered user.
            <br></br>
            <br></br>
            <span className='underline'>
              For the performance of a contract:
            </span>{" "}
            <span> </span>
            The development, compliance and undertaking of the purchase contract
            for the products, items or services You have purchased or of any
            other contract with Us through the Service.
            <br></br>
            <br></br>
            <span className='underline'>To contact You:</span> <span> </span>
            To contact You by email, telephone calls, SMS, or other equivalent
            forms of electronic communication, such as a mobile application's
            push notifications regarding updates or informative communications
            related to the functionalities, products or contracted services,
            including the security updates, when necessary or reasonable for
            their implementation.
            <br></br>
            <br></br>
            <span className='underline'>To provide You with news:</span>{" "}
            <span> </span>
            Special offers and general information about other goods, services
            and events which we offer that are similar to those that you have
            already purchased or enquired about unless You have opted not to
            receive such information.
            <br></br>
            <br></br>
            <span className='underline'>To manage Your requests:</span>{" "}
            <span> </span>
            To attend and manage Your requests to Us. For business transfers: We
            may use Your information to evaluate or conduct a merger,
            divestiture,restructuring, reorganization, dissolution, or other
            sale or transfer of some or all of Our assets, whether as a going
            concern or as part of bankruptcy, liquidation, or similar
            proceeding, in which Personal Data held by Us about our Service
            users is among the assets transferred.
            <br></br>
            <br></br>
            <span className='underline'>For other purposes:</span>{" "}
            <span> </span>
            We may use Your information for other purposes, such as data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
            <br></br>
            <br></br>
            <span className='underline'>
              We may share Your personal information in the following
              situations:
            </span>
            <br></br>
            <br></br>
            <span className='underline'>With Service Providers:</span>{" "}
            <span> </span>
            We may share Your personal information with Service Providers to
            monitor and analyze the use of our Service, to contact You. •For
            business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
            <br></br>
            <br></br>
            <span className='underline'>With Affiliates:</span> <span> </span>
            We may share Your information with Our affiliates, in which case we
            will require those affiliates to honor this Privacy Policy.
            Affiliates include Our parent company and any other subsidiaries,
            joint venture partners or other companies that We control or that
            are under common control with Us.
            <br></br>
            <br></br>
            <span className='underline'>With business partners:</span>{" "}
            <span> </span>
            We may share Your information with Our business partners to offer
            You certain products, services or promotions.•With other users: when
            You share personal information or otherwise interact in the public
            areas with other users, such information may be viewed by all users
            and may be publicly distributed outside.
            <br></br>
            <br></br>
            <span className='underline'>With Your consent:</span> <span> </span>
            We may disclose Your personal information for any other purpose with
            Your consent.
          </p>
          <h4 className='mb-2 capitalize'>retention of your personal data</h4>
          <p className='m-auto pb-5'>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies. The Company will also
            retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this
            data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain
            this data for longer time periods.
          </p>
          <h4 className='mb-2 capitalize'>transfer of your personal data</h4>
          <p className='m-auto pb-5'>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction. Your consent to this Privacy
            Policy followed by Your submission of such information represents
            Your agreement to that transfer. The Company will take all steps
            reasonably necessary to ensure that Your data is treated securely
            and in accordance with this Privacy Policy and no transfer of Your
            Personal Data will take place to an organization or a country unless
            there are adequate controls in place including the security of Your
            data and other personal information.
          </p>
          <h3 className='mb-5 capitalize'>disclosure of your personal data</h3>
          <h4 className='mb-2 capitalize'>business transactions</h4>
          <p className='m-auto pb-8'>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h4 className='mb-2 capitalize'>law enforcement</h4>
          <p className='m-auto pb-8'>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h4 className='mb-2 capitalize'>other legal requirements</h4>
          <p className='m-auto pb-8'>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
            <br></br>
            •Comply with a legal obligation
            <br></br>
            •Protect and defend the rights or property of the Company
            <br></br>
            •Prevent or investigate possible wrongdoing in connection with the
            Service
            <br></br>
            •Protect the personal safety of Users of the Service or the public
            <br></br>
            •Protect against legal liability
          </p>
          <h4 className='mb-2 capitalize'>security of your personal data</h4>
          <p className='m-auto pb-8'>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            CHILDREN'S PRIVACY
          </h2>
          <p className='m-auto pb-8'>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            LINKS TO OTHER WEBSITES
          </h2>
          <p className='m-auto pb-8'>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit. We have no
            control over and assume no responsibility for the content, privacy
            policies or practices of any third party sites or services.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            CHANGES TO THIS PRIVACY POLICY
          </h2>
          <p className='m-auto pb-8'>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy. You are advised to
            review this Privacy Policy periodically for any changes. Changes to
            this Privacy Policy are effective when they are posted on this page.
          </p>
          <h2 className='mb-4 pb-2 border-b-2 border-borders dark:border-darkBorders'>
            CONTACT US
          </h2>

          <p className='m-auto'>
            If you have any questions about this Privacy Policy, you can contact
            us by email:{" "}
            <a
              href='mailto: help@roguestudios.io'
              className='text-heading font-bold hover:opacity-80 duration-500'
            >
              help@roguestudios.io
            </a>
          </p>
        </div>
        <div className='p-5'></div>
      </div>
      <Footer />
    </div>
  );
}
