import React, { PureComponent, Suspense } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../ThemeContext";
import {
  RarityIcon,
  BannerIcon,
  RadarIcon,
  RogueIcon,
  YomiIcon,
} from "../../icons/BrandIcons";
import ThreeJS from "./ThreeJS";
import { NFTSkeleton } from "../../icons/SVGs";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function scrollControlVision() {
  document.getElementById("Mission").scrollIntoView({ behavior: "smooth" });
}

function scrollControlCalendar() {
  document.getElementById("Calendar").scrollIntoView({ behavior: "smooth" });
}

function scrollControlYomi() {
  document.getElementById("Yomi").scrollIntoView({ behavior: "smooth" });
}

function scrollControlBanners() {
  document.getElementById("Banners").scrollIntoView({ behavior: "smooth" });
}

function scrollControlRadar() {
  document.getElementById("Radar").scrollIntoView({ behavior: "smooth" });
}

function scrollControlRarity() {
  document.getElementById("Rarity").scrollIntoView({ behavior: "smooth" });
}

function LandingIO() {
  return (
    <div>
      <div className='w-11/12 mx-auto max-w-6xl relative grid grid-rows-1 sm:grid-rows-2 grid-cols-1 lg:grid-rows-1 lg:grid-cols-2 py-32 pb-52 sm:mb-40 mb-5 overflow-hidden'>
        {/* <Hero /> */}
        <div className='flex flex-wrap items-center justify-center relative z-10 lg:translate-y-16'>
          <div classname='w-min'>
            <div className='flex flex-nowrap xs:justify-center xs:gap-0 gap-3 lg:gap-5 mx-auto w-full items-center h-fit flex-grow'>
              <h1 className='w-full my-auto pt-1 whitespace-nowrap text-center lg:text-left text-5xl lg:text-7xl text-heading'>
                the rogue way
              </h1>
            </div>

            <p className='max-w-2xl text-[2vw] md:text-base mt-3 mb-6 xs:mt-4 xs:text-xs text-center lg:text-left text-bodySecondary'>
              Rogue Studios provides a variety of NFT utility services,
              completely free. In addition to this, we build innovative SaaS
              products that help make creating, buying, and selling NFTs more
              accessible and user-friendly for businesses and individuals while
              eliminating the strain of navigating a technical landscape. We
              believe in Web3 for all and we're providing a seamless transition
              for everyone entering the space.
            </p>
            <div className='flex flex-wrap xs:gap-0 gap-6 w-full justify-center lg:justify-start'>
              <Link
                to='/#Mission'
                onClick={scrollControlVision}
                className='w-fit'
              >
                <button className='text-cardBackground md:mb-0 mb-4 px-8 sm:text-lg py-2 w-fit bg-darkCB border-darkBorders hover:bg-darkBorders'>
                  The Mission
                </button>
              </Link>
              <Link
                to='/#Calendar'
                onClick={scrollControlCalendar}
                className='w-fit'
              >
                <button className='text-body text-md sm:text-lg py-2 px-8 w-fit  bg-cardBackground border-borders border-2 hover:bg-borders'>
                  Release Calendar
                </button>
              </Link>
            </div>
          </div>
        </div>

        <div className='sm:w-[500px] sm:block hidden mx-auto text-md lg:mx-0 absolute lg:z-30 sm:h-[800px] w-[300px] h-[500px] top-0 left-0 z-30 lg:left-auto right-0 md:translate-y-40 lg:translate-x-20 translate-y-28 xs:translate-y-96 lg:-translate-y-20'>
          <Suspense fallback={Ghost()}>
            <ThreeJS />
          </Suspense>
        </div>
      </div>
      <div className='w-11/12 max-w-6xl mx-auto -mt-40 flex-wrap gap-8 justify-between border-t-2 border-borders pt-8 -translate-y-10 hidden sm:flex'>
        <Link
          to='/#Yomi'
          onClick={scrollControlYomi}
          className='flex flex-nowrap gap-3 items-center'
        >
          <YomiIcon iconClass='w-6 h-6 fill-heading' />
          <p className='text-xl text-bodySecondary capitalize'>yomi</p>
        </Link>
        <Link
          to='/#Banners'
          onClick={scrollControlBanners}
          className='flex flex-nowrap gap-3 items-center'
        >
          <BannerIcon iconClass='w-6 h-6 fill-heading' />
          <p className='text-xl text-bodySecondary capitalize'>banners</p>
        </Link>

        <Link
          to='/#Radar'
          onClick={scrollControlRadar}
          className='flex flex-nowrap gap-3 items-center'
        >
          <RadarIcon iconClass='w-6 h-6 fill-heading' />
          <p className='text-xl text-bodySecondary capitalize'>radar</p>
        </Link>
        <Link
          to='/#Rarity'
          onClick={scrollControlRarity}
          className='flex flex-nowrap gap-3 items-center'
        >
          <RarityIcon iconClass='w-6 h-6 fill-heading' />
          <p className='text-xl text-bodySecondary capitalize'>rarities</p>
        </Link>
      </div>

      <div className='sm:w-11/12 w-full md:max-w-6xl sm:max-w-[50%] max-w-[80%] mx-auto -mt-40 flex flex-wrap gap-8 justify-between border-t-2 border-borders pt-8 -translate-y-10 sm:hidden'>
        <div className='flex justify-between w-full'>
          <div className='flex flex-nowrap gap-3 items-center'>
            <YomiIcon iconClass='w-6 h-6 fill-heading' />
            <p className='text-xl text-bodySecondary capitalize'>yomi</p>
          </div>
          <div className='flex flex-nowrap gap-3 items-center'>
            <BannerIcon iconClass='w-6 h-6 fill-heading' />
            <p className='text-xl text-bodySecondary capitalize'>banners</p>
          </div>
        </div>
        <div className='flex justify-between w-full'>
          <div className='flex flex-nowrap gap-3 items-center'>
            <RadarIcon iconClass='w-6 h-6 fill-heading' />
            <p className='text-xl text-bodySecondary capitalize'>radar</p>
          </div>
          <div className='flex flex-nowrap gap-3 items-center'>
            <RarityIcon iconClass='w-6 h-6 fill-heading' />
            <p className='text-xl text-bodySecondary capitalize'>rarities</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const Ghost = () => {
  return (
    <div>
      <div className='xs:h-[200px] xs:w-[200px] h-[300px] w-[300px] lg:h-[400px] lg:w-[400px] scale-100 mx-auto translate-y-[275px] sm:translate-y-[225px] lg:translate-y-[200px] rounded-2xl relative'>
        <div className='w-10/12 h-10/12 relative object-cover flex justify-center items-end aspect-square rounded-xl bg-bodySecondary overflow-hidden animate-pulse'>
          <NFTSkeleton iconClass='w-full h-auto opacity-50' />
        </div>
      </div>
    </div>
  );
};

export default LandingIO;
