import React from "react";
import { Link } from "react-router-dom";

function topScroll() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

function Fegal(_) {
  return (
    <div>
      {/* <div>
        <p className="uppercase font-medium text-xs duration-500">Legal</p>
      </div>
      <a
        href="https://roguestudios.io/brand-kit"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Brand Kit
        </p>
      </a>
      <a
        href="https://roguestudios.io/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Terms and Conditions
        </p>
      </a>
      <a
        href="https://roguestudios.io/privacy-policy"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Privacy Policy
        </p>
      </a>
      <a
        href="https://roguestudios.io/disclaimer"
        target="_blank"
        rel="noreferrer"
      >
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Disclaimer
        </p>
      </a> */}

      <div>
        <p className="uppercase font-medium hover:text-heading text-xs duration-500">
          Legal
        </p>
      </div>
      <Link onClick={topScroll} to="/brand-kit">
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Brand Kit
        </p>
      </Link>
      <Link onClick={topScroll} to="/terms-and-conditions">
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Terms and Conditions
        </p>
      </Link>
      <Link onClick={topScroll} to="/privacy-policy">
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Privacy Policy
        </p>
      </Link>
      <Link onClick={topScroll} to="/disclaimer">
        <p className="my-3 block hover:text-heading text-sm duration-500">
          Disclaimer
        </p>
      </Link>
    </div>
  );
}

export default Fegal;
